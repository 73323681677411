import { ChainId } from '@traderjoe-xyz/sdk-core'
import { APTFarmABI } from '@traderjoe-xyz/sdk-v2'
import { Vault } from 'types/vault'
import { getFarmAndPoolId } from 'utils/farm'
import { formatUnits, getAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

interface UseGetUserFarmPositionProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  vault?: Vault
}

const useGetUserFarmPosition = ({
  chainId,
  vault
}: UseGetUserFarmPositionProps) => {
  const account = useAccount()

  const { farmAddress, poolId } =
    vault && vault.farm
      ? getFarmAndPoolId(vault?.farm.farmId)
      : { farmAddress: undefined, poolId: undefined }

  const aptDecimals = vault?.farm?.aptDecimals || 0

  return useReadContract({
    abi: APTFarmABI,
    address: farmAddress ? getAddress(farmAddress) : undefined,
    args:
      poolId && account.address ? [BigInt(poolId), account.address] : undefined,
    chainId,
    functionName: 'userInfo',
    query: {
      enabled: !!farmAddress && !!poolId && !!account.address,
      select: (data) => {
        return {
          formatted: formatUnits(data.amount, aptDecimals),
          value: data.amount
        }
      }
    }
  })
}

export default useGetUserFarmPosition
