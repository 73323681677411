import { ExternalLinkIcon } from '@chakra-ui/icons'
import { HStack, Link, Text } from '@chakra-ui/react'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import React from 'react'
import { shortenAddress } from 'utils/addresses'
import { getChain } from 'utils/chains'

interface Props {
  address: string | undefined
  chainId: Exclude<ChainId, ChainId.MANTLE>
  color?: string
  showAddress?: boolean
}

const ContractLink = ({
  address,
  chainId,
  color = 'textSecondary',
  showAddress = true
}: Props): JSX.Element => {
  const chain = getChain(chainId)

  return (
    <Link
      aria-label={`Link to contract ${address}`}
      isExternal
      href={`${chain?.blockExplorers?.default.url}/address/${address}`}
      color={color}
      _hover={{ opacity: 0.5 }}
    >
      <HStack spacing={1} align="center">
        {showAddress && (
          <Text fontSize="sm" textColor={color}>
            {shortenAddress(address)}
          </Text>
        )}
        <ExternalLinkIcon boxSize="14px" />
      </HStack>
    </Link>
  )
}

export default ContractLink
