import {
  Button,
  Center,
  Flex,
  Spinner,
  Tab,
  Table,
  TableContainer,
  TabList,
  Tabs,
  Tbody,
  Text,
  Thead,
  Tr
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import SearchBar from 'components/SearchBar'
import useSortedPools, { PoolSortMethod } from 'hooks/pool/useSortedPools'
import { useCallback, useState } from 'react'
import React from 'react'
import { Pool } from 'types/pool'
import { getChainName, getIsChainSunset } from 'utils/chains'

import { poolsListHeaders, PoolStatus } from './constants'
import CreateNewPoolMenu from './CreateNewPoolMenu'
import PoolTableHeader from './PoolTableHeader'
import PoolTableRow from './PoolTableRow'

export interface PoolsTableProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  isFetchingPage: boolean
  isLoadingPools: boolean
  onPoolsStatusChange: (poolStatus: PoolStatus) => void
  onQueryChange: (query: string) => void
  pools: Pool[]
  query: string
  selectedPoolStatus: PoolStatus
  setSortMethod: (sortMethod: PoolSortMethod) => void
  sortMethod: PoolSortMethod
  hasNextPage?: boolean
  onLoadMoreClick?: () => void
}

const PoolsTable = ({
  chainId,
  hasNextPage,
  isFetchingPage,
  isLoadingPools,
  onLoadMoreClick,
  onPoolsStatusChange,
  onQueryChange,
  pools,
  query,
  selectedPoolStatus,
  setSortMethod,
  sortMethod
}: PoolsTableProps) => {
  const isChainSunset = getIsChainSunset(chainId)

  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)
  const sortedPools = useSortedPools({
    isSortDescending,
    pools,
    sortMethod
  })

  const onHeaderClick = useCallback(
    (method?: PoolSortMethod) => {
      if (!method) return
      sortMethod !== method
        ? setSortMethod(method)
        : setIsSortDescending((previous) => !previous)
    },
    [sortMethod, setSortMethod]
  )

  return (
    <Flex flexDir="column" gap={4} pt={4}>
      <Flex flexDir={{ base: 'column', sm: 'row' }} gap={2}>
        <Tabs
          variant="soft-rounded"
          flexShrink={0}
          index={
            selectedPoolStatus === 'main'
              ? 0
              : selectedPoolStatus === 'all'
                ? 1
                : 2
          }
          onChange={(index) =>
            onPoolsStatusChange(
              index === 0 ? 'main' : index === 1 ? 'all' : 'rewarded'
            )
          }
        >
          <TabList>
            <Tab>
              <Trans>Manual Pools</Trans>
            </Tab>
            <Tab>
              <Trans>All Pools</Trans>
            </Tab>
            <Tab>
              <Trans>Has Rewards</Trans>
            </Tab>
          </TabList>
        </Tabs>
        <SearchBar
          placeholder={t`Search by name, symbol or address`}
          value={query}
          onValueChange={onQueryChange}
          size="lg"
        />
        {!isChainSunset && <CreateNewPoolMenu chainId={chainId} />}
      </Flex>
      {isLoadingPools ? (
        <Center mt={8}>
          <Spinner />
        </Center>
      ) : selectedPoolStatus === 'rewarded' && sortedPools.length === 0 ? (
        <Center minH="200px">
          <Text textColor="textSecondary">{`Reward Pools have ended on ${getChainName(
            chainId
          )}`}</Text>
        </Center>
      ) : (
        <TableContainer
          bg="bgCard"
          borderRadius="2xl"
          border="1px solid"
          borderColor="border"
          p={4}
        >
          <Table variant="card">
            <Thead>
              <Tr>
                {poolsListHeaders.map((header, i) => (
                  <PoolTableHeader
                    key={i}
                    selectedSortMethod={sortMethod}
                    isSortDescending={isSortDescending}
                    {...header}
                    onClick={() => onHeaderClick(header.sortMethod)}
                  />
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {sortedPools.map((pool) => (
                <PoolTableRow
                  key={pool.pairAddress}
                  pool={pool}
                  chainId={chainId}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {!isLoadingPools &&
      query === '' &&
      sortedPools.length > 0 &&
      hasNextPage ? (
        <Button
          variant="outline-bold"
          size="lg"
          alignSelf="center"
          w="160px"
          isLoading={isFetchingPage}
          loadingText={t`Loading`}
          onClick={onLoadMoreClick}
        >
          <Trans>Load More</Trans>
        </Button>
      ) : sortedPools.length > 0 && !hasNextPage ? (
        <Center mt={4}>
          <Text color="textSecondary" fontSize="sm">
            <Trans>All pools have been loaded</Trans>
          </Text>
        </Center>
      ) : null}
    </Flex>
  )
}

export default PoolsTable
