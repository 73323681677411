import { Center, Flex, Text } from '@chakra-ui/react'
import { ChainId, Currency } from '@traderjoe-xyz/sdk-core'
import {
  LB_ROUTER_ADDRESS,
  LB_ROUTER_V21_ADDRESS,
  LB_ROUTER_V22_ADDRESS
} from '@traderjoe-xyz/sdk-v2'
import Web3Button from 'components/Web3Button'
import useRemoveLiquidityInfoV2 from 'hooks/pool/v2/useRemoveLiquidityInfoV2'
import { RemoveLiquidityV2Option } from 'hooks/pool/v2/useRemoveLiquidityV2'
import useApproveForAllIfNeeded from 'hooks/useApproveForAllIfNeeded'
import React from 'react'
import { LBPoolVersion } from 'types/pool'
import { LBPairUserBalances } from 'types/poolV2'

import RemoveLiquidityDetailsRow from './RemoveLiquidityDetailsRow'

interface RemoveLiquidityDetailsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  currency0: Currency
  currency1: Currency
  lbPairAddress: string
  lbPoolVersion: LBPoolVersion
  onRemoveLiquidityConfigError: () => void
  onRemoveLiquiditySuccess: () => void
  range: number[]
  removeOption: RemoveLiquidityV2Option
  activeBinId?: number
  binStep?: string
  inversePriceRatios?: boolean
  userBalances?: LBPairUserBalances
}

const RemoveLiquidityDetails = ({
  activeBinId,
  binStep,
  chainId,
  currency0,
  currency1,
  inversePriceRatios,
  lbPairAddress,
  lbPoolVersion,
  onRemoveLiquidityConfigError,
  onRemoveLiquiditySuccess,
  range,
  removeOption,
  userBalances
}: RemoveLiquidityDetailsProps) => {
  const { parsedAmounts, refetchBins, refetchTotalSupplies, withdrawalInfo } =
    useRemoveLiquidityInfoV2({
      activeBinId,
      chainId,
      currency0,
      currency1,
      lbPairAddress,
      range,
      removeOption,
      userBalances
    })

  let lbRouterAddress: string
  switch (lbPoolVersion) {
    case 'v2':
      lbRouterAddress = LB_ROUTER_ADDRESS[chainId]
      break
    case 'v21':
      lbRouterAddress = LB_ROUTER_V21_ADDRESS[chainId]
      break
    case 'v22':
      lbRouterAddress = LB_ROUTER_V22_ADDRESS[chainId]
      break
  }

  const { approve, isApproved, isApproving, isLoadingIsApprovedForAll } =
    useApproveForAllIfNeeded({
      chainId: currency0.chainId,
      lbPoolVersion,
      spender: lbRouterAddress,
      token: lbPairAddress,
      tokenSymbol: 'LBT'
    })

  if (!withdrawalInfo || withdrawalInfo.length === 0) {
    return (
      <Center py={4}>
        <Text size="sm" color="textSecondary">
          You have no liquidity in this range.
        </Text>
      </Center>
    )
  }

  if (!parsedAmounts) {
    return null
  }

  return (
    <Flex flexDir="column" gap={4}>
      {!isApproved && !isLoadingIsApprovedForAll && approve ? (
        <Web3Button
          data-cy="remove-liquidity-approve-button"
          w="full"
          size="xl"
          variant="primary"
          colorScheme="accent"
          isLoading={isApproving}
          loadingText="Approving"
          onClick={approve}
        >
          Approve to continue
        </Web3Button>
      ) : null}
      {parsedAmounts.map((amounts, i) => (
        <RemoveLiquidityDetailsRow
          key={i}
          chainId={chainId}
          currency0={currency0}
          currency1={currency1}
          binStep={binStep}
          onRemoveLiquiditySuccess={onRemoveLiquiditySuccess}
          onRemoveLiquidityConfigError={() => {
            onRemoveLiquidityConfigError()
            refetchTotalSupplies()
            refetchBins()
          }}
          isApproved={isApproved}
          removeOption={removeOption}
          withdrawalInfo={withdrawalInfo[i]}
          parsedAmounts={amounts}
          inversePriceRatios={inversePriceRatios}
          lbPoolVersion={lbPoolVersion}
        />
      ))}
    </Flex>
  )
}

export default RemoveLiquidityDetails
