import { ChainId } from '@traderjoe-xyz/sdk-core'
import { erc20Abi, getAddress } from 'viem'
import { useReadContracts } from 'wagmi'

interface UseGetTokensTotalSupplyProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  tokens: string[]
}

const useGetTokensTotalSupply = ({
  chainId,
  tokens
}: UseGetTokensTotalSupplyProps) => {
  const calls = tokens.map((token) => {
    return {
      abi: erc20Abi,
      address: getAddress(token),
      chainId,
      functionName: 'totalSupply'
    } as const
  })

  return useReadContracts({
    allowFailure: true,
    contracts: calls,
    query: {
      select: (data) => {
        return data
          ?.filter((read) => read.status === 'success')
          .map((read) => read?.result as bigint | null)
      }
    }
  })
}

export default useGetTokensTotalSupply
