import {
  ButtonProps,
  Flex,
  Heading,
  HeadingProps,
  HStack,
  InputGroup,
  InputProps,
  InputRightElement,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { useSize } from '@chakra-ui/react-use-size'
import { t } from '@lingui/macro'
import { ChainId, Currency } from '@traderjoe-xyz/sdk-core'
import CurrencyPickerModal from 'components/CurrencyPickerModal'
import InputBalanceButton from 'components/InputBalanceButton'
import NumericalInput from 'components/NumericalInput'
import PairPickerModal, { Pair } from 'components/PairPickerModal'
import React, { useRef } from 'react'

import CurrencySelectButton from './CurrencySelectButton'

export interface CurrencyInputProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  onValueChange: (value: string) => void
  value: string
  amountUsd?: string
  balance?: string
  currency?: Currency
  currencyAddress?: string
  currencyPickerButtonProps?: ButtonProps
  dataCyInputGroup?: string
  error?: string
  heading?: string
  headingProps?: HeadingProps
  headingRightElement?: JSX.Element
  isBalanceButtonDisabled?: boolean
  isChainPickable?: boolean
  isCurrencyPickerDisabled?: boolean
  isDisabled?: boolean
  isNumericalInputDisabled?: boolean
  onCurrencyChange?: (currency: Currency) => void
  onPairSelected?: (pair: Pair) => void
  rightElement?: JSX.Element
  wrapRightElementIfNeeded?: boolean
}

const CurrencyInput = ({
  amountUsd,
  balance,
  chainId,
  currency,
  currencyAddress,
  currencyPickerButtonProps,
  dataCyInputGroup,
  error,
  heading,
  headingProps,
  headingRightElement,
  isBalanceButtonDisabled,
  isChainPickable,
  isDisabled,
  isNumericalInputDisabled,
  onCurrencyChange,
  onPairSelected,
  onValueChange,
  rightElement,
  value,
  wrapRightElementIfNeeded,
  ...props
}: CurrencyInputProps & InputProps) => {
  const inputRightElementRef = useRef<HTMLDivElement | null>(null)
  const rightElementSize = useSize(inputRightElementRef)

  const {
    isOpen: isPickerOpen,
    onClose: onPickerClose,
    onOpen: onPickerOpen
  } = useDisclosure()

  const isPickerEnabled = !!onCurrencyChange || !!onPairSelected

  return (
    <>
      {onCurrencyChange ? (
        <CurrencyPickerModal
          chainId={chainId}
          isOpen={isPickerOpen}
          onClose={onPickerClose}
          onCurrencyChange={onCurrencyChange}
          isChainPickable={isChainPickable}
        />
      ) : onPairSelected ? (
        <PairPickerModal
          chainId={chainId}
          isOpen={isPickerOpen}
          onClose={onPickerClose}
          onPairSelected={onPairSelected}
        />
      ) : null}
      <VStack
        w="full"
        spacing={1}
        sx={
          wrapRightElementIfNeeded
            ? {
                '@container currency-input-container (min-width: 0px)': {
                  '.currency-input-group': {
                    h: '72px'
                  },
                  '.currency-input-right-element': {
                    alignItems: 'flex-end',
                    flexDir: 'column'
                  }
                },
                '@container currency-input-container (min-width: 375px)': {
                  '.currency-input-group': {
                    h: 'unset'
                  },
                  '.currency-input-right-element': {
                    alignItems: 'center',
                    flexDir: 'row'
                  }
                },
                containerName: 'currency-input-container',
                containerType: 'inline-size'
              }
            : undefined
        }
      >
        <Flex
          w="full"
          align="center"
          justify={heading ? 'space-between' : 'flex-end'}
        >
          {heading ? (
            <HStack spacing={1}>
              <Heading
                data-cy="currency-input-heading"
                fontSize="md"
                w="full"
                textAlign="left"
                textColor="textSecondary"
                {...headingProps}
              >
                {heading}
              </Heading>
              {headingRightElement ? headingRightElement : null}
            </HStack>
          ) : null}
          {balance && !!currency ? (
            <InputBalanceButton
              flexShrink={0}
              alignSelf="flex-end"
              balance={balance}
              isDisabled={isDisabled || isBalanceButtonDisabled}
              onClick={() => onValueChange(balance)}
            />
          ) : null}
        </Flex>
        <InputGroup data-cy={dataCyInputGroup} className="currency-input-group">
          <NumericalInput
            size="lg"
            inputType="decimal"
            placeholder={t`Enter Amount`}
            isDisabled={isDisabled || isNumericalInputDisabled}
            _disabled={
              isNumericalInputDisabled
                ? { cursor: 'not-allowed', opacity: 1 }
                : { cursor: 'not-allowed', opacity: 0.5 }
            }
            isInvalid={!!error}
            borderRadius="full"
            value={value}
            onValueChange={onValueChange}
            {...props}
            pr={
              rightElementSize
                ? `${Math.trunc(rightElementSize.width + 8)}px`
                : undefined
            }
          />
          <InputRightElement
            className="currency-input-right-element"
            h="full"
            justifyContent="flex-end"
            gap={2}
            alignItems="center"
            w="fit-content"
            ref={inputRightElementRef}
            pr={1}
          >
            {rightElement && rightElement}
            {isPickerEnabled ? (
              <CurrencySelectButton
                currency={currency}
                currencyAddress={currencyAddress}
                currencyPickerButtonProps={currencyPickerButtonProps}
                isDisabled={isDisabled}
                onCurrencyPickerOpen={onPickerOpen}
              />
            ) : (
              <Text
                fontSize="xs"
                textColor="textSecondary"
                px={4}
                borderLeft="1px solid"
                borderColor="border"
              >
                {currency?.symbol}
              </Text>
            )}
          </InputRightElement>
        </InputGroup>
        {amountUsd ? (
          <Text alignSelf="flex-start" fontSize="sm" textColor="textSecondary">
            {amountUsd}
          </Text>
        ) : null}
        {error ? (
          <Text
            data-cy="currency-input-error-label"
            fontSize="sm"
            color="red"
            w="full"
          >
            {error}
          </Text>
        ) : null}
      </VStack>
    </>
  )
}

export default CurrencyInput
