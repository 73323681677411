import { ChainId } from '@traderjoe-xyz/sdk-core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Token } from 'types/dexbarn'
import { Pool } from 'types/pool'
import { getPoolDetailUrl } from 'utils/poolUrl'

interface UseSearchPanelKeyboardNavigationProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  focused: boolean
  setFocused: React.Dispatch<React.SetStateAction<boolean>>
  pairs?: Pool[]
  tokens?: Token[]
}

const useSearchPanelKeyboardNavigation = ({
  chainId,
  pairs,
  setFocused,
  tokens
}: UseSearchPanelKeyboardNavigationProps) => {
  const navigate = useNavigate()

  const resultsContainerRef = React.useRef<HTMLDivElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [activeIndex, setActiveIndex] = React.useState<number | undefined>()

  const closePanel = React.useCallback(() => {
    setFocused(false)
    setActiveIndex(undefined)
    inputRef.current?.blur()
  }, [setFocused])

  const scrollToItemIfNeeded = React.useCallback((index: number) => {
    const container = resultsContainerRef.current
    if (!container) return

    const selectedItem = document.getElementById(`search-result-${index}`)
    if (!selectedItem) return

    const containerTop = container.getBoundingClientRect().top
    const containerBottom = container.getBoundingClientRect().bottom
    const itemTop = selectedItem.getBoundingClientRect().top
    const itemBottom = selectedItem.getBoundingClientRect().bottom

    if (itemTop < containerTop) {
      container.scrollTop -= containerTop - itemTop
    } else if (itemBottom > containerBottom) {
      container.scrollTop += itemBottom - containerBottom
    }
  }, [])

  const downHandler = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === '/') {
        event.preventDefault()
        inputRef.current?.focus()
        setFocused(true)
      } else if (event.key === 'Escape') {
        closePanel()
      } else if (event.key === 'ArrowDown') {
        const itemsLength = (pairs?.length ?? 0) + (tokens?.length ?? 0)
        if (itemsLength === 0) return
        const newIndex =
          activeIndex === undefined ? 0 : (activeIndex + 1) % itemsLength
        setActiveIndex(newIndex)
        scrollToItemIfNeeded(newIndex)
      } else if (event.key === 'ArrowUp') {
        const itemsLength = (pairs?.length ?? 0) + (tokens?.length ?? 0)
        if (itemsLength === 0) return
        const newIndex =
          activeIndex !== undefined && activeIndex > 0
            ? activeIndex - 1
            : itemsLength - 1
        setActiveIndex(newIndex)
        scrollToItemIfNeeded(newIndex)
      } else if (event.key === 'Enter') {
        if (
          activeIndex !== undefined &&
          tokens &&
          activeIndex < tokens.length
        ) {
          closePanel()

          const token = tokens[activeIndex]
          navigate(`/${token.chain}/trade?outputCurrency=${token.tokenAddress}`)
        } else if (
          activeIndex !== undefined &&
          pairs &&
          activeIndex < pairs.length
        ) {
          closePanel()

          const i = activeIndex - (tokens?.length ?? 0)
          navigate(getPoolDetailUrl(pairs[i], chainId))
        }
      }
    },
    [
      pairs,
      tokens,
      navigate,
      chainId,
      activeIndex,
      closePanel,
      setFocused,
      scrollToItemIfNeeded
    ]
  )

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [downHandler])

  return {
    activeIndex,
    closePanel,
    inputRef,
    resultsContainerRef,
    setActiveIndex
  }
}

export default useSearchPanelKeyboardNavigation
