import { useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { useMemo } from 'react'
import { Token as DexbarnToken } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

import useDebounce from './useDebounce'
import { useDexbarnGet } from './useDexbarn'

interface UseTokensSearchProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  query: string
  enabled?: boolean
}

const useTokensSearch = ({ chainId, enabled, query }: UseTokensSearchProps) => {
  const chain = getDexbarnChainParam(chainId)

  const debouncedQuery = useDebounce(query, 400)
  const terms = debouncedQuery.split(' ')
  const _query = terms[0].trim()

  const fetchLbPairs = useDexbarnGet<DexbarnToken[]>(
    `/v1/tokens/search?query=${_query}&chain=${chain}&filterBy=1d&orderBy=volume`,
    chainId
  )
  const { data, isFetching } = useQuery<DexbarnToken[]>({
    enabled: _query.length >= 3 && enabled,
    queryFn: () => fetchLbPairs(),
    queryKey: ['TokensSearch', _query, chainId]
  })

  const tokens = useMemo(() => {
    if (!data) {
      return []
    }

    return data.filter((token) => {
      return token.volume > 100 && token.volume < 69e7
    })
  }, [data])

  return {
    isLoading: isFetching,
    tokens
  }
}

export default useTokensSearch
