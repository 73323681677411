import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useEffect, useMemo } from 'react'
import { Aggregator } from 'types/router'
import { isUserRejectedError } from 'utils/error'
import { formattedNum } from 'utils/format'
import { BaseError, useSendTransaction, useWalletClient } from 'wagmi'

import useGetSwapDataFromBarn from './useGetSwapDataFromBarn'
import { UseSwapProps, UseSwapResult } from './useSwap'

interface UseSwapWithExternalAggregatorProps extends UseSwapProps {
  aggregator?: Aggregator
}

const useSwapWithExternalAggregator = ({
  aggregator,
  chainId,
  currencyIn,
  currencyOut,
  enabled,
  onSwapSuccess,
  route,
  slippageBps
}: UseSwapWithExternalAggregatorProps): UseSwapResult => {
  const { data: walletClient } = useWalletClient({ chainId })
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const {
    data: swapData,
    error: swapDataError,
    isLoading: isFetchingSwapData
  } = useGetSwapDataFromBarn({
    aggregator,
    amountIn: route?.amountIn.value.toString(),
    chainId,
    currencyIn,
    currencyOut,
    enabled,
    slippageBps
  })

  const {
    data: hash,
    error: sendTransactionError,
    isPending,
    reset,
    sendTransactionAsync
  } = useSendTransaction({
    mutation: {
      onSuccess: (hash) => {
        if (!route) return

        const transactionSummary = t`Swap ${formattedNum(
          route?.amountIn.formatted
        )} ${currencyIn?.symbol} for ${formattedNum(
          route?.amountOut.formatted
        )} ${currencyOut?.symbol}`

        addRecentTransaction({ description: transactionSummary, hash })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  useEffect(() => {
    reset()
  }, [swapData, reset])

  const swapAsync = swapData
    ? async () => {
        try {
          const hash = await sendTransactionAsync({
            data: swapData.data as `0x${string}`,
            to: swapData.to as `0x${string}`,
            value: BigInt(swapData.value)
          })
          return hash
        } catch (error) {
          console.error('Error sending transaction', error)
          throw error
        }
      }
    : undefined

  const { data: receipt, isLoading: isReceiptLoading } =
    useWaitForTransactionReceipt({
      chainId,
      hash,
      onTransactionSuccess: onSwapSuccess
    })

  const error = useMemo(() => {
    if (swapDataError) {
      return {
        message: swapDataError.response?.data.error || '',
        summary: swapDataError.message
      }
    }

    if (
      sendTransactionError &&
      !isUserRejectedError(sendTransactionError.message)
    ) {
      return {
        message: sendTransactionError.message,
        summary: (sendTransactionError as BaseError).shortMessage
      }
    }

    return undefined
  }, [swapDataError, sendTransactionError])

  const forceSwapAsync =
    swapData && walletClient && !!error
      ? async () => {
          return walletClient.sendTransaction({
            data: swapData.data as `0x${string}`,
            to: swapData.to as `0x${string}`,
            value: BigInt(swapData.value)
          })
        }
      : undefined

  return {
    error,
    forceSwapAsync,
    isSimulating: isFetchingSwapData,
    isSwapping: isPending || isReceiptLoading,
    receipt,
    resetSwap: reset,
    swapAsync
  }
}

export default useSwapWithExternalAggregator
