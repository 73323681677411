import { JOE_ADDRESS } from '@traderjoe-xyz/sdk'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import useGetTokenUsdPrice from 'hooks/useGetTokenUsdPrice'
import { calculateSJoeMetrics } from 'utils/stake'

import { useSJoePayouts } from './useSJoeAnalytics'
import { useSJoeStaking } from './useSJoeStaking'

const useGetStakingInfo = () => {
  // get staking data
  const {
    data: avalancheStakingData,
    isLoading: isLoadingAvalancheStakingData
  } = useSJoeStaking({
    chainId: ChainId.AVALANCHE
  })
  const { data: arbitrumStakingData, isLoading: isLoadingArbitrumStakingData } =
    useSJoeStaking({
      chainId: ChainId.ARBITRUM_ONE
    })

  // get fees remitted
  const {
    data: avalanchePayoutDayDatas,
    isLoading: isLoadingAvalanchePayouts
  } = useSJoePayouts({ chainId: ChainId.AVALANCHE })
  const { data: arbitrumPayoutDayDatas, isLoading: isLoadingArbitrumPayouts } =
    useSJoePayouts({
      chainId: ChainId.ARBITRUM_ONE
    })
  const totalFees1D =
    avalanchePayoutDayDatas && arbitrumPayoutDayDatas
      ? avalanchePayoutDayDatas.feesUsd1D + arbitrumPayoutDayDatas.feesUsd1D
      : undefined
  const totalFees7D =
    avalanchePayoutDayDatas && arbitrumPayoutDayDatas
      ? avalanchePayoutDayDatas.feesUsd7D + arbitrumPayoutDayDatas.feesUsd7D
      : undefined
  const totalFees30D =
    avalanchePayoutDayDatas && arbitrumPayoutDayDatas
      ? avalanchePayoutDayDatas.feesUsd30D + arbitrumPayoutDayDatas.feesUsd30D
      : undefined

  // get total staked on all chains
  const totalStaked =
    avalancheStakingData?.totalStaked && arbitrumStakingData?.totalStaked
      ? avalancheStakingData.totalStaked + arbitrumStakingData.totalStaked
      : undefined

  // 30D APR
  const { data: joePriceUsd } = useGetTokenUsdPrice({
    chainId: ChainId.AVALANCHE,
    tokenAddress: JOE_ADDRESS[ChainId.AVALANCHE]
  })
  const { apr30D: avalancheApr30D } =
    calculateSJoeMetrics(
      avalancheStakingData?.totalStaked,
      joePriceUsd,
      avalanchePayoutDayDatas?.dayDatas30D
    ) || {}
  const { apr30D: arbitrumApr30D } =
    calculateSJoeMetrics(
      arbitrumStakingData?.totalStaked,
      joePriceUsd,
      arbitrumPayoutDayDatas?.dayDatas30D
    ) || {}

  const isLoading =
    isLoadingArbitrumStakingData ||
    isLoadingAvalancheStakingData ||
    isLoadingAvalanchePayouts ||
    isLoadingArbitrumPayouts

  return {
    arbitrumApr30D,
    arbitrumPayoutDayDatas,
    arbitrumStakingData,
    avalancheApr30D,
    avalanchePayoutDayDatas,
    avalancheStakingData,
    isLoading,
    isLoadingArbitrumPayouts,
    isLoadingArbitrumStakingData,
    isLoadingAvalanchePayouts,
    isLoadingAvalancheStakingData,
    totalFees1D,
    totalFees30D,
    totalFees7D,
    totalStaked
  }
}

export default useGetStakingInfo
