export const LBSwapRouterAbi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'wnative',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'initialOwner',
        type: 'address'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    stateMutability: 'nonpayable',
    type: 'fallback'
  },
  {
    stateMutability: 'payable',
    type: 'receive'
  },
  {
    inputs: [],
    name: 'WNATIVE',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'acceptOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256'
      }
    ],
    name: 'getTrustedLogicAt',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getTrustedLogicLength',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'pendingOwner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'logic',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'tokenIn',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'tokenOut',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amountIn',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amountOut',
        type: 'uint256'
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address'
      },
      {
        internalType: 'bool',
        name: 'exactIn',
        type: 'bool'
      },
      {
        internalType: 'bytes[]',
        name: 'multiRoutes',
        type: 'bytes[]'
      }
    ],
    name: 'simulate',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'logic',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'tokenIn',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'tokenOut',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amountIn',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amountOut',
        type: 'uint256'
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address'
      },
      {
        internalType: 'bool',
        name: 'exactIn',
        type: 'bool'
      },
      {
        internalType: 'bytes',
        name: 'route',
        type: 'bytes'
      }
    ],
    name: 'simulateSingle',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'logic',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'tokenIn',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'tokenOut',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amountIn',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amountOutMin',
        type: 'uint256'
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'deadline',
        type: 'uint256'
      },
      {
        internalType: 'bytes',
        name: 'route',
        type: 'bytes'
      }
    ],
    name: 'swapExactIn',
    outputs: [
      {
        internalType: 'uint256',
        name: 'totalIn',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'totalOut',
        type: 'uint256'
      }
    ],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'logic',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'tokenIn',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'tokenOut',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amountOut',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amountInMax',
        type: 'uint256'
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'deadline',
        type: 'uint256'
      },
      {
        internalType: 'bytes',
        name: 'route',
        type: 'bytes'
      }
    ],
    name: 'swapExactOut',
    outputs: [
      {
        internalType: 'uint256',
        name: 'totalIn',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'totalOut',
        type: 'uint256'
      }
    ],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'logic',
        type: 'address'
      },
      {
        internalType: 'bool',
        name: 'add',
        type: 'bool'
      }
    ],
    name: 'updateRouterLogic',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferStarted',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'routerLogic',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'added',
        type: 'bool'
      }
    ],
    name: 'RouterLogicUpdated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'to',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'tokenIn',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'tokenOut',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountIn',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountOut',
        type: 'uint256'
      }
    ],
    name: 'SwapExactIn',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'to',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'tokenIn',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'tokenOut',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountIn',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountOut',
        type: 'uint256'
      }
    ],
    name: 'SwapExactOut',
    type: 'event'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address'
      }
    ],
    name: 'OwnableInvalidOwner',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address'
      }
    ],
    name: 'OwnableUnauthorizedAccount',
    type: 'error'
  },
  {
    inputs: [],
    name: 'ReentrancyGuardReentrantCall',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'allowance',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'RouterLib__InsufficientAllowance',
    type: 'error'
  },
  {
    inputs: [],
    name: 'RouterLib__ZeroAmount',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__DeadlineExceeded',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__IdenticalTokens',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'balanceBefore',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'balanceAfter',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amountOutMin',
        type: 'uint256'
      }
    ],
    name: 'Router__InsufficientAmountReceived',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'outputAmount',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'minOutputAmount',
        type: 'uint256'
      }
    ],
    name: 'Router__InsufficientOutputAmount',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__InvalidTo',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__InvalidWnative',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'routerLogic',
        type: 'address'
      }
    ],
    name: 'Router__LogicAlreadyAdded',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'routerLogic',
        type: 'address'
      }
    ],
    name: 'Router__LogicNotFound',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__OnlyWnative',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'Router__SimulateSingle',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256[]',
        name: 'amounts',
        type: 'uint256[]'
      }
    ],
    name: 'Router__Simulations',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'routerLogic',
        type: 'address'
      }
    ],
    name: 'Router__UntrustedLogic',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__ZeroAmount',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TokenLib__BalanceOfFailed',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TokenLib__NativeTransferFailed',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TokenLib__TransferFailed',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TokenLib__TransferFromFailed',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TokenLib__UnwrapFailed',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TokenLib__WrapFailed',
    type: 'error'
  }
] as const
