import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import React from 'react'
import { Link } from 'react-router-dom'
import { PoolIcon } from 'theme/icons'
import { getChainSlug } from 'utils/chains'

type Version = 'v1' | 'v2'

interface CreateNewPoolMenuProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
}

const CreateNewPoolMenu = ({ chainId }: CreateNewPoolMenuProps) => {
  const versions: Version[] =
    chainId === ChainId.ETHEREUM ? ['v2'] : ['v2', 'v1']

  const versionLabels = {
    v1: t`Basic (V1)`,
    v2: t`Manual (V2)`
  }

  return (
    <Menu>
      <MenuButton as={Button} variant="outline-bold" size="lg" flexShrink={0}>
        <Flex justifyContent="center" align="center" gap={3}>
          <PoolIcon boxSize={5} />
          <Trans>Create New Pool</Trans>
        </Flex>
      </MenuButton>
      <MenuList>
        {versions.map((version) => (
          <MenuItem
            key={version}
            as={Link}
            h="48px"
            to={`/${getChainSlug(chainId)}/pool/${version}/create`}
          >
            {versionLabels[version]}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export default CreateNewPoolMenu
