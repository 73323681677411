import { useToast } from '@chakra-ui/react'
import { supportedChains } from 'constants/chains'
import { useSwitchChain as useWagmiSwitchChain } from 'wagmi'
import { avalancheFuji } from 'wagmi/chains'

import useErrorToast from './useErrorToast'

const useSwitchChain = () => {
  const toast = useToast()
  const addErrorToast = useErrorToast()

  return useWagmiSwitchChain({
    mutation: {
      onError: (error) => {
        // ignore user cancel errors
        if ((error as any).code === 4001) return
        addErrorToast(
          error.message,
          'Please switch to the correct network from your wallet.',
          'switch_chain_error'
        )
      },
      onMutate: ({ chainId }) => {
        const chain = supportedChains.find((c) => c.id === chainId)
        if (chain && chain.testnet && chain.id !== avalancheFuji.id) {
          const toastId = 'dexbarn_unsupported_chain_error'
          if (!toast.isActive(toastId)) {
            addErrorToast(
              `No API support for ${chain.name}`,
              `We only support basic functionality on this chain.`,
              toastId
            )
          }
        }
      }
    }
  })
}

export default useSwitchChain
