import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Heading,
  Hide,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import ChainSelector from 'components/ChainSelector'
import PageHelmet from 'components/PageHelmet'
import { POOL_HELMET_DESCRIPTION, POOL_HELMET_TITLE } from 'constants/pool'
import usePoolSearchParams from 'hooks/pool/usePoolSearchParams'
import { PoolSortMethod } from 'hooks/pool/useSortedPools'
import usePoolsV1 from 'hooks/pool/v1/usePoolsV1'
import useLbPoolsWithRewards from 'hooks/pool/v2/useLbPoolsWithRewards'
import usePoolsV2 from 'hooks/pool/v2/usePoolsV2'
import useChainIdFromUrlParam from 'hooks/useChainIdFromUrlParam'
import usePairsSearch from 'hooks/usePairsSearch'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { LBPool } from 'types/pool'
import { getChainSlug } from 'utils/chains'

import { PoolStatus, PoolVersion } from './constants'
import PoolMainAnalytics from './PoolMainAnalytics'
import PoolsMobileList from './PoolsMobileList'
import PoolsTable from './PoolsTable'
import UserPositionsTable from './UserPositions/UserPositionsTable'
import UserRewardsTable from './UserPositions/UserRewardsTable'
import Vault from './Vault'

interface PoolProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
}

const Pool = ({ chainId }: PoolProps) => {
  const location = useLocation()
  const { selectedTab, setSelectedTab } = usePoolSearchParams()
  const navigate = useNavigate()

  const isTestnet =
    chainId === ChainId.FUJI ||
    chainId === ChainId.BNB_TESTNET ||
    chainId === ChainId.ARB_GOERLI
  const analyticsPoolVersions = [PoolVersion.V1, PoolVersion.V2]
  const [analyticsPoolVersion, setAnalyticsPoolVersion] = useState<PoolVersion>(
    PoolVersion.V2
  )
  const isAnalyticsPoolVersionMenuEnabled =
    chainId === ChainId.AVALANCHE || chainId === ChainId.FUJI

  const [poolsStatus, setPoolsStatus] = useState<PoolStatus>('main')
  const [sortMethod, setSortMethod] = useState<PoolSortMethod>(
    PoolSortMethod.VOLUME
  )

  // fetch pools v1
  const pageSize = 25
  const {
    data: poolsV1,
    fetchNextPage: fetchNextPagePoolsV1,
    hasNextPage: hasNextPagePoolsV1,
    isFetchingNextPage: isFetchingNextPagePoolsV1,
    isLoading: isLoadingPoolsV1
  } = usePoolsV1({
    chainId,
    enabled: poolsStatus === 'all',
    orderBy: sortMethod === PoolSortMethod.LIQUIDITY ? 'liquidity' : 'volume',
    pageSize
  })

  // fetch pools v2
  const {
    data: poolsV2,
    fetchNextPage: fetchNextPagePoolsV2,
    hasNextPage: hasNextPagePoolsV2,
    isFetchingNextPage: isFetchingNextPagePoolsV2,
    isLoading: isLoadingPoolsV2
  } = usePoolsV2({
    chainId,
    excludeLowVolumePools: !isTestnet && poolsStatus !== 'rewarded',
    orderBy: sortMethod === PoolSortMethod.LIQUIDITY ? 'liquidity' : 'volume',
    pageSize,
    status: poolsStatus === 'all' ? 'all' : 'main',
    version: poolsStatus === 'rewarded' ? 'v2.2' : undefined
  })

  // pagination state
  const isFetchingNextPoolsPage =
    isFetchingNextPagePoolsV1 || isFetchingNextPagePoolsV2
  const hasNextPage =
    poolsStatus === 'all'
      ? hasNextPagePoolsV1 || hasNextPagePoolsV2
      : hasNextPagePoolsV2

  // load more callback
  const onLoadMoreClick = useCallback(() => {
    switch (poolsStatus) {
      case 'all':
        fetchNextPagePoolsV1()
        fetchNextPagePoolsV2()
        break
      case 'main':
        fetchNextPagePoolsV2()
        break
      case 'rewarded':
        fetchNextPagePoolsV2()
        break
    }
  }, [fetchNextPagePoolsV1, fetchNextPagePoolsV2, poolsStatus])

  // search
  const [query, setQuery] = useState<string>('')
  const { data: searchedPools, isLoading: isLoadingSearch } = usePairsSearch({
    chainId,
    includesPoolV1: poolsStatus === 'all',
    query,
    status: poolsStatus === 'all' ? 'all' : 'main'
  })

  // get lb pools with rewards
  const nonFilteredPools = useMemo(() => {
    if (query.length > 0) {
      return searchedPools
    }
    return poolsV2 as LBPool[]
  }, [poolsV2, searchedPools, query])
  const { lbPoolsWithRewards: poolsWithRewards } = useLbPoolsWithRewards({
    chainId,
    pools: nonFilteredPools
  })

  // calculate pools to display
  const pools = useMemo(() => {
    if (query.length > 0) {
      return poolsWithRewards
    }
    switch (poolsStatus) {
      case 'all':
        return poolsV1.concat(poolsWithRewards)
      case 'main':
        return poolsWithRewards
      case 'rewarded':
        return poolsWithRewards.filter((pool) => {
          const lbPool = pool as LBPool
          return (
            (lbPool.lbRewards && lbPool.lbRewards.length > 0) ||
            (lbPool.rewards && lbPool.rewards.length > 0)
          )
        })
    }
  }, [poolsV1, poolsWithRewards, poolsStatus, query])

  // loading state
  const isLoadingPools =
    isLoadingPoolsV2 ||
    (isLoadingPoolsV1 && poolsStatus === 'all') ||
    isLoadingSearch

  // reset query when chain changes
  useEffect(() => {
    setQuery('')
  }, [chainId])

  return (
    <Box pt={4} bg="bgSecondary">
      <Box maxW={{ '2xl': '1600px', base: '1400px' }} margin="0 auto">
        <PageHelmet
          title={POOL_HELMET_TITLE}
          description={POOL_HELMET_DESCRIPTION}
          url={location.pathname}
        />
        <Flex px={4} align="center" justify="space-between">
          <VStack pb={6} spacing={0} align="flex-start">
            <Heading>
              <Trans>Pool</Trans>
            </Heading>
            <Text color="textSecondary" fontSize="sm">
              <Trans>Provide liquidity and earn fees.</Trans>
            </Text>
          </VStack>

          <HStack>
            {isAnalyticsPoolVersionMenuEnabled ? (
              <Menu>
                <MenuButton
                  size="lg"
                  bg="transparent"
                  borderColor="joeLight.600"
                  _dark={{
                    _hover: { bg: 'joeDark.400' },
                    borderColor: 'joeDark.500'
                  }}
                  variant="outline"
                  borderRadius="full"
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  {analyticsPoolVersion}
                </MenuButton>
                <MenuList>
                  {analyticsPoolVersions.map((poolVersion, i) => (
                    <MenuItem
                      key={i}
                      h="48px"
                      onClick={() => setAnalyticsPoolVersion(poolVersion)}
                    >
                      {poolVersion}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            ) : null}
            <ChainSelector
              onChainSelect={(chainId) => {
                navigate(`/${getChainSlug(chainId)}/pool`)
              }}
              selectedChainId={chainId}
              menuButtonProps={{
                _dark: {
                  _hover: { bg: 'joeDark.400' },
                  borderColor: 'joeDark.500'
                },
                bg: 'transparent',
                borderColor: 'joeLight.600',
                borderRadius: 'full'
              }}
            />
          </HStack>
        </Flex>
        <PoolMainAnalytics
          chainId={chainId}
          poolVersion={analyticsPoolVersion}
        />
      </Box>
      <Tabs
        variant="enclosed"
        index={selectedTab}
        onChange={(index) => setSelectedTab(index)}
      >
        <TabList
          px={6}
          maxW={{ '2xl': '1600px', base: '1400px' }}
          margin="0 auto"
          borderBottom={0}
          overflowX="auto"
          overflowY="hidden"
        >
          <Tab>
            <Trans>Pools</Trans>
          </Tab>
          <Tab data-cy="auto-pools-tab">
            <Trans>Auto Pools</Trans>
          </Tab>
          <Tab>
            <Trans>My Pools</Trans>
          </Tab>
          <Tab>
            <Trans>My Rewards</Trans>
          </Tab>
        </TabList>
        <TabPanels minH="60vh" pb={{ base: 4, md: 24 }}>
          <TabPanel maxW={{ '2xl': '1600px', base: '1400px' }} margin="0 auto">
            <Hide below="md">
              <PoolsTable
                isFetchingPage={isFetchingNextPoolsPage}
                pools={pools}
                onPoolsStatusChange={setPoolsStatus}
                selectedPoolStatus={poolsStatus}
                isLoadingPools={isLoadingPools}
                onLoadMoreClick={onLoadMoreClick}
                hasNextPage={hasNextPage}
                query={query}
                onQueryChange={setQuery}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
                chainId={chainId}
              />
            </Hide>
            <Show below="md">
              <PoolsMobileList
                pools={pools}
                onPoolsStatusChange={setPoolsStatus}
                selectedPoolStatus={poolsStatus}
                isLoadingPools={isLoadingPools}
                isFetchingPage={isFetchingNextPoolsPage}
                onLoadMoreClick={onLoadMoreClick}
                hasNextPage={hasNextPage}
                query={query}
                onQueryChange={setQuery}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
                chainId={chainId}
              />
            </Show>
          </TabPanel>
          <TabPanel maxW={{ '2xl': '1600px', base: '1400px' }} margin="0 auto">
            <Vault chainId={chainId} isTabSelected={selectedTab === 1} />
          </TabPanel>
          <TabPanel maxW={{ '2xl': '1600px', base: '1400px' }} margin="0 auto">
            <UserPositionsTable
              chainId={chainId}
              isTabSelected={selectedTab === 2}
            />
          </TabPanel>
          <TabPanel maxW={{ '2xl': '1600px', base: '1400px' }} margin="0 auto">
            <UserRewardsTable
              chainId={chainId}
              isTabSelected={selectedTab === 3}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

const PoolWithRedirect = () => {
  const chainId = useChainIdFromUrlParam()

  if (!chainId) {
    return <Navigate to="/404" />
  }

  return <Pool chainId={chainId} />
}

export default PoolWithRedirect
