import { Box, Divider } from '@chakra-ui/react'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import ChainTabs from 'components/ChainTabs'
import React from 'react'
import { WalletMenuEnum } from 'types/walletMenu'

import ConnectedAccount from './ConnectedAccount'
import WalletMyInfo from './WalleMyInfo'
import WalletBalances from './WalletBalances'

interface ConnectedScreenProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  isOpen: boolean
  onClose: () => void
  setChainId: React.Dispatch<
    React.SetStateAction<Exclude<ChainId, ChainId.MANTLE>>
  >
  setWalletMenuDisplay: (menuDisplay: WalletMenuEnum) => void
  walletAddress: string
}

const ConnectedScreen = ({
  chainId,
  isOpen,
  onClose,
  setChainId,
  setWalletMenuDisplay,
  walletAddress
}: ConnectedScreenProps) => {
  return (
    <>
      <ConnectedAccount
        chainId={chainId}
        walletAddress={walletAddress}
        stackProps={{ pb: 4, px: 6 }}
      />

      <Box px={4} pb={4}>
        <ChainTabs
          selectedChainId={chainId}
          setChainId={setChainId}
          tabProps={{ _dark: { _selected: { bg: 'joeDark.500' } } }}
        />
      </Box>

      <Divider />
      <WalletMyInfo
        walletAddress={walletAddress}
        setWalletMenuDisplay={setWalletMenuDisplay}
        chainId={chainId}
      />
      <Divider />
      <WalletBalances
        isVisible={isOpen}
        onTokenSelected={onClose}
        chainId={chainId}
      />
    </>
  )
}

export default ConnectedScreen
