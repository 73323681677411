import { FACTORY_ADDRESS } from '@traderjoe-xyz/sdk'
import { ChainId, Currency } from '@traderjoe-xyz/sdk-core'
import { ClassicAMMFactoryAbi } from 'constants/abi/classicAmmFactoryAbi'
import usePair from 'hooks/pool/v1/usePair'
import usePoolV1ByTokens from 'hooks/pool/v1/usePoolV1ByTokens'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getChainSlug } from 'utils/chains'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { getAddress, zeroAddress } from 'viem'
import { usePublicClient } from 'wagmi'

interface UsePoolV1LiquidityPanelProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  token0Address?: string
  token1Address?: string
}

const usePoolV1LiquidityPanel = ({
  chainId,
  token0Address,
  token1Address
}: UsePoolV1LiquidityPanelProps) => {
  const navigate = useNavigate()
  const publicClient = usePublicClient({ chainId })

  const [_currency0, setCurrency0] = useState<Currency | undefined>()
  const [_currency1, setCurrency1] = useState<Currency | undefined>()

  const {
    tokens: [paramCurrency0, paramCurrency1]
  } = useSdkCurrencies({
    addresses: [token0Address, token1Address],
    chainId
  })

  const currency0 = useMemo(
    () => _currency0 ?? paramCurrency0,
    [_currency0, paramCurrency0]
  )
  const currency1 = useMemo(
    () => _currency1 ?? paramCurrency1,
    [_currency1, paramCurrency1]
  )

  const poolQueryToken0 = wrappedCurrency(currency0)?.address
  const poolQueryToken1 = wrappedCurrency(currency1)?.address

  const {
    data: pool,
    isLoading: isLoadingPool,
    pairAddress
  } = usePoolV1ByTokens({
    chainId,
    tokens:
      poolQueryToken0 && poolQueryToken1
        ? [poolQueryToken0, poolQueryToken1]
        : undefined
  })

  const { data: pair, isLoading: isLoadingPair } = usePair({
    chainId,
    currency0,
    currency1,
    pairAddress
  })

  const [isLoadingNewPair, setIsLoadingNewPair] = useState(false)
  const onCurrencySelect = useCallback(
    async (currency: Currency, pairedCurrency?: Currency) => {
      const tokenAddress = wrappedCurrency(currency)?.address
      const pairedTokenAddress = wrappedCurrency(pairedCurrency)?.address
      if (!pairedTokenAddress || !tokenAddress) {
        return
      }
      setIsLoadingNewPair(true)
      const pairAddress = await publicClient?.readContract({
        abi: ClassicAMMFactoryAbi,
        address: FACTORY_ADDRESS[chainId],
        args: [getAddress(tokenAddress), getAddress(pairedTokenAddress)],
        functionName: 'getPair'
      })
      setIsLoadingNewPair(false)
      if (pairAddress && pairAddress !== zeroAddress) {
        setCurrency0(undefined)
        setCurrency1(undefined)
        navigate(
          `/${getChainSlug(chainId)}/pool/v1/${
            currency.isNative ? currency.symbol : tokenAddress
          }/${
            pairedCurrency?.isNative
              ? pairedCurrency?.symbol
              : pairedCurrency?.address
          }`
        )
      } else {
        navigate(`/${getChainSlug(chainId)}/pool/v1/create`)
      }
    },
    [navigate, chainId, publicClient]
  )

  const onToken0Select = useCallback(
    async (currency: Currency) => {
      const isEqualToCurrency1 =
        (currency.isNative && currency1?.isNative) ||
        (currency.isToken && currency1?.isToken && currency.equals(currency1))
      if (isEqualToCurrency1) {
        setCurrency0(currency)
        setCurrency1(undefined)
        onCurrencySelect(currency, currency0)
      } else {
        setCurrency0(currency)
        onCurrencySelect(currency, currency1)
      }
    },
    [onCurrencySelect, currency0, currency1]
  )

  const onToken1Select = useCallback(
    async (currency: Currency) => {
      const isEqualToCurrency0 =
        (currency.isNative && currency0?.isNative) ||
        (currency.isToken && currency0?.isToken && currency.equals(currency0))
      if (isEqualToCurrency0) {
        setCurrency0(undefined)
        setCurrency1(currency)
        onCurrencySelect(currency, currency1)
      } else {
        setCurrency1(currency)
        onCurrencySelect(currency, currency0)
      }
    },
    [onCurrencySelect, currency0, currency1]
  )

  return {
    isLoading: isLoadingPair || isLoadingPool || isLoadingNewPair,
    onToken0Select,
    onToken1Select,
    pair,
    pool,
    token0: currency0,
    token1: currency1
  }
}

export default usePoolV1LiquidityPanel
