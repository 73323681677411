import { VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { ChainId, Currency } from '@traderjoe-xyz/sdk-core'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CopyableError from 'components/CopyableError'
import CurrencyInput from 'components/CurrencyInput'
import MaxButton from 'components/MaxButton'
import Web3Button from 'components/Web3Button'
import useFarmDeposit from 'hooks/farm/useFarmDeposit'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import React from 'react'
import { getCurrencyAddress } from 'utils/wrappedCurrency'
import { BaseError } from 'viem'
import { GetBalanceData } from 'wagmi/query'

interface PoolRewardsDepositProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  farmAddress: string
  poolId: string
  balance?: GetBalanceData
  currency?: Currency
  refetchFarmBalances?: () => void
}

const PoolRewardsDeposit = ({
  balance,
  chainId,
  currency,
  farmAddress,
  poolId,
  refetchFarmBalances
}: PoolRewardsDepositProps) => {
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: currency
  })
  const tokenAddress = getCurrencyAddress(currency)
  const isExceedingBalance = balance
    ? Number(amount) > Number(balance.formatted)
    : false

  const { approvalType, approve, isApproved, isApproving, setApprovalType } =
    useApproveSpenderIfNeeded({
      amount: amountBN,
      chainId,
      spender: farmAddress,
      token: tokenAddress,
      tokenSymbol: currency?.symbol
    })

  const {
    deposit,
    error,
    isLoading: isDepositing
  } = useFarmDeposit({
    chainId,
    currency,
    enabled: isApproved === true,
    farmAddress,
    onSuccess: () => {
      setAmount('')
      refetchFarmBalances?.()
    },
    poolId,
    tokenAmount: amountBN
  })

  return (
    <VStack rowGap={{ base: 4, md: 6 }}>
      <CurrencyInput
        chainId={chainId}
        currency={currency}
        currencyAddress={tokenAddress}
        value={amount}
        onValueChange={setAmount}
        balance={balance?.formatted}
        error={
          isExceedingBalance ? t`Not enough ${currency?.symbol}` : undefined
        }
        rightElement={
          balance ? (
            <MaxButton
              borderRadius={12}
              balance={balance?.formatted}
              onClick={() => setAmount(balance.formatted)}
            />
          ) : undefined
        }
      />

      {!isApproved && !isExceedingBalance && approve && currency ? (
        <ApproveTokenButton
          data-cy="launch-deposit-approve-button"
          amount={amount}
          approvalType={approvalType}
          currencySymbol={currency.symbol}
          onApprovalTypeSelect={setApprovalType}
          isLoading={isApproving}
          loadingText={t`Approving ${currency.symbol}`}
          onClick={() => approve()}
        >
          {t`Approve ${currency.symbol}`}
        </ApproveTokenButton>
      ) : null}

      <VStack w="full">
        <Web3Button
          data-cy="launch-deposit-button"
          colorScheme="accent"
          variant="primary"
          size="xl"
          w="full"
          isDisabled={isExceedingBalance || !isApproved || !deposit}
          isLoading={isDepositing}
          loadingText={t`Depositing Tokens`}
          onClick={() => deposit?.()}
        >
          <Trans>Deposit</Trans>
        </Web3Button>

        {error ? (
          <CopyableError
            error={error.message}
            summary={(error as BaseError).shortMessage}
          />
        ) : null}
      </VStack>
    </VStack>
  )
}

export default PoolRewardsDeposit
