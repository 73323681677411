import { useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { RewardsInfo, RewardsStatus } from 'types/rewards'
import { arbitrum, avalanche, bsc } from 'wagmi/chains'

import useSdkCurrencies from './useSdkCurrencies'
import useTokenPriceUSD from './useTokenPriceUSD'

export interface Epoch {
  end: string
  epoch: number
  start: string
}

export const useFetchEpochs = () => {
  const url =
    'https://raw.githubusercontent.com/traderjoe-xyz/epoch-rewards/main/epochs.json'

  return useQuery<Epoch[], Error>({
    queryFn: () => fetch(url).then((res) => res.json()),
    queryKey: ['EpochsJson'],
    staleTime: 10 * 60 * 1000
  })
}

interface GithubRewardsInfo {
  chain: 'Arbitrum' | 'Avax' | 'BNB'
  epoch: number
  poolId: string
  rewardPerDay: number
  rewardToken?: string
}

const useFetchRewardsInfo = () => {
  const url =
    'https://raw.githubusercontent.com/traderjoe-xyz/epoch-rewards/main/rewards.json'

  return useQuery<GithubRewardsInfo[], Error>({
    queryFn: () => fetch(url).then((res) => res.json()),
    queryKey: ['RewardsJson'],
    staleTime: 10 * 60 * 1000
  })
}

interface UseEpochRewardsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  lbPairAddress?: string
  status?: RewardsStatus
}

const useEpochRewards = ({
  chainId,
  lbPairAddress,
  status
}: UseEpochRewardsProps) => {
  const { data, isLoading } = useFetchRewardsInfo()
  const { data: epochs, isLoading: isLoadingEpochs } = useFetchEpochs()

  // filter rewards by chain and status
  const rewards = (data || [])
    .map((reward) => {
      const epoch = epochs?.find((epoch) => epoch.epoch === reward.epoch)
      const start = epoch ? new Date(epoch.start) : undefined
      const end = epoch ? new Date(epoch.end) : undefined

      const status: RewardsStatus =
        end && end.getTime() < Date.now()
          ? 'ended'
          : start && start.getTime() > Date.now()
            ? 'upcoming'
            : 'ongoing'

      return {
        ...reward,
        end,
        start,
        status
      }
    })
    .filter((reward) => {
      // filter by pool address if needed
      if (
        lbPairAddress &&
        reward.poolId.toLowerCase() !== lbPairAddress.toLowerCase()
      ) {
        return false
      }

      // filter by status if needed
      if (status && reward.status !== status) {
        return false
      }

      // filter entry with 0 rewards
      if (reward.rewardPerDay === 0) {
        return false
      }

      // keep last 2 epochs
      const epochsCount = epochs?.length
      if (epochsCount === undefined) {
        return false
      }

      // filter upcoming epochs
      if (reward.status === 'upcoming') {
        return false
      }

      // filter by chain
      switch (reward.chain) {
        case 'Arbitrum':
          return chainId === arbitrum.id
        case 'Avax':
          return chainId === avalanche.id
        case 'BNB':
          return chainId === bsc.id
      }
    })

  // get rewards token price USD
  const tokenAddresses = [
    ...new Set(
      rewards.map((reward) => reward.rewardToken).filter(Boolean) as string[]
    )
  ]
  const { data: tokenPricesUsd, isLoading: isLoadingUsdPrices } =
    useTokenPriceUSD({
      chainId,
      enabled: !isLoading && rewards.length > 0,
      tokens: tokenAddresses
    })
  const tokenPricesUsdPerToken: { [tokenAddress: string]: number } | undefined =
    tokenPricesUsd
      ? tokenPricesUsd.reduce(
          (prev, price, i) => ({
            ...prev,
            [tokenAddresses[i]]: price
          }),
          {}
        )
      : undefined

  // get sdk token
  const { isLoading: isLoadingSdkTokens, tokens } = useSdkCurrencies({
    addresses: tokenAddresses,
    chainId
  })

  // calculate rewardsPerDayUsd
  const results: RewardsInfo[] = rewards.map((reward) => {
    return {
      ...reward,
      rewardsPerDayUsd:
        tokenPricesUsdPerToken && reward.rewardToken
          ? tokenPricesUsdPerToken[reward.rewardToken] * reward.rewardPerDay
          : undefined,
      sdkToken: tokens.find((token) =>
        token?.isToken
          ? token.address.toLowerCase() === reward.rewardToken?.toLowerCase()
          : false
      )
    }
  })

  // group rewards per epoch
  const allEpochs = epochs
    ?.map((epoch) => {
      const epochRewards = results.filter(
        (reward) => reward.epoch === epoch.epoch
      )

      if (epochRewards.length === 0) {
        return undefined
      }

      return epochRewards
    })
    .filter(Boolean) as RewardsInfo[][] | undefined

  return {
    epochRewards: allEpochs?.sort((a, b) => b[0].epoch - a[0].epoch),
    isLoading:
      isLoading || isLoadingEpochs || isLoadingUsdPrices || isLoadingSdkTokens
  }
}

export const useHasPoolRewarder = (poolAddress?: string) => {
  const { data, isLoading } = useFetchRewardsInfo()

  const hasRewarder = (data || []).some(
    (reward) => reward.poolId.toLowerCase() === poolAddress?.toLowerCase()
  )

  return {
    hasRewarder,
    isLoading
  }
}

export default useEpochRewards
