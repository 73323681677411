import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Box, Button, Hide, Image, Text } from '@chakra-ui/react'
import JoeLogo from 'assets/joe.png'
import React from 'react'
import { NavLink } from 'react-router-dom'

const StartTradingButton = () => {
  return (
    <NavLink to={`/trade`}>
      <Button
        variant="primary"
        size="lg"
        colorScheme="accent"
        h={{ base: '52px', md: '60px' }}
        fontSize={{ base: '18px', md: '20px' }}
        py={{ base: 4, md: 8 }}
        px={{ base: 4, md: 6 }}
        mt={{ base: '20px', md: '32px' }}
        position="relative"
        overflow="hidden"
        borderBottom="3px solid"
        borderColor="accent.700"
        whiteSpace="nowrap"
        transition="all 0.3s"
        role="group"
        _hover={{
          bg: 'accent.600',
          transform: 'scale(1.05)'
        }}
        _active={{
          transform: 'scale(0.95)'
        }}
      >
        <Box
          position="relative"
          display="inline-flex"
          alignItems="center"
          width="100%"
        >
          <Hide below="sm">
            <Box
              display="inline-flex"
              alignItems="center"
              transition="transform 0.2s"
              _groupHover={{ transform: 'translateX(-5px)' }}
            >
              <Image src={JoeLogo} w={{ base: '40px', md: '48px' }} mr={3} />
            </Box>
          </Hide>
          <Text textColor="white" flex="1" textAlign="center">
            Start Trading
          </Text>
          <Box
            as="span"
            display="inline-flex"
            alignItems="center"
            pl={4}
            transition="transform 0.2s"
            _groupHover={{ transform: 'translateX(5px)' }}
          >
            <ArrowForwardIcon boxSize={7} color="white" mt={'2px'} />
          </Box>
        </Box>
      </Button>
    </NavLink>
  )
}

export default StartTradingButton
