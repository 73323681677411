import { Center, HStack, Image } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import BoostLogo from 'assets/boost.png'
import GalacticaLogo from 'assets/galactica.png'
import SeedLogo from 'assets/garden_logomark.svg'
import MilkLogo from 'assets/milk.png'
import StablejackLogo from 'assets/stablejack.png'
import SuzakuLogo from 'assets/suzaku.png'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import React from 'react'
import { Point } from 'types/pool'

interface PointsBadgeProps {
  points: Point[]
  boxSize?: number
}

const PointsBadge = ({ boxSize = 6, points }: PointsBadgeProps) => {
  const elements: JSX.Element[] = []

  if (points.includes('galactica')) {
    elements.push(
      <TouchFriendlyTooltip
        key={'galactica'}
        label={t`Liquidity Providers are eligible to earn Galactica Points. Click for more info.`}
      >
        <Center
          p={1}
          bg="yellow.100"
          boxSize={boxSize}
          borderRadius="full"
          overflow="hidden"
          onClick={(e) => {
            e.stopPropagation()
            window.open(
              'https://traderjoexyz.notion.site/Welcome-to-the-Galactica-f5662481ae40489ba2b419cdf9510e59?pvs=4',
              '_blank'
            )
          }}
        >
          <Image src={GalacticaLogo} />
        </Center>
      </TouchFriendlyTooltip>
    )
  }

  if (points.includes('seed')) {
    elements.push(
      <TouchFriendlyTooltip
        key={'seed'}
        label={t`Earn 20 SEED for providing liquidity into this pool. Click for more info.`}
      >
        <Image
          src={SeedLogo}
          boxSize={boxSize}
          onClick={(e) => {
            e.stopPropagation()
            window.open(
              'https://garden.finance/blogs/season-3-quests/',
              '_blank'
            )
          }}
        />
      </TouchFriendlyTooltip>
    )
  }

  if (points.includes('stablejack')) {
    elements.push(
      <TouchFriendlyTooltip
        key={'stablejack'}
        label={t`This Liquidity Pool is earning 80x Discount Ticket Points. Click for more info.`}
      >
        <Image
          src={StablejackLogo}
          boxSize={boxSize}
          onClick={(e) => {
            e.stopPropagation()
            window.open(
              'https://docs.stablejack.xyz/stablejack/discount-tickets/the-death-of-airdrops-discount-tickets-as-a-new-paradigm',
              '_blank'
            )
          }}
        />
      </TouchFriendlyTooltip>
    )
  }

  if (points.includes('boost')) {
    elements.push(
      <TouchFriendlyTooltip
        key={'boost'}
        label={t`This Liquidity Pool is receiving AVAX rewards as part of the Boost Campaign. Click for more info.`}
      >
        <Image
          src={BoostLogo}
          boxSize={boxSize}
          onClick={(e) => {
            e.stopPropagation()
            window.open(
              'https://traderjoexyz.notion.site/GRAB-A-CAN-OF-BOOST-DEFI-ON-AVALANCHE-IS-GEARING-UP-ad42617947dc495e8fdaffec72a7a707',
              '_blank'
            )
          }}
        />
      </TouchFriendlyTooltip>
    )
  }

  if (points.includes('milk')) {
    elements.push(
      <TouchFriendlyTooltip
        key={'milk'}
        label={t`Earn 3.75 Milk Points daily per $1 in rsAVAX deposited. Click for more info.`}
      >
        <Image
          src={MilkLogo}
          boxSize={boxSize}
          onClick={(e) => {
            e.stopPropagation()
            window.open(
              'https://yieldyak.com/avalanche/milk/0xDf788AD40181894dA035B827cDF55C523bf52F67',
              '_blank'
            )
          }}
        />
      </TouchFriendlyTooltip>
    )
  }

  if (points.includes('suzaka')) {
    elements.push(
      <TouchFriendlyTooltip
        key={'suzaka'}
        label={t`Earn 10 Suzaku Points daily per $1 in rsAVAX deposited. Click for more info.`}
      >
        <Image
          src={SuzakuLogo}
          borderRadius="full"
          boxSize={boxSize}
          onClick={(e) => {
            e.stopPropagation()
            window.open(
              'https://yieldyak.com/avalanche/milk/0xDf788AD40181894dA035B827cDF55C523bf52F67',
              '_blank'
            )
          }}
        />
      </TouchFriendlyTooltip>
    )
  }

  return <HStack>{elements}</HStack>
}

export default PointsBadge
