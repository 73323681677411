import { useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Vault as DexbarnVault } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnVaultIntoVault } from 'utils/vault'

interface UseVaultProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  vaultAddress?: string
}

const useVault = ({ chainId, vaultAddress }: UseVaultProps) => {
  const chain = getDexbarnChainParam(chainId)
  const fetchVault = useDexbarnGet<DexbarnVault>(
    `/v1/vaults/${chain}/${vaultAddress?.toLowerCase()}`,
    chainId
  )
  return useQuery({
    enabled: !!vaultAddress,
    queryFn: () => fetchVault(),
    queryKey: ['VaultQuery', chainId, vaultAddress],
    select: (vault) => convertDexbarnVaultIntoVault(vault)
  })
}
export default useVault
