import { useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { SwapEvent } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

interface UseLBPairProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  address?: string
  enabled?: boolean
  first?: number
}

const useLBPairSwaps = ({
  address,
  chainId,
  enabled = true,
  first = 10
}: UseLBPairProps) => {
  const fetchSwapEvents = useDexbarnGet<SwapEvent[]>(
    `/v1/events/swap/${getDexbarnChainParam(
      chainId
    )}/${address?.toLowerCase()}?pageNum=1&pageSize=${first}`,
    chainId
  )
  const result = useQuery<SwapEvent[]>({
    enabled: !!address && enabled,
    queryFn: () => fetchSwapEvents(),
    queryKey: ['LBPairSwapsQuery', address, chainId, first]
  })
  return result
}

export default useLBPairSwaps
