import {
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Text
} from '@chakra-ui/react'
import { ChainId, CNATIVE, Currency, Token } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import { JOE_TOKEN } from 'constants/tokens'
import useTokensList from 'hooks/tokensList/useTokensList'
import React from 'react'
import { TokenInfoTag } from 'types/tokensList'
import {
  ARB,
  BTCB,
  BUSD,
  DAI,
  USDC,
  USDCe,
  USDT,
  USDTe,
  WBTC,
  WETH
} from 'utils/swap'

interface TradeCurrencyShortcutsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  onCurrencyClick: (currency: Currency) => void
  otherSelectedCurrency?: Currency
}

const TradeCurrencyShortcuts = ({
  chainId,
  onCurrencyClick,
  otherSelectedCurrency
}: TradeCurrencyShortcutsProps) => {
  const {
    isLoading: isLoadingTokens,
    isLoadingBalances,
    tokens: allTokens
  } = useTokensList({
    activeTag: TokenInfoTag.ALL,
    chainId
  })

  const topTokensPerChain = {
    [ChainId.ARBITRUM_ONE]: [
      CNATIVE.onChain(chainId),
      USDC[chainId],
      USDT[chainId],
      USDCe[ChainId.ARBITRUM_ONE],
      ARB[ChainId.ARBITRUM_ONE],
      JOE_TOKEN[chainId],
      WBTC[ChainId.ARBITRUM_ONE]
    ],
    [ChainId.AVALANCHE]: [
      CNATIVE.onChain(chainId),
      USDC[chainId],
      USDT[chainId],
      BTCB[ChainId.AVALANCHE],
      WETH[ChainId.AVALANCHE],
      JOE_TOKEN[chainId],
      USDCe[ChainId.AVALANCHE],
      USDTe[ChainId.AVALANCHE]
    ],
    [ChainId.BNB_CHAIN]: [
      CNATIVE.onChain(chainId),
      USDC[chainId],
      USDT[chainId],
      BUSD[ChainId.BNB_CHAIN],
      JOE_TOKEN[chainId]
    ],
    [ChainId.ETHEREUM]: [
      CNATIVE.onChain(chainId),
      USDC[chainId],
      USDT[chainId],
      DAI[ChainId.ETHEREUM]
    ],
    [ChainId.ARB_GOERLI]: [CNATIVE.onChain(chainId)],
    [ChainId.BNB_TESTNET]: [CNATIVE.onChain(chainId)],
    [ChainId.FUJI]: [CNATIVE.onChain(chainId)]
  }

  const tokens = allTokens
    .filter(
      (token) =>
        (token.balance ? Number(token.balance) > 0 : false) &&
        otherSelectedCurrency?.symbol !== token.symbol
    )
    .slice(0, 3)
    .map((token) => {
      if (token.address) {
        return new Token(
          token.chainId,
          token.address,
          token.decimals,
          token.symbol,
          token.name
        )
      }
      return CNATIVE.onChain(chainId)
    })

  if (tokens.length < 3) {
    const defaultTokens = topTokensPerChain[chainId]
    defaultTokens.forEach((token) => {
      if (
        !token ||
        token.symbol === otherSelectedCurrency?.symbol ||
        tokens.length >= 3
      )
        return

      if (token.isNative && !tokens.some((t) => t.isNative)) {
        tokens.push(token)
      }
      if (
        token &&
        token.isToken &&
        !tokens.some(
          (t) =>
            t.isToken && t.address.toLowerCase() === token.address.toLowerCase()
        )
      ) {
        tokens.push(token)
      }
    })
  }

  if (isLoadingTokens || isLoadingBalances) {
    return (
      <HStack>
        <Skeleton boxSize={8} borderRadius="full" />
        <Skeleton boxSize={8} borderRadius="full" />
        <Skeleton boxSize={8} borderRadius="full" />
      </HStack>
    )
  }

  return (
    <HStack data-cy="trade-currency-shortcuts">
      {tokens.map((token) => {
        return (
          <Popover
            key={token.symbol}
            placement="top"
            trigger="hover"
            variant="responsive"
          >
            <PopoverTrigger>
              <IconButton
                data-cy={`trade-currency-shortcut-${token.symbol}`}
                borderRadius="full"
                size="sm"
                aria-label={`select ${token.symbol}`}
                icon={
                  <CurrencyLogo
                    key={token.symbol}
                    address={token.isToken ? token.address : undefined}
                    symbol={token.symbol}
                    boxSize={6}
                  />
                }
                onClick={() => onCurrencyClick(token)}
              />
            </PopoverTrigger>
            <PopoverContent p={0}>
              <PopoverBody px={2} py={1}>
                <HStack spacing={1}>
                  <Text fontSize="sm">{token.name}</Text>
                  <Text fontSize="sm" textColor="textSecondary">
                    {token.symbol}
                  </Text>
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )
      })}
    </HStack>
  )
}

export default TradeCurrencyShortcuts
