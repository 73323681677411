import { useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { UserFarmPosition } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { useAccount } from 'wagmi'

interface UseGetUserFarmBalancesProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  enabled?: boolean
}

const useGetUserFarmBalances = ({
  chainId,
  enabled
}: UseGetUserFarmBalancesProps) => {
  const { address: userAddress } = useAccount()
  const chain = getDexbarnChainParam(chainId)
  const fetchUserFarmBalances = useDexbarnGet<UserFarmPosition[]>(
    `/v1/user/${chain}/${userAddress}/farms`,
    chainId
  )

  return useQuery<UserFarmPosition[]>({
    enabled: enabled && !!userAddress,
    queryFn: () => fetchUserFarmBalances(),
    queryKey: ['UserFarmBalances', chainId, userAddress]
  })
}

export default useGetUserFarmBalances
