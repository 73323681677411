import { useQuery } from '@tanstack/react-query'
import { ChainId, Currency } from '@traderjoe-xyz/sdk-core'
import { routerApiClient, routerTestnetClient } from 'constants/dexbarn'
import { useState } from 'react'
import { DexbarnRoute, DexbarnRoutes, Route } from 'types/router'
import { getDexbarnChainParam } from 'utils/chains'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { formatUnits } from 'viem'

interface UseGetRoutesFromBarnProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  enabled: boolean
  amount?: string
  currencyIn?: Currency
  currencyOut?: Currency
  refetchInterval?: number
}

const useGetRoutesFromBarn = ({
  amount,
  chainId,
  currencyIn,
  currencyOut,
  enabled,
  refetchInterval
}: UseGetRoutesFromBarnProps) => {
  const isTestnet =
    chainId === ChainId.FUJI ||
    chainId === ChainId.ARB_GOERLI ||
    chainId === ChainId.BNB_TESTNET
  const client = isTestnet ? routerTestnetClient : routerApiClient

  const tokenIn = wrappedCurrency(currencyIn)
  const tokenOut = wrappedCurrency(currencyOut)

  const params =
    tokenIn && tokenOut && amount
      ? {
          amountIn: amount,
          tokenIn: tokenIn.address.toLowerCase(),
          tokenOut: tokenOut.address.toLowerCase()
        }
      : undefined

  const [lastFetchTime, setLastFetchTime] = useState(Date.now())
  const {
    data: routes,
    isFetching: isFetchingPaths,
    refetch: refetchPaths
  } = useQuery<DexbarnRoute[], Error, Route[]>({
    enabled: enabled && !!params,
    queryFn: async () => {
      setLastFetchTime(Date.now())
      const response = await client.get<DexbarnRoutes>(
        `/v2/aggregator/routes/${getDexbarnChainParam(chainId)}`,
        { params }
      )
      return response.data.routes
    },
    queryKey: ['GetDexbarnRoutes', chainId, params],
    refetchInterval,
    select: (data) => {
      if (!currencyIn || !currencyOut) return []

      return data.map((route) => {
        return {
          ...route,
          amountIn: {
            formatted: formatUnits(BigInt(route.amountIn), currencyIn.decimals),
            value: BigInt(route.amountIn)
          },
          amountOut: {
            formatted: formatUnits(
              BigInt(route.amountOut),
              currencyOut.decimals
            ),
            value: BigInt(route.amountOut)
          },
          currencyIn,
          currencyOut
        }
      })
    }
  })

  return {
    data: routes,
    isFetching: isFetchingPaths,
    lastFetchTime,
    refetch: refetchPaths
  }
}

export default useGetRoutesFromBarn
