import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import {
  LIQUIDITY_AMOUNTS_HELPER_ADDRESS,
  LIQUIDITY_HELPER_V2_ADDRESS,
  LiquidityAmountsHelperABI,
  LiquidityHelperV2ABI
} from '@traderjoe-xyz/sdk-v2'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { UserLBPosition } from 'types/poolV2'
import { getDexbarnChainParam } from 'utils/chains'
import { formatUnits, getAddress, zeroAddress } from 'viem'
import { useAccount, usePublicClient } from 'wagmi'

const useUserLBPositions = ({
  chainId,
  enabled
}: {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  enabled: boolean
}): {
  data: UserLBPosition[]
  isLoading: boolean
} => {
  const { address: account } = useAccount()
  const chain = getDexbarnChainParam(chainId)

  const fetchUserPoolIds = useDexbarnGet<UserLBPosition[]>(
    `/v1/user/pool-ids/${account?.toLowerCase()}/${chain}`,
    chainId
  )
  const result = useQuery<UserLBPosition[]>({
    enabled: !!account && enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchUserPoolIds({ params: { pageSize: 50 } }),
    queryKey: ['UserLBPositionsWithBinIds', chainId, account]
  })
  const positions = result.data ?? []

  // fetch total amounts X/Y for each position
  const publicClient = usePublicClient({ chainId })
  const { data: totalAmounts, isLoading } = useQuery({
    enabled: !!account && positions.length > 0,
    queryFn: async () => {
      if (!publicClient || !account || !positions.length) return

      const liquidityHelperV2Address = LIQUIDITY_HELPER_V2_ADDRESS[chainId]
      const isLiquidityHelperV2Deployed =
        liquidityHelperV2Address !== zeroAddress

      const results = await publicClient.multicall({
        contracts: positions.map((position) => {
          if (position.version !== 'v2.0' && isLiquidityHelperV2Deployed) {
            return {
              abi: LiquidityHelperV2ABI,
              address: liquidityHelperV2Address,
              args: [
                getAddress(position.poolAddress),
                account,
                position.binIds
              ],
              functionName: 'getAmountsOf'
            } as const
          }

          return {
            abi: LiquidityAmountsHelperABI,
            address: LIQUIDITY_AMOUNTS_HELPER_ADDRESS[chainId],
            args: [account, position.binIds, getAddress(position.poolAddress)],
            functionName: 'getTotalAmountsOf'
          } as const
        })
      })

      return results.map((result, i) => {
        switch (result.status) {
          case 'success':
            if (
              positions[i].version !== 'v2.0' &&
              isLiquidityHelperV2Deployed
            ) {
              const amounts = result.result as [bigint[], bigint[]]
              const totalAmountX = amounts[0].reduce(
                (acc, curr) => acc + curr,
                BigInt(0)
              )
              const totalAmountY = amounts[1].reduce(
                (acc, curr) => acc + curr,
                BigInt(0)
              )
              return {
                totalAmountX,
                totalAmountY
              }
            } else {
              const totalAmounts = result.result as [bigint, bigint]
              return {
                totalAmountX: totalAmounts[0],
                totalAmountY: totalAmounts[1]
              }
            }
          case 'failure':
            return undefined
        }
      })
    },
    queryKey: [
      'UserLBPositionsTotalAmounts',
      chainId,
      account,
      positions.map((p) => {
        return { binIds: p.binIds, chain: p.chain, poolAddress: p.poolAddress }
      })
    ]
  })

  const userLBPositions = positions.map((position, i) => {
    const totalAmountX = totalAmounts?.[i]?.totalAmountX ?? BigInt(0)
    const totalAmountY = totalAmounts?.[i]?.totalAmountY ?? BigInt(0)
    return {
      ...position,
      totalAmountX: {
        formatted: formatUnits(totalAmountX, position.tokenX.decimals),
        value: totalAmountX
      },
      totalAmountY: {
        formatted: formatUnits(totalAmountY, position.tokenY.decimals),
        value: totalAmountY
      }
    }
  })

  return {
    data: userLBPositions,
    isLoading: isLoading || result.isLoading
  }
}

export default useUserLBPositions
