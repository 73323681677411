import { Box, Flex, Heading, SimpleGrid, useToken } from '@chakra-ui/react'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import AnalyticsChart from 'components/AnalyticsChart'
import AnalyticsStat from 'components/AnalyticsStat'
import usePoolAnalyticsChange from 'hooks/analytics/usePoolAnalyticsChange'
import usePoolDetailV1Analytics from 'hooks/analytics/usePoolDetailV1Analytics'
import React from 'react'
import { Pool } from 'types/pool'
import { formattedNum } from 'utils/format'

interface PoolDetailOverviewProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  pool?: Pool
}

const PoolDetailOverview = ({ chainId, pool }: PoolDetailOverviewProps) => {
  const { data: { chartData, pairDayDatas } = {}, isLoading } =
    usePoolDetailV1Analytics({
      chainId,
      poolAddress: pool?.pairAddress
    })
  const poolChange = usePoolAnalyticsChange({ pairDayDatas })

  const [graphPurple, graphGreen] = useToken('colors', [
    'graphPurpleDark',
    'graphGreenDark'
  ])

  const fmtLiquidity = pool
    ? formattedNum(pool.liquidityUsd, { usd: true })
    : '--'
  const fmtVolume = pool ? formattedNum(pool.volumeUsd, { usd: true }) : '--'
  const fmtFee = pool ? formattedNum(pool.feesUsd, { usd: true }) : '--'
  const fmtApr = pool ? `${Number(pool.apr * 100).toFixed(2)}%` : '--'

  return (
    <Flex flexDir="column" gap={4}>
      <SimpleGrid gap={4} columns={2}>
        <AnalyticsStat
          title="Liquidity"
          value={fmtLiquidity}
          change={poolChange?.liquidityChange}
        />
        <AnalyticsStat
          title="Volume (24h)"
          value={fmtVolume}
          change={poolChange?.volumeChange}
        />
        <AnalyticsStat
          title="Fees (24h)"
          value={fmtFee}
          change={poolChange?.feeChange}
        />
        <AnalyticsStat title="APR" value={fmtApr} />
      </SimpleGrid>
      <Box
        py={{ base: 4, md: 6 }}
        px={{ base: 4, md: 8 }}
        bg="bgCard"
        border="1px"
        borderColor="border"
        borderRadius="2xl"
      >
        <AnalyticsChart
          id="tvl"
          data={chartData?.liquidity ?? []}
          tagline="TVL (Total Locked Value)"
          header={<Heading size="md">{fmtLiquidity}</Heading>}
          fill={graphPurple}
          stroke={graphPurple}
          h={{ base: '100px', md: '250px' }}
          tooltipTitle="TVL"
          isLoading={isLoading}
        />
      </Box>
      <Box
        py={{ base: 4, md: 6 }}
        px={{ base: 4, md: 8 }}
        bg="bgCard"
        border="1px"
        borderColor="border"
        borderRadius="2xl"
      >
        <AnalyticsChart
          id="volume"
          data={chartData?.volume ?? []}
          tagline="Volume (24h)"
          header={<Heading size="md">{fmtVolume}</Heading>}
          fill={graphGreen}
          stroke={graphGreen}
          h={{ base: '100px', md: '250px' }}
          tooltipTitle="Volume (24h)"
          isLoading={isLoading}
        />
      </Box>
    </Flex>
  )
}

export default PoolDetailOverview
