import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import {
  LIMIT_ORDER_MANAGER_ADDRESS,
  LimitOrderManagerABI
} from '@traderjoe-xyz/sdk-v2'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useMemo } from 'react'
import {
  LimitOrder as DexbarnLimitOrder,
  OrderType as DexbarnOrderType
} from 'types/dexbarn'
import { OrderType } from 'types/limitOrder'
import { BaseError, getAddress } from 'viem'
import { useWriteContract } from 'wagmi'

interface UseCancelOrderProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  limitOrder: DexbarnLimitOrder | undefined
  onTransactionSuccess: () => void
  enabled?: boolean
  minAmounts?: readonly [bigint, bigint]
}

const useCancelOrder = ({
  chainId,
  limitOrder,
  minAmounts,
  onTransactionSuccess
}: UseCancelOrderProps) => {
  const {
    binId,
    orderType,
    pairLbBinStep: lbBinStep,
    tokenOut
  } = limitOrder || {}
  const { tokenX, tokenY } = tokenOut || {}
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const args =
    tokenX?.tokenId &&
    tokenY?.tokenId &&
    lbBinStep &&
    binId &&
    orderType &&
    minAmounts
      ? ([
          getAddress(tokenX.tokenId),
          getAddress(tokenY.tokenId),
          lbBinStep,
          orderType === DexbarnOrderType.BID ? OrderType.BID : OrderType.ASK,
          binId,
          minAmounts[0],
          minAmounts[1]
        ] as const)
      : undefined

  const {
    data,
    error: contractError,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Cancel limit order`
        addRecentTransaction({ description, hash })
        addTransactionToast({ description, hash })
      }
    }
  })

  const cancelOrder = args
    ? () =>
        writeContract({
          abi: LimitOrderManagerABI,
          address: getAddress(LIMIT_ORDER_MANAGER_ADDRESS[chainId]),
          args,
          chainId,
          functionName: 'cancelOrder'
        })
    : undefined

  const { data: receipt } = useWaitForTransactionReceipt({
    chainId,
    hash: data,
    onTransactionSuccess
  })

  const error = useMemo(() => {
    if (!contractError) return undefined

    const isSlippageTooLow = contractError.message.includes(
      'LimitOrderManager__InsufficientWithdrawalAmounts'
    )

    return {
      message: contractError.message,
      summary: isSlippageTooLow
        ? t`Unable to cancel: the slippage is too low. Please increase it from the settings.`
        : (contractError as BaseError).shortMessage
    }
  }, [contractError])

  return {
    cancelOrder,
    error,
    isLoading: isPending,
    isSuccess: receipt?.status === 'success'
  }
}

export default useCancelOrder
