import { ChainId } from '@traderjoe-xyz/sdk-core'
import { LBPairABI } from '@traderjoe-xyz/sdk-v2'
import { getAddress } from 'viem'
import { useReadContracts } from 'wagmi'

interface UseLBTokensSupplyProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  binIds?: number[]
  lbPairAddress?: string
}

const useLBTokensSupply = ({
  binIds,
  chainId,
  lbPairAddress
}: UseLBTokensSupplyProps) => {
  const contract = {
    abi: LBPairABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined
  }
  const reads = useReadContracts({
    contracts: binIds?.map((binId) => ({
      ...contract,
      args: [BigInt(binId)],
      chainId,
      functionName: 'totalSupply'
    })),
    query: { enabled: !!binIds && binIds.length > 0 }
  })
  return {
    ...reads,
    data: reads.data
      ? (reads.data.map((read) => read.result) as unknown[] as bigint[])
      : undefined
  }
}

export default useLBTokensSupply
