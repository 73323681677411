import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  Hide,
  Link,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ChainId, CNATIVE } from '@traderjoe-xyz/sdk-core'
import { JOE_TOKEN } from 'constants/tokens'
import useTokensList from 'hooks/tokensList/useTokensList'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { WalletIcon } from 'theme/icons'
import { TokenInfoTag } from 'types/tokensList'
import { getChainSlug } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { DAI, USDC, USDT } from 'utils/swap'
import { getAddress } from 'viem'

import CurrencyLabel from './CurrencyLabel'

type TokenBalanceInfo = {
  address?: string
  balance?: string
  name?: string
  symbol?: string
  totalValueUsd?: number
}

interface WalletBalancesProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  isVisible: boolean
  onTokenSelected: (token: TokenBalanceInfo) => void
}

const WalletBalances = ({
  chainId,
  isVisible,
  onTokenSelected
}: WalletBalancesProps) => {
  const {
    isLoading: isLoadingTokens,
    isLoadingBalances,
    tokens: allTokens
  } = useTokensList({
    activeTag: TokenInfoTag.ALL,
    chainId,
    enabled: isVisible
  })
  const isLoading = isLoadingBalances || isLoadingTokens

  const topTokensPerChain = {
    [ChainId.ARBITRUM_ONE]: [
      CNATIVE.onChain(chainId),
      USDC[chainId],
      USDT[chainId],
      JOE_TOKEN[chainId]
    ],
    [ChainId.AVALANCHE]: [
      CNATIVE.onChain(chainId),
      USDC[chainId],
      USDT[chainId],
      JOE_TOKEN[chainId]
    ],
    [ChainId.BNB_CHAIN]: [
      CNATIVE.onChain(chainId),
      USDC[chainId],
      USDT[chainId],
      JOE_TOKEN[chainId]
    ],
    [ChainId.ETHEREUM]: [
      CNATIVE.onChain(chainId),
      USDC[chainId],
      USDT[chainId],
      DAI[ChainId.ETHEREUM]
    ],
    [ChainId.ARB_GOERLI]: [CNATIVE.onChain(chainId)],
    [ChainId.BNB_TESTNET]: [CNATIVE.onChain(chainId)],
    [ChainId.FUJI]: [CNATIVE.onChain(chainId)]
  }

  const tokensWithNonZeroBalance = allTokens.filter((token) =>
    token.balance ? Number(token.balance) > 0 : false
  )
  const [showAllTokens, setShowAllTokens] = useState(false)
  const minVisibleTokensCount = 4
  const visibleTokensCount = showAllTokens
    ? tokensWithNonZeroBalance.length
    : minVisibleTokensCount
  const tokens: TokenBalanceInfo[] = tokensWithNonZeroBalance.slice(
    0,
    visibleTokensCount
  )

  if (tokens.length < minVisibleTokensCount) {
    const defaultTokens = topTokensPerChain[chainId]
    defaultTokens.forEach((token) => {
      if (!token || tokens.length >= minVisibleTokensCount) return

      if (
        token.isNative &&
        !tokens.some((t) => t.symbol === CNATIVE.onChain(chainId).symbol)
      ) {
        tokens.push({
          address: undefined,
          balance: undefined,
          name: token.name,
          symbol: token.symbol,
          totalValueUsd: undefined
        })
      } else if (
        token &&
        token.isToken &&
        !tokens.some(
          (t) => t.address?.toLowerCase() === token.address.toLowerCase()
        )
      ) {
        tokens.push({
          address: getAddress(token.address),
          balance: undefined,
          name: token.name,
          symbol: token.symbol,
          totalValueUsd: undefined
        })
      }
    })
  }

  // reset show all tokens when wallet menu is closed
  useEffect(() => {
    if (isVisible) {
      setShowAllTokens(false)
    }
  }, [isVisible])

  return (
    <VStack
      w="full"
      alignItems="flex-start"
      pt={{ base: 0, lg: 6 }}
      px={{ base: 0, lg: 2 }}
      spacing={0}
    >
      <Hide below="lg">
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          w="full"
          px={4}
          mb={4}
        >
          <WalletIcon boxSize="20px" mr={4} fill="textSecondary" />
          <Text fontWeight="semibold">
            <Trans>Wallet</Trans>
          </Text>
        </Flex>
      </Hide>

      {isLoading && tokens.length === 0 ? (
        <Center w="full" minH="100px">
          <Spinner size="sm" />
        </Center>
      ) : (
        tokens.map((token) => {
          return (
            <Link
              key={token.name}
              as={RouterLink}
              to={
                token.address
                  ? `/${getChainSlug(chainId)}/trade?inputCurrency=${
                      token.address
                    }`
                  : `/${getChainSlug(chainId)}/trade`
              }
              w="full"
              _hover={{}}
              onClick={() => onTokenSelected(token)}
            >
              <CurrencyLabel
                currencyAddress={token.address}
                currencySymbol={token.symbol}
                currencyName={token.name}
                balance={
                  token.balance
                    ? formattedNum(token.balance, { places: 2, usd: false })
                    : '0'
                }
                balanceUsd={
                  token.totalValueUsd
                    ? formattedNum(token.totalValueUsd, {
                        places: 2,
                        usd: true
                      })
                    : '$0'
                }
              />
            </Link>
          )
        })
      )}

      {tokensWithNonZeroBalance.length > minVisibleTokensCount ? (
        <Button
          alignSelf="center"
          variant="ghost"
          w="calc(100% - 16px)"
          mt={2}
          rightIcon={showAllTokens ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={() => {
            setShowAllTokens((previous) => !previous)
          }}
        >
          {showAllTokens ? (
            <Trans>Hide All ({tokensWithNonZeroBalance.length})</Trans>
          ) : (
            <Trans>View All ({tokensWithNonZeroBalance.length})</Trans>
          )}
        </Button>
      ) : null}
    </VStack>
  )
}

export default WalletBalances
