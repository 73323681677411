import { ChainId, Token } from '@traderjoe-xyz/sdk-core'
import { wrappedCurrency } from 'utils/wrappedCurrency'

import useOrderableList from './useOrderableList'

interface UseIsPairOrderableProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  tokenX?: Token
  tokenY?: Token
}

const useIsPairOrderable = ({
  chainId,
  tokenX,
  tokenY
}: UseIsPairOrderableProps) => {
  const orderablePairs = useOrderableList({
    chainId
  })

  const isOrderable =
    tokenX &&
    tokenY &&
    orderablePairs.length > 0 &&
    orderablePairs.some(
      (pair) =>
        (wrappedCurrency(pair.tokenX)?.address === tokenX?.address &&
          wrappedCurrency(pair.tokenY)?.address === tokenY?.address) ||
        (wrappedCurrency(pair.tokenY)?.address === tokenX?.address &&
          wrappedCurrency(pair.tokenX)?.address === tokenY?.address)
    )

  return isOrderable
}

export default useIsPairOrderable
