import { ChainId } from '@traderjoe-xyz/sdk-core'
import { RawAxiosRequestConfig } from 'axios'
import {
  dexbarnBnbClient,
  dexbarnClient,
  dexbarnTestnetClient
} from 'constants/dexbarn'
import { useCallback } from 'react'
import { getChain } from 'utils/chains'
import { avalancheFuji } from 'wagmi/chains'

export const useDexbarnClient = ({
  chainId
}: {
  chainId: Exclude<ChainId, ChainId.MANTLE>
}) => {
  const chain = getChain(chainId)

  if (chain?.testnet) {
    return dexbarnTestnetClient
  }

  if (chain?.id === ChainId.BNB_CHAIN) {
    return dexbarnBnbClient
  }

  return dexbarnClient
}

export const useDexbarnGet = <T = any, D = any>(
  url: string,
  chainId: Exclude<ChainId, ChainId.MANTLE>,
  config?: RawAxiosRequestConfig<D>
) => {
  const client = useDexbarnClient({ chainId })
  const chain = getChain(chainId)

  return useCallback(
    async (overridesConfig?: RawAxiosRequestConfig<D>) => {
      if (chain?.testnet && chain?.id !== avalancheFuji.id) {
        // dexbarn only supports fuji testnets
        return Promise.reject('[Dexbarn] Unsupported chain')
      }

      if (chain?.id === ChainId.BNB_CHAIN) {
        const { data } = await client.get<T>(`${url}.json`)
        return data
      }

      const { data } = await client.get<T>(`${url}`, overridesConfig ?? config)
      return data
    },
    [client, url, config, chain]
  )
}

export const useDexbarnPost = <T = any, D = any>(
  url: string,
  chainId: Exclude<ChainId, ChainId.MANTLE>,
  body?: D
) => {
  const client = useDexbarnClient({ chainId })
  return useCallback(async () => {
    const { data } = await client.post<T>(url, body)
    return data
  }, [client, url, body])
}
