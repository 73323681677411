import { useInfiniteQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useMemo } from 'react'
import { DexbarnPoolV1, PoolQueryParam } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnPoolsV1ToPools } from 'utils/poolV1'

dayjs.extend(utc)

interface UsePoolsV1Props {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  enabled: boolean
  orderBy: PoolQueryParam.OrderBy
  pageSize: number
}

const usePoolsV1 = ({
  chainId,
  enabled,
  orderBy,
  pageSize
}: UsePoolsV1Props) => {
  const chain = getDexbarnChainParam(chainId)

  const fetchPools = useDexbarnGet(`/v1/joev1/pools/${chain}`, chainId)
  const result = useInfiniteQuery<DexbarnPoolV1[]>({
    enabled,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.length % pageSize === 0 ? allPages.length + 1 : undefined,
    initialPageParam: 1,
    queryFn: ({ pageParam }) =>
      fetchPools({
        params: {
          filterBy: '1d',
          orderBy,
          pageNum: pageParam,
          pageSize
        }
      }),
    queryKey: ['DexbarnPoolsV1', chainId, pageSize, orderBy]
  })

  const data = useMemo(() => {
    return convertDexbarnPoolsV1ToPools({
      dexbarnPools: result.data?.pages.map((pools) => pools).flat() ?? []
    })
  }, [result.data])

  return {
    ...result,
    data
  }
}

export default usePoolsV1
