import { Button, ButtonProps } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { supportedChains } from 'constants/chains'
import useChainFromUrlParam from 'hooks/useChainFromUrlParam'
import useSwitchChain from 'hooks/useSwitchChain'
import React from 'react'
import { useAccount } from 'wagmi'

interface Web3ButtonProps extends ButtonProps {
  chainId?: Exclude<ChainId, ChainId.MANTLE>
}

const Web3Button = ({ chainId, ...props }: Web3ButtonProps) => {
  const { chain: walletChain, isConnected } = useAccount()
  const { openConnectModal } = useConnectModal()

  const { switchChain } = useSwitchChain()

  const selectedChain = supportedChains.find((chain) => chain.id === chainId)

  const chainInUrlParam = useChainFromUrlParam()
  const targetChain = chainInUrlParam ?? selectedChain

  return isConnected && targetChain && walletChain?.id !== targetChain.id ? (
    <Button
      {...props}
      leftIcon={undefined}
      rightIcon={undefined}
      isDisabled={false}
      onClick={() => switchChain({ chainId: targetChain.id })}
    >
      {t`Switch to ${targetChain.name}`}
    </Button>
  ) : isConnected ? (
    <Button {...props} />
  ) : (
    <Button {...props} isDisabled={false} onClick={openConnectModal}>
      <Trans>Connect Wallet</Trans>
    </Button>
  )
}

export default Web3Button
