import { useQuery } from '@tanstack/react-query'
import { JoePairABI } from '@traderjoe-xyz/sdk'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useMemo } from 'react'
import { DexbarnUserPoolPositionV1 } from 'types/dexbarn'
import { UserPoolPosition } from 'types/poolV1'
import { getDexbarnChainParam } from 'utils/chains'
import { formatUnits, getAddress } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

interface UseUserPoolV1PositionsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  enabled: boolean
}

const useUserPoolV1Positions = ({
  chainId,
  enabled
}: UseUserPoolV1PositionsProps) => {
  const { address: account } = useAccount()

  // fetch user pool positons
  const fetchPoolPositions = useDexbarnGet<DexbarnUserPoolPositionV1[]>(
    `/v1/joev1/user/pool-positions/${account}/${getDexbarnChainParam(chainId)}`,
    chainId
  )
  const { data: userPositions = [], isLoading: isLoadingUserPositions } =
    useQuery({
      enabled: !!account && enabled,
      queryFn: () => fetchPoolPositions(),
      queryKey: ['UserPoolPositionsQuery', account, chainId]
    })

  // get user pool positions amounts
  const totalSupplyCalls = userPositions.map(
    (position) =>
      ({
        abi: JoePairABI,
        address: getAddress(position.pairId),
        chainId,
        functionName: 'totalSupply'
      }) as const
  )

  const getReservesCalls = userPositions.map(
    (position) =>
      ({
        abi: JoePairABI,
        address: getAddress(position.pairId),
        chainId,
        functionName: 'getReserves'
      }) as const
  )

  const { data: pairData, isLoading } = useReadContracts({
    contracts: [...totalSupplyCalls, ...getReservesCalls],
    query: {
      enabled: userPositions.length > 0
    }
  })

  const data = useMemo(() => {
    if (!pairData) {
      return []
    }

    return userPositions.map((position, i) => {
      const totalSupply = pairData[i].result as bigint
      const reserves = pairData[i + userPositions.length].result as [
        bigint,
        bigint,
        number
      ]

      const reserveX = reserves?.[0]
      const reserveY = reserves?.[1]

      const amountX = (BigInt(position.liquidity) * reserveX) / totalSupply
      const amountY = (BigInt(position.liquidity) * reserveY) / totalSupply

      return {
        ...position,
        id: position.pairId,
        name: `${position.tokenX.symbol}-${position.tokenY.symbol}`,
        pooledToken0: {
          formatted: formatUnits(amountX, position.tokenX.decimals),
          value: amountX
        },
        pooledToken1: {
          formatted: formatUnits(amountY, position.tokenY.decimals),
          value: amountY
        },
        token0: position.tokenX,
        token1: position.tokenY
      } satisfies UserPoolPosition
    })
  }, [userPositions, pairData])

  return {
    data,
    isLoading: isLoading || isLoadingUserPositions
  }
}

export default useUserPoolV1Positions
