import { ChainId } from '@traderjoe-xyz/sdk-core'

import useChainFromUrlParam from './useChainFromUrlParam'

const useChainIdFromUrlParam = ():
  | Exclude<ChainId, ChainId.MANTLE>
  | undefined =>
  useChainFromUrlParam()?.id as Exclude<ChainId, ChainId.MANTLE> | undefined

export default useChainIdFromUrlParam
