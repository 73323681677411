import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Button,
  HStack,
  InputGroup,
  InputRightElement,
  Text,
  VStack
} from '@chakra-ui/react'
import NumericalInput from 'components/NumericalInput'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import React, { useMemo, useState } from 'react'

interface SlippageInputRowProps {
  onSlippageChange: (slippage: string) => void
  placeholder: string
  predefinedValues: string[]
  slippage: string
  title: string
  tooltipLabel: string
  transactionFailThreshold: number
}

const SlippageInputRow = ({
  onSlippageChange,
  placeholder,
  predefinedValues,
  slippage,
  title,
  tooltipLabel,
  transactionFailThreshold
}: SlippageInputRowProps) => {
  const [inputValue, setInputValue] = useState<string>(slippage)

  const warning = useMemo(() => {
    const inputValueNumber = Number(inputValue)
    if (!inputValue) return undefined
    if (inputValueNumber < transactionFailThreshold) {
      return 'Your transaction may fail'
    }
    if (inputValueNumber >= 2 && inputValueNumber < 50) {
      return 'Your transaction may be frontrun'
    }
    return undefined
  }, [inputValue, transactionFailThreshold])

  const isInputInvalid = useMemo(() => {
    const inputValueNumber = Number(inputValue)
    if (isNaN(inputValueNumber)) return true
    return inputValueNumber <= 0 || inputValueNumber >= 50
  }, [inputValue])

  const formatInputValue = (value: string) => {
    const numberValue = parseFloat(value)
    if (isNaN(numberValue) || Number.isInteger(numberValue)) {
      return value
    }
    const decimalPlaces = value.split('.')[1]?.length || 0
    if (decimalPlaces > 2) {
      return numberValue.toFixed(2)
    }
    return value
  }

  const handleValueChange = (value: string) => {
    setInputValue(formatInputValue(value))
  }

  return (
    <VStack align="flex-start">
      <HStack justify="space-between" w="full">
        <Text color="textSecondary" fontSize="sm">
          {title}
        </Text>
        <TouchFriendlyTooltip label={tooltipLabel}>
          <InfoOutlineIcon color="textSecondary" />
        </TouchFriendlyTooltip>
      </HStack>
      <HStack>
        <HStack align="center" spacing={1}>
          {predefinedValues.map((val, i) => (
            <Button
              key={i}
              data-cy={`slippage-button-${val}`}
              variant="outline"
              isActive={val === inputValue}
              w="70px"
              onClick={() => {
                onSlippageChange(val.toString())
                setInputValue(val.toString())
              }}
            >{`${val}%`}</Button>
          ))}
        </HStack>
        <InputGroup>
          <NumericalInput
            inputType="decimal"
            placeholder={placeholder}
            value={inputValue}
            isInvalid={isInputInvalid}
            onValueChange={handleValueChange}
            onBlur={() => {
              if (isInputInvalid) {
                setInputValue(slippage)
              } else {
                onSlippageChange(inputValue)
              }
            }}
          />
          <InputRightElement>%</InputRightElement>
        </InputGroup>
      </HStack>
      {isInputInvalid ? (
        <Text fontSize="sm" color="red.500">
          Enter a valid slippage percentage
        </Text>
      ) : null}
      {warning ? (
        <Text fontSize="sm" color="yellow.500">
          {warning}
        </Text>
      ) : null}
    </VStack>
  )
}

export default SlippageInputRow
