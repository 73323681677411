import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Badge,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import {
  joeBridge,
  joeFarm,
  joeHome,
  joeLend,
  joeMemeRush,
  joePool,
  joeStake,
  joeTrade,
  SubmenuItem,
  tokenMill
} from 'constants/urls'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { HamburgerIcon } from 'theme/icons'

const MobileNavigationMenu = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const navigate = useNavigate()
  const submenuItems: SubmenuItem[] = [
    joeHome,
    joeTrade,
    joePool,
    joeStake,
    tokenMill,
    joeMemeRush,
    joeBridge,
    joeFarm,
    joeLend
  ]

  return (
    <>
      <Button
        aria-label="Click for links to Bridge, Farm, Lend and Stake on Trader Joe"
        variant="ghost"
        color="textPrimary"
        _hover={{ bg: 'bgTertiary' }}
        _active={{ bg: 'bgTertiary', color: 'textPrimary' }}
        onClick={onOpen}
      >
        <HamburgerIcon boxSize={6} fill="textPrimary" />
      </Button>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          borderTopLeftRadius={24}
          borderTopRightRadius={24}
          bg="bgPrimary"
        >
          <DrawerCloseButton />
          <DrawerBody>
            <Flex
              gap={4}
              w="full"
              align="center"
              flexDir="column"
              justifyContent="center"
              p={4}
            >
              {submenuItems.map((item, i) => (
                <Flex
                  key={i}
                  w="full"
                  h={10}
                  align="center"
                  justifyContent="center"
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (item.external) {
                      window.open(item.url, '_blank')
                    } else {
                      navigate(`/${item.url}`)
                      onClose()
                    }
                  }}
                >
                  <Flex w="full" gap={2} justifyContent="center" align="center">
                    <Text textAlign="center" fontWeight="semibold">
                      {item.name}
                    </Text>
                    {item.external && <ExternalLinkIcon />}
                    {item.isNew && (
                      <Badge
                        borderRadius="full"
                        colorScheme="accent"
                        fontSize="10px"
                      >
                        New
                      </Badge>
                    )}
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileNavigationMenu
