import {
  ChainId,
  Currency,
  CurrencyAmount,
  Token,
  TokenAmount,
  WNATIVE
} from '@traderjoe-xyz/sdk-core'
import { getAddress } from 'viem'

export function wrappedCurrency(currency?: Currency): Token | undefined {
  return currency && currency.isNative
    ? WNATIVE[currency.chainId as Exclude<ChainId, ChainId.MANTLE>]
    : currency instanceof Token
      ? currency
      : undefined
}

export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount | undefined
): TokenAmount | undefined {
  const token =
    currencyAmount && currencyAmount.currency.isNative
      ? WNATIVE[
          currencyAmount.currency.chainId as Exclude<ChainId, ChainId.MANTLE>
        ]
      : currencyAmount
        ? wrappedCurrency(currencyAmount.currency)
        : undefined
  return token && currencyAmount
    ? new TokenAmount(token, currencyAmount.raw)
    : undefined
}

export const getCurrencyAddress = (
  currency?: Currency
): `0x${string}` | undefined => {
  return currency && currency.isToken ? getAddress(currency.address) : undefined
}

export function isWrappedNativeCurrency(
  address?: string,
  chainId?: ChainId
): boolean {
  return chainId
    ? address?.toLowerCase() === WNATIVE[chainId].address.toLowerCase()
    : false
}

export const getIsNativeInAndWrappedNativeOut = (
  chainId: ChainId,
  currency0?: Currency,
  currency1?: Currency
): boolean => {
  return (
    !!currency0 &&
    !!currency1 &&
    currency0.isNative &&
    isWrappedNativeCurrency(getCurrencyAddress(currency1), chainId)
  )
}

export const getIsWrappedNativeInAndNativeOut = (
  chainId: ChainId,
  currency0?: Currency,
  currency1?: Currency
): boolean => {
  return (
    !!currency0 &&
    !!currency1 &&
    currency1.isNative &&
    isWrappedNativeCurrency(getCurrencyAddress(currency0), chainId)
  )
}
