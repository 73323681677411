import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import dayjs from 'dayjs'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { FeesPeriod } from 'pages/PoolDetailV2/UserEarnedFees'
import { UserFeesBinData, UserFeesIntervalData } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { roundToNearest5Minutes } from 'utils/date'

interface UseUserEarnedFeesProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  feesPeriod: FeesPeriod
  lbPairAddress?: string
  owner?: string
}

interface LastRefreshedTimestamp {
  timestamp: number
}

const useUserEarnedFees = ({
  chainId,
  feesPeriod,
  lbPairAddress,
  owner
}: UseUserEarnedFeesProps) => {
  const chain = getDexbarnChainParam(chainId)

  const now = roundToNearest5Minutes(dayjs())
  const yesterday = now.subtract(1, 'day')
  const week = now.subtract(7, 'day')
  const month = now.subtract(30, 'day')

  const urlParams =
    feesPeriod === FeesPeriod.DAY
      ? `startTime=${yesterday.unix()}&aggregateBy=hourly`
      : feesPeriod === FeesPeriod.WEEK
        ? `startTime=${week.unix()}&aggregateBy=daily`
        : `startTime=${month.unix()}&aggregateBy=daily`

  const fetchUserEarnedFeesAnalytics = useDexbarnGet<UserFeesIntervalData[]>(
    `/v1/user/fees/analytics/${chain}/${lbPairAddress?.toLowerCase()}/${owner?.toLowerCase()}?${urlParams}`,
    chainId
  )

  const { data: userFeesIntervalData, isFetching: isFetchingIntervalData } =
    useQuery<UserFeesIntervalData[]>({
      enabled: !!lbPairAddress && !!owner,
      placeholderData: keepPreviousData,
      queryFn: () => fetchUserEarnedFeesAnalytics(),
      queryKey: ['UserEarnedFees', chainId, owner, lbPairAddress, feesPeriod]
    })

  const fetchUserEarnedFeesPerBin = useDexbarnGet<UserFeesBinData[]>(
    `/v1/user/fees-earned/${chain}/${owner?.toLowerCase()}/${lbPairAddress?.toLowerCase()}`,
    chainId
  )

  const { data: userFeesDataBins, isFetching: isFetchinBins } = useQuery<
    UserFeesBinData[]
  >({
    enabled: !!lbPairAddress && !!owner,
    placeholderData: keepPreviousData,
    queryFn: () => fetchUserEarnedFeesPerBin(),
    queryKey: ['UserEarnedFeesPerBin', chainId, owner, lbPairAddress]
  })

  const fetchLastRefreshedTimestamp = useDexbarnGet<LastRefreshedTimestamp>(
    `v1/user/fees/last-refreshed/${chain}/${lbPairAddress?.toLowerCase()}`,
    chainId
  )

  const { data: lastRefreshedTimestamp, isFetching: isFetchingRefreshedTime } =
    useQuery<LastRefreshedTimestamp>({
      enabled: !!lbPairAddress,
      placeholderData: keepPreviousData,
      queryFn: () => fetchLastRefreshedTimestamp(),
      queryKey: ['UserEarnedFeesRefreshedTimestamp', chainId, lbPairAddress]
    })

  return {
    isFetchinBins,
    isFetchingIntervalData,
    isFetchingRefreshedTime,
    lastRefreshedTimestamp,
    userFeesDataBins: userFeesDataBins ?? [],
    userFeesIntervalData: userFeesIntervalData ?? []
  }
}

export default useUserEarnedFees
