import { Badge, Flex, Link } from '@chakra-ui/react'
import React from 'react'
import { NavLink } from 'react-router-dom'

interface HeaderLinkProps {
  title: string
  to: string
  isNew?: boolean
}

interface IconHeaderLinkProps {
  children: React.ReactNode
  to: string
}

export const HeaderLink = ({ title, to }: HeaderLinkProps) => {
  return (
    <Link
      as={NavLink}
      to={`/${to}`}
      fontWeight="semibold"
      color="textSecondary"
      textAlign="center"
      px={4}
      py={2}
      borderRadius="full"
      _hover={{ bg: 'bgTertiary', textDecor: 'none' }}
      _activeLink={{ color: 'textPrimary' }}
    >
      {title}
    </Link>
  )
}

export const ExternalHeaderLink = ({ isNew, title, to }: HeaderLinkProps) => {
  return (
    <Flex flexDir="column" align="center" position="relative">
      {isNew && (
        <Badge
          position="absolute"
          top="-2px"
          right="0px"
          borderRadius="full"
          colorScheme="accent"
          fontSize="10px"
        >
          New
        </Badge>
      )}
      <Link
        isExternal={true}
        href={to}
        fontWeight="semibold"
        color="textSecondary"
        textAlign="center"
        px={4}
        py={2}
        borderRadius="full"
        _hover={{ bg: 'bgTertiary', textDecor: 'none' }}
        _activeLink={{ color: 'textPrimary' }}
      >
        {title}
      </Link>
    </Flex>
  )
}

export const IconHeaderLink = ({ children, to }: IconHeaderLinkProps) => {
  return (
    <Link
      as={NavLink}
      to={`/${to}`}
      fontWeight="semibold"
      color="textSecondary"
      textAlign="center"
      px={4}
      py={2}
      borderRadius="full"
      _hover={{ bg: 'bgTertiary', textDecor: 'none' }}
      _activeLink={{ color: 'textPrimary' }}
    >
      <Flex alignItems="center" justifyContent="center">
        {children}
      </Flex>
    </Link>
  )
}
