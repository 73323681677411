import { ChainId } from '@traderjoe-xyz/sdk-core'
import { supportedChains } from 'constants/chains'
import { Chain } from 'types/dexbarn'

export const getChain = (chainId: ChainId) => {
  return supportedChains.find((chain) => chain.id === chainId)
}

export const getChainSlug = (chainId: ChainId): string | undefined => {
  return getChain(chainId)?.slug
}

export const getDexbarnChainParam = (
  chainId: Exclude<ChainId, ChainId.MANTLE>
): Chain => {
  switch (chainId) {
    case ChainId.AVALANCHE:
    case ChainId.FUJI:
      return 'avalanche'
    case ChainId.ARBITRUM_ONE:
    case ChainId.ARB_GOERLI:
      return 'arbitrum'
    case ChainId.BNB_CHAIN:
    case ChainId.BNB_TESTNET:
      return 'binance'
    case ChainId.ETHEREUM:
      return 'ethereum'
  }
}

export const getChainName = (
  chainId: Exclude<ChainId, ChainId.MANTLE>
): string => {
  switch (chainId) {
    case ChainId.BNB_CHAIN:
    case ChainId.BNB_TESTNET:
      return 'Binance'
    case ChainId.AVALANCHE:
    case ChainId.FUJI:
      return 'Avalanche'
    case ChainId.ETHEREUM:
      return 'Ethereum'
    case ChainId.ARBITRUM_ONE:
    case ChainId.ARB_GOERLI:
      return 'Arbitrum'
  }
}

export const getIsChainSunset = (chainId: ChainId) => {
  return chainId === ChainId.BNB_CHAIN || chainId === ChainId.BNB_TESTNET
}
