import { ChainId } from '@traderjoe-xyz/sdk-core'
import { getAddress } from 'viem'
import { useAccount, useBalance } from 'wagmi'

interface UseTokenBalanceProps {
  chainId: Exclude<ChainId, ChainId.MANTLE> | undefined
  enabled?: boolean
  token?: string
}

export const useTokenBalance = ({
  chainId,
  enabled,
  token
}: UseTokenBalanceProps) => {
  const { address: account } = useAccount()

  return useBalance({
    address: account,
    chainId,
    query: { enabled: enabled ? enabled : !!account },
    token: token ? getAddress(token) : undefined
  })
}
