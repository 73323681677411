import {
  Box,
  Center,
  Flex,
  Grid,
  HStack,
  IconButton,
  Tab,
  TabList,
  Tabs,
  Text
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { ChainId, CNATIVE, Currency, Token } from '@traderjoe-xyz/sdk-core'
import { LIMIT_ORDER_MANAGER_ADDRESS } from '@traderjoe-xyz/sdk-v2'
import AddToWalletButton from 'components/AddToWalletButton'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CopyableError from 'components/CopyableError'
import MaxButton from 'components/MaxButton'
import PageHelmet from 'components/PageHelmet'
import SlippageSettingsPicker from 'components/SlippageSettingsPicker'
import TradingViewChart from 'components/TradingViewChart'
import Web3Button from 'components/Web3Button'
import { TRADE_HELMET_DESCRIPTION, TRADE_HELMET_TITLE } from 'constants/swap'
import useGetTradeRoutes from 'hooks/swap/useGetTradeRoutes'
import { useSwap } from 'hooks/swap/useSwap'
import useWrapUnwrapNativeCurrency from 'hooks/swap/useWrapUnwrapNativeCurrency'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainIdFromUrlParam from 'hooks/useChainIdFromUrlParam'
import useGetTokensUsdPrice from 'hooks/useGetTokensUsdPrice'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useTokenBalance } from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useCallback } from 'react'
import { useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useIsSafeModeEnabled, useSlippageSettings } from 'state/settings/hooks'
import { CandlestickIcon } from 'theme/icons'
import { Route } from 'types/router'
import { getChainSlug } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { trackSwap } from 'utils/measure'
import { getSpenderForAggregator, tryParseAmount } from 'utils/swap'
import {
  getCurrencyAddress,
  wrappedCurrency,
  wrappedCurrencyAmount
} from 'utils/wrappedCurrency'
import { zeroAddress } from 'viem'
import { useAccount } from 'wagmi'

import Erc404TradeCompleted from './Erc404TradeCompleted'
import PlaceOrder from './LimitOrder/PlaceOrder'
import TradeCurrencyInputs from './TradeCurrencyInputs'
import TradeCurrencyShortcuts from './TradeCurrencyShortcuts'
import TradeDetails from './TradeDetails'
import TradeRefetchView from './TradeRefetchView'

const Trade = () => {
  const { isConnected } = useAccount()
  const navigate = useNavigate()

  // chain state
  const chainIdFromUrlParams = useChainIdFromUrlParam()
  const chainId = chainIdFromUrlParams || ChainId.AVALANCHE

  // ui state
  const [isChartVisible, setIsChartVisible] = useState(false)
  const [isSwap, setIsSwap] = useState<boolean>(true)

  // TODO: use getIsPlaceOrderEnabled when BNB completely sunset
  const isPlaceOrdersEnabled =
    LIMIT_ORDER_MANAGER_ADDRESS[chainId] !== zeroAddress

  // Decode search params
  const [searchParams, setSearchParams] = useSearchParams()
  const inputCurrencyAddr = searchParams.get('inputCurrency')
  const outputCurrencyAddr = searchParams.get('outputCurrency')
  const paramAddresses = useMemo(
    () => [inputCurrencyAddr, outputCurrencyAddr],
    [inputCurrencyAddr, outputCurrencyAddr]
  )
  const {
    tokens: [inputCurrencyParam, outputCurrencyParam]
  } = useSdkCurrencies({
    addresses: paramAddresses,
    chainId
  })

  // Input/output currencies
  const [selectedInputCurrency, setInputCurrency] = useState<
    Currency | undefined
  >(
    !inputCurrencyParam && !outputCurrencyParam
      ? CNATIVE.onChain(chainId)
      : undefined
  )
  const [selectedOutputCurrency, setOutputCurrency] = useState<
    Currency | undefined
  >()

  const inputCurrency = inputCurrencyParam || selectedInputCurrency
  const outputCurrency = outputCurrencyParam || selectedOutputCurrency

  const handleSetInputCurrency = (currency: Currency | undefined) => {
    const newChainId = currency?.chainId as
      | Exclude<ChainId, ChainId.MANTLE>
      | undefined

    setInputCurrency(currency)

    setSearchParams((params) => {
      if (currency?.isToken) {
        params.set('inputCurrency', currency.address)
      } else if (currency?.isNative) {
        params.set('inputCurrency', currency?.symbol || '')
      } else {
        params.delete('inputCurrency')
      }
      return params
    })

    if (newChainId) {
      if (outputCurrency?.chainId !== newChainId) {
        setOutputCurrency(undefined)
        setSearchParams((params) => {
          params.delete('outputCurrency')
          return params
        })
      }

      navigate(`/${getChainSlug(newChainId)}/trade${location.search}`)
    }
  }

  const handleSetOutputCurrency = (currency: Currency | undefined) => {
    const newChainId = currency?.chainId as
      | Exclude<ChainId, ChainId.MANTLE>
      | undefined

    setOutputCurrency(currency)

    setSearchParams((params) => {
      if (currency?.isToken) {
        params.set('outputCurrency', currency.address)
      } else if (currency?.isNative) {
        params.set('outputCurrency', currency?.symbol || '')
      } else {
        params.delete('outputCurrency')
      }
      return params
    })

    if (newChainId) {
      if (!currency?.isNative) {
        setInputCurrency(CNATIVE.onChain(newChainId))
      } else {
        setInputCurrency(undefined)
      }

      if (inputCurrency?.chainId !== newChainId) {
        setSearchParams((params) => {
          params.delete('inputCurrency')
          return params
        })
      }

      navigate(`/${getChainSlug(newChainId)}/trade${location.search}`)
    }
  }

  // Addresses
  const inputCurrencyAddress = getCurrencyAddress(inputCurrency)
  const outputCurrencyAddress = getCurrencyAddress(outputCurrency)

  // User balances
  const inputBalance = useTokenBalance({
    chainId,
    token: inputCurrencyAddress
  })
  const debouncedRefetchInputBalance = debounce(
    () => inputBalance.refetch(),
    2000
  )
  const outputBalance = useTokenBalance({
    chainId,
    token:
      outputCurrency instanceof Token
        ? (outputCurrency as Token).address
        : undefined
  })
  const debouncedRefetchOutputBalance = debounce(
    () => outputBalance.refetch(),
    2000
  )

  // User input
  const [typedValue, setTypedValue] = useState('')
  const typedTokenAmount = useMemo(
    () => wrappedCurrencyAmount(tryParseAmount(typedValue, inputCurrency)),
    [typedValue, inputCurrency]
  )

  // Get token USD prices
  const inputWrappedCurrencyAddress = wrappedCurrency(inputCurrency)?.address
  const outputWrappedCurrencyAddress = wrappedCurrency(outputCurrency)?.address
  const { data: tokenUsdPrices } = useGetTokensUsdPrice({
    chainId,
    tokenAddresses:
      inputWrappedCurrencyAddress && outputWrappedCurrencyAddress
        ? [inputWrappedCurrencyAddress, outputWrappedCurrencyAddress]
        : []
  })
  const inputCurrencyUsdPrice =
    tokenUsdPrices?.[inputWrappedCurrencyAddress?.toLowerCase() || '']
  const outputCurrencyUsdPrice =
    tokenUsdPrices?.[outputWrappedCurrencyAddress?.toLowerCase() || '']

  // Fetch trades
  const refetchInterval = 30000 // 30 seconds
  const [isRefreshQuoteEnabled, setIsRefreshQuoteEnabled] = useState(true)
  const {
    data: quotes = [],
    isFetching: isFetchingQuotes,
    lastFetchTime: quoteLastFetchTime,
    refetch: refetchQuotes
  } = useGetTradeRoutes({
    chainId,
    inputCurrency,
    outputCurrency,
    refetchInterval: isRefreshQuoteEnabled ? refetchInterval : 0,
    typedTokenAmount
  })

  const [userSelectedTradeRoute, setUserSelectedTradeRoute] = useState<
    Route | undefined
  >()

  const selectedTradeRoute =
    userSelectedTradeRoute ?? (quotes.length > 0 ? quotes[0] : undefined)

  useEffect(() => {
    setUserSelectedTradeRoute(undefined)
  }, [quotes])

  const isLiquidityInsufficient =
    !isFetchingQuotes && !selectedTradeRoute && !!typedValue

  // Get amounts usd prices
  const inputAmountUsdPrice =
    selectedTradeRoute && inputCurrencyUsdPrice
      ? Number(selectedTradeRoute.amountIn.formatted) * inputCurrencyUsdPrice
      : undefined
  const outputAmountUsdPrice =
    selectedTradeRoute && outputCurrencyUsdPrice
      ? Number(selectedTradeRoute.amountOut.formatted) * outputCurrencyUsdPrice
      : undefined
  const priceImpactInUsd =
    inputAmountUsdPrice && outputAmountUsdPrice
      ? ((outputAmountUsdPrice - inputAmountUsdPrice) / inputAmountUsdPrice) *
        100
      : undefined

  // Slippage
  const { slippageSettings } = useSlippageSettings()
  const slippageBps = Math.trunc(slippageSettings.swap * 100)

  // Price impact
  const { isSafeModeEnabled } = useIsSafeModeEnabled()
  const priceImpact = priceImpactInUsd
    ? priceImpactInUsd >= 0
      ? 0
      : Math.abs(priceImpactInUsd)
    : undefined
  const isPriceImpactHigh = priceImpact ? priceImpact >= 5 : false

  // Input error
  const hasEnoughInputCurrency =
    inputBalance.data && selectedTradeRoute
      ? Number(inputBalance.data.formatted) >=
        Number(selectedTradeRoute.amountIn.formatted)
      : true

  // Approval
  const spender = selectedTradeRoute?.aggregator
    ? getSpenderForAggregator(selectedTradeRoute.aggregator, chainId)
    : undefined
  const {
    approvalType,
    approveAsync,
    isApproved,
    isApproving,
    isLoadingAllowance,
    reset: resetApproval,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: selectedTradeRoute?.amountIn.value,
    chainId,
    spender,
    token: inputCurrencyAddress,
    tokenSymbol: inputCurrency?.symbol
  })

  // Approve click handler
  const onApproveClick = approveAsync
    ? async () => {
        setIsRefreshQuoteEnabled(false)
        try {
          await approveAsync()
        } finally {
          setIsRefreshQuoteEnabled(true)
        }
      }
    : undefined

  // Refresh state on swap success
  const onSwapSuccess = useCallback(() => {
    resetApproval()
    setTypedValue('')
    setIsRefreshQuoteEnabled(true)
    debouncedRefetchInputBalance()
    debouncedRefetchOutputBalance()
  }, [
    debouncedRefetchInputBalance,
    debouncedRefetchOutputBalance,
    resetApproval
  ])

  // Reset approval on currency change
  useEffect(() => {
    resetApproval()
  }, [inputCurrency, resetApproval])

  // Swap
  const isSwapEnabled =
    hasEnoughInputCurrency &&
    !isFetchingQuotes &&
    (isApproved || (inputCurrency?.isNative ?? false))
  const {
    error: swapError,
    forceSwapAsync,
    isSimulating: isSimulatingSwap,
    isSwapping,
    receipt: swapReceipt,
    resetSwap,
    swapAsync
  } = useSwap({
    chainId,
    currencyIn: inputCurrency,
    currencyOut: outputCurrency,
    enabled: isSwapEnabled,
    isExactIn: true,
    onSwapSuccess,
    route: selectedTradeRoute,
    slippageBps
  })

  // Swap click handler
  const onSwapClick = async () => {
    setIsRefreshQuoteEnabled(false)
    try {
      await (forceSwapAsync ?? swapAsync)?.()
      trackSwap(
        inputCurrency?.symbol,
        outputCurrency?.symbol,
        selectedTradeRoute?.aggregator,
        inputAmountUsdPrice
      )
    } catch {
      setIsRefreshQuoteEnabled(true)
    }
  }

  // Wrap / Unwrap
  const {
    isLoading: isLoadingWrapUnwrap,
    state: wrapUnwrapState,
    write: wrapUnwrap
  } = useWrapUnwrapNativeCurrency({
    amount: typedTokenAmount?.toExact(),
    chainId,
    currency0: inputCurrency,
    currency1: outputCurrency,
    onSuccess: onSwapSuccess
  })

  // Action handlers
  const onChangeSwapDirectionClick = () => {
    const newInputCurrency = outputCurrency
    const newOutputCurrency = inputCurrency
    handleSetInputCurrency(newInputCurrency)
    handleSetOutputCurrency(newOutputCurrency)
  }

  return (
    <Flex
      mt={{ base: 6, md: 10 }}
      mb="200px"
      justify="center"
      minH="calc(100vh - 400px)"
    >
      <PageHelmet
        title={TRADE_HELMET_TITLE}
        description={TRADE_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <Grid
        gap={{ base: 0, lg: 8 }}
        w="full"
        maxW={isChartVisible ? '1400px' : '550px'}
        templateColumns={
          isChartVisible
            ? {
                base: 'minmax(0, 1fr)',
                lg: 'minmax(0, 7fr) minmax(0, 5fr)'
              }
            : '1fr'
        }
      >
        {isChartVisible ? (
          <Box
            order={{ base: 1, md: 0 }}
            mt={{ base: 4, md: 0 }}
            px={{ base: 4, md: 0 }}
          >
            <Box
              w="full"
              border="1px solid"
              borderColor="border"
              borderRadius={{ base: 'md', md: '2xl' }}
              overflow="hidden"
              bg="white"
              _dark={{ bg: '#151822' }}
              p={1}
            >
              {inputCurrency && outputCurrency ? (
                <TradingViewChart
                  inputCurrency={inputCurrency}
                  outputCurrency={outputCurrency}
                  chainId={chainId}
                />
              ) : (
                <Center h={{ base: '400px', md: '600px' }}>
                  <Text>Select a token pair to view the chart</Text>
                </Center>
              )}
            </Box>
          </Box>
        ) : null}
        <Flex width="full" flexDir="column" px={{ base: 4, xl: 0 }} gap={4}>
          <Flex
            flexDir="row"
            justifyContent={isPlaceOrdersEnabled ? 'space-between' : 'flex-end'}
            align="center"
            overflow={{ base: 'auto', md: 'initial' }}
            gap={2}
          >
            {isPlaceOrdersEnabled ? (
              <Tabs
                variant="solid"
                index={isSwap ? 0 : 1}
                onChange={(index) => setIsSwap(index === 0)}
                flexShrink={0}
              >
                <TabList>
                  <Tab data-cy="swap-tab">
                    <Trans>Swap</Trans>
                  </Tab>
                  <Tab data-cy="place-order-tab">
                    <Trans>Place Order</Trans>
                  </Tab>
                </TabList>
              </Tabs>
            ) : null}
            <HStack spacing={1}>
              {!!inputCurrency && !!outputCurrency ? (
                <IconButton
                  data-cy="toggle-chart-button"
                  aria-label="Toggle chart"
                  variant="outline"
                  size="lg"
                  boxSize="48px"
                  borderRadius="full"
                  icon={<CandlestickIcon w="24px" h="24px" />}
                  onClick={() => setIsChartVisible(!isChartVisible)}
                />
              ) : null}
              {!!selectedTradeRoute ? (
                <TradeRefetchView
                  isRefreshQuoteEnabled={isRefreshQuoteEnabled}
                  lastFetchTime={quoteLastFetchTime}
                  refetchInterval={refetchInterval}
                  onRefetchClick={refetchQuotes}
                />
              ) : null}
              <SlippageSettingsPicker
                chainId={chainId}
                type={isSwap ? 'swap' : 'poolV2'}
                iconButtonProps={{
                  'aria-label': 'open settings',
                  bg: 'transparent',
                  borderRadius: 'full',
                  flexShrink: 0
                }}
              />
            </HStack>
          </Flex>

          {isSwap ? (
            <TradeCurrencyInputs
              inputCurrencyProps={{
                amountUsd: inputAmountUsdPrice
                  ? '~' +
                    formattedNum(inputAmountUsdPrice, {
                      allowDecimalsOver1000: true,
                      places: 2,
                      usd: true
                    })
                  : undefined,
                balance: inputBalance.data?.formatted,
                chainId,
                currency: inputCurrency,
                currencyAddress: inputCurrencyAddress,
                heading: t`From`,
                isChainPickable: true,
                onCurrencyChange: (currency) => {
                  const cAddress = currency.isToken
                    ? currency.address
                    : undefined

                  const outputCurrencyAddress = outputCurrency?.isToken
                    ? outputCurrency.address
                    : undefined

                  currency.symbol === outputCurrency?.symbol &&
                  cAddress === outputCurrencyAddress &&
                  currency.chainId === chainId
                    ? onChangeSwapDirectionClick()
                    : handleSetInputCurrency(currency)
                },
                onValueChange: (value) => {
                  setTypedValue(value)
                },
                rightElement: inputCurrency ? (
                  <MaxButton
                    data-cy="trade-currency-input-max-button"
                    borderRadius="full"
                    balance={inputBalance.data?.formatted}
                    onClick={() => {
                      setTypedValue(inputBalance.data?.formatted ?? '')
                    }}
                  />
                ) : (
                  <TradeCurrencyShortcuts
                    otherSelectedCurrency={outputCurrency}
                    onCurrencyClick={handleSetInputCurrency}
                    chainId={chainId}
                  />
                ),
                value: typedValue,
                wrapRightElementIfNeeded: !inputCurrency
              }}
              outputCurrencyProps={{
                amountUsd:
                  outputAmountUsdPrice && priceImpactInUsd
                    ? `~${formattedNum(outputAmountUsdPrice, {
                        allowDecimalsOver1000: true,
                        places: 2,
                        usd: true
                      })} (${priceImpactInUsd.toFixed(2)}%)`
                    : undefined,
                balance: outputBalance.data?.formatted,
                chainId,
                currency: outputCurrency,
                currencyAddress: outputCurrencyAddress,
                heading: t`To`,
                isBalanceButtonDisabled: true,
                isChainPickable: true,
                onCurrencyChange: (currency) => {
                  const cAddress = currency.isToken
                    ? currency.address
                    : undefined
                  const inputCurrencyAddress = inputCurrency?.isToken
                    ? inputCurrency.address
                    : undefined

                  currency.symbol === inputCurrency?.symbol &&
                  inputCurrencyAddress === cAddress &&
                  currency.chainId === chainId
                    ? onChangeSwapDirectionClick()
                    : handleSetOutputCurrency(currency)
                },
                onValueChange: (value) => {
                  setTypedValue(value)
                },
                rightElement: !outputCurrency ? (
                  <TradeCurrencyShortcuts
                    chainId={chainId}
                    otherSelectedCurrency={inputCurrency}
                    onCurrencyClick={handleSetOutputCurrency}
                  />
                ) : undefined,
                value: wrapUnwrapState
                  ? typedValue
                  : selectedTradeRoute?.amountOut.formatted ?? '',
                wrapRightElementIfNeeded: !outputCurrency
              }}
              onChangeSwapDirectionClick={onChangeSwapDirectionClick}
              isChangeSwapDirectionDisabled={isFetchingQuotes || isSwapping}
              bottomContent={
                <Box w="full" pt={selectedTradeRoute ? 4 : 0}>
                  {selectedTradeRoute ? (
                    <TradeDetails
                      trades={quotes}
                      chainId={chainId}
                      selectedTrade={selectedTradeRoute}
                      slippageBps={slippageBps}
                      onTradeSelected={(trade) => {
                        resetSwap?.()
                        resetApproval()
                        setUserSelectedTradeRoute(trade)
                      }}
                      outputCurrencyUsdPrice={outputCurrencyUsdPrice}
                      priceImpact={priceImpact}
                    />
                  ) : null}
                  <Flex flexDir="column" pt={4} gap={4}>
                    {onApproveClick &&
                    !isApproved &&
                    hasEnoughInputCurrency &&
                    !isFetchingQuotes ? (
                      <ApproveTokenButton
                        data-cy="approve-button"
                        amount={selectedTradeRoute?.amountIn.formatted}
                        currencySymbol={inputCurrency?.symbol}
                        approvalType={approvalType}
                        onApprovalTypeSelect={setApprovalType}
                        isLoading={isApproving}
                        onClick={onApproveClick}
                      />
                    ) : null}
                    {wrapUnwrapState && !!wrapUnwrap ? (
                      <Web3Button
                        data-cy="wrap-unwrap-button"
                        variant="primary"
                        colorScheme="accent"
                        w="full"
                        size="xl"
                        isLoading={isLoadingWrapUnwrap}
                        loadingText={wrapUnwrapState}
                        onClick={() => wrapUnwrap?.()}
                        chainId={chainId}
                      >
                        {wrapUnwrapState}
                      </Web3Button>
                    ) : (
                      <Web3Button
                        chainId={chainId}
                        data-cy="swap-button"
                        variant="primary"
                        colorScheme={
                          (isPriceImpactHigh && isConnected) || swapError
                            ? 'red'
                            : 'accent'
                        }
                        w="full"
                        size="xl"
                        isLoading={
                          isFetchingQuotes ||
                          isSwapping ||
                          isSimulatingSwap ||
                          isLoadingAllowance
                        }
                        loadingText={
                          isFetchingQuotes
                            ? t`Fetching best trade`
                            : isSimulatingSwap
                              ? t`Fetching swap data`
                              : isLoadingAllowance
                                ? t`Fetching allowance`
                                : t`Waiting for confirmation`
                        }
                        isDisabled={
                          !typedTokenAmount ||
                          !isSwapEnabled ||
                          isLiquidityInsufficient ||
                          isSimulatingSwap ||
                          (isPriceImpactHigh && isSafeModeEnabled) ||
                          (!swapAsync && !forceSwapAsync)
                        }
                        onClick={onSwapClick}
                      >
                        {isLiquidityInsufficient ? (
                          <Trans>Insufficient liquidity for this trade</Trans>
                        ) : hasEnoughInputCurrency ? (
                          isPriceImpactHigh ? (
                            isSafeModeEnabled ? (
                              <Trans>Safe Mode Activated</Trans>
                            ) : (
                              <Trans>Swap Anyway</Trans>
                            )
                          ) : swapError ? (
                            <Trans>Swap Anyway</Trans>
                          ) : (
                            <Trans>Swap</Trans>
                          )
                        ) : (
                          <Trans>Not enough {inputCurrency?.symbol}</Trans>
                        )}
                      </Web3Button>
                    )}
                    {swapError ? (
                      <CopyableError
                        textProps={{ mt: -2 }}
                        error={swapError.message}
                        summary={swapError.summary || ''}
                      />
                    ) : null}

                    {isPriceImpactHigh && isSafeModeEnabled ? (
                      <Text
                        fontSize="sm"
                        textColor="textSecondary"
                        textAlign="center"
                        mt={-2}
                      >
                        <Trans>
                          Safe mode prevents high price impact trade. It can be
                          accessed in Settings.
                        </Trans>
                      </Text>
                    ) : null}
                  </Flex>
                </Box>
              }
            />
          ) : (
            <PlaceOrder
              chainId={chainId}
              inputCurrency={undefined}
              outputCurrency={undefined}
              handleSetCurrency={() => {
                // disabled for now
              }}
              onChangeSwapDirectionClick={onChangeSwapDirectionClick}
            />
          )}

          {outputCurrency && outputCurrency.isToken && isSwap ? (
            <Center w="full">
              <AddToWalletButton token={outputCurrency} />
            </Center>
          ) : null}

          {isSwap && swapReceipt ? (
            <Erc404TradeCompleted
              chainId={chainId}
              receipt={swapReceipt}
              onDismiss={resetSwap}
            />
          ) : null}
        </Flex>
      </Grid>
    </Flex>
  )
}

export default Trade
