import { VStack } from '@chakra-ui/react'
import { ChainId, Currency } from '@traderjoe-xyz/sdk-core'
import CopyableError from 'components/CopyableError'
import CurrencyInput from 'components/CurrencyInput'
import MaxButton from 'components/MaxButton'
import Web3Button from 'components/Web3Button'
import useFarmWithdraw from 'hooks/farm/useFarmWithdraw'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import React from 'react'
import { getCurrencyAddress } from 'utils/wrappedCurrency'
import { BaseError } from 'viem'

interface PoolRewardsWithdrawProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  farmAddress: string
  poolId: string
  balance?: string
  currency?: Currency
  refetchFarmBalances?: () => void
}

const PoolRewardsWithdraw = ({
  balance,
  chainId,
  currency,
  farmAddress,
  poolId,
  refetchFarmBalances
}: PoolRewardsWithdrawProps) => {
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: currency
  })

  const tokenAddress = getCurrencyAddress(currency)
  const isExceedingBalance = balance ? Number(amount) > Number(balance) : false

  const {
    error,
    isLoading: isWithdrawing,
    withdraw
  } = useFarmWithdraw({
    chainId,
    currency,
    enabled: Boolean(amount),
    farmAddress,
    onSuccess: () => {
      setAmount('')
      refetchFarmBalances?.()
    },
    poolId,
    tokenAmount: amountBN
  })

  return (
    <VStack spacing={{ base: 4, md: 6 }}>
      <CurrencyInput
        chainId={chainId}
        currency={currency}
        currencyAddress={tokenAddress}
        value={amount}
        onValueChange={setAmount}
        balance={balance}
        error={
          isExceedingBalance ? `Not enough ${currency?.symbol}` : undefined
        }
        rightElement={
          balance ? (
            <MaxButton
              borderRadius={12}
              balance={balance}
              onClick={() => setAmount(balance)}
            />
          ) : undefined
        }
      />

      <VStack w="full">
        <Web3Button
          data-cy="launch-deposit-button"
          colorScheme="accent"
          variant="primary"
          size="xl"
          w="full"
          isDisabled={isExceedingBalance || !amount}
          isLoading={isWithdrawing}
          loadingText="Withdrawing Tokens"
          onClick={() => withdraw?.()}
        >
          Withdraw
        </Web3Button>

        {error ? (
          <CopyableError
            error={error.message}
            summary={(error as BaseError).shortMessage}
          />
        ) : null}
      </VStack>
    </VStack>
  )
}

export default PoolRewardsWithdraw
