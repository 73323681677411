import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { ChainId, Token } from '@traderjoe-xyz/sdk-core'
import {
  LIMIT_ORDER_MANAGER_ADDRESS,
  LimitOrderManagerABI
} from '@traderjoe-xyz/sdk-v2'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { OrderType } from 'types/limitOrder'
import { getAddress, zeroAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UsePlaceLimitOrderProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  isApproved: boolean
  amount?: bigint
  binId?: number
  binStep?: number
  isDisabled?: boolean
  isInputNative?: boolean
  onPlaceOrderSuccess?: () => void
  orderType?: OrderType
  tokenX?: Token
  tokenY?: Token
}

const usePlaceLimitOrder = ({
  amount,
  binId,
  binStep,
  chainId,
  isApproved,
  isDisabled,
  isInputNative,
  onPlaceOrderSuccess,
  orderType,
  tokenX,
  tokenY
}: UsePlaceLimitOrderProps) => {
  const walletChainId = useAccount().chain?.id

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // prepare 'placeOrder'contract write
  const args =
    tokenX?.address &&
    tokenY?.address &&
    binStep &&
    binId &&
    amount &&
    orderType !== undefined &&
    isApproved
      ? ([
          isInputNative && orderType === OrderType.ASK
            ? zeroAddress
            : getAddress(tokenX.address),
          isInputNative && orderType === OrderType.BID
            ? zeroAddress
            : getAddress(tokenY.address),
          binStep,
          orderType,
          binId,
          amount
        ] as const)
      : undefined

  const { data: config } = useSimulateContract({
    abi: LimitOrderManagerABI,
    address: getAddress(LIMIT_ORDER_MANAGER_ADDRESS[chainId]),
    args,
    chainId,
    functionName: 'placeOrder',
    query: {
      enabled: !!args && chainId === walletChainId && !isDisabled,
      gcTime: 0
    },

    value: isInputNative && amount ? amount : BigInt(0)
  })

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Place limit order`
        addRecentTransaction({ description, hash })
        addTransactionToast({ description, hash })
      }
    }
  })

  const placeOrder = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction } = useWaitForTransactionReceipt({
    chainId,
    hash: data,
    onTransactionSuccess: onPlaceOrderSuccess
  })

  return { isLoading: isPending || isWaitingForTransaction, placeOrder }
}

export default usePlaceLimitOrder
