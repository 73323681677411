import { ChainId } from '@traderjoe-xyz/sdk-core'
import { BinReserves, LBPairABI } from '@traderjoe-xyz/sdk-v2'
import { getAddress } from 'viem'
import { useReadContracts } from 'wagmi'

interface UseLBPairBinsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  binIds?: number[]
  enabled?: boolean
  lbPairAddress?: string
}

const useLBPairBins = ({
  binIds,
  chainId,
  enabled = true,
  lbPairAddress
}: UseLBPairBinsProps) => {
  const reads = useReadContracts({
    contracts: binIds?.map((binId) => ({
      abi: LBPairABI,
      address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
      args: [binId],
      chainId,
      functionName: 'getBin'
    })),
    query: { enabled: !!binIds && binIds.length > 0 && enabled }
  })
  return {
    ...reads,
    data: reads.data
      ? reads.data.map((read) => {
          const result = read.result as unknown[]
          return {
            reserveX: result?.[0],
            reserveY: result?.[1]
          } as BinReserves
        })
      : undefined
  }
}

export default useLBPairBins
