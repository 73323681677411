import { ROUTER_ADDRESS, RouterABI } from '@traderjoe-xyz/sdk'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import useGetTransactionDeadline from 'hooks/useGetTransactionDeadline'
import { useMemo } from 'react'
import { getAddress } from 'viem'
import { useAccount, useSimulateContract } from 'wagmi'

type AddLiquidityNativeArgs =
  | [`0x${string}`, bigint, bigint, bigint, `0x${string}`, bigint]
  | undefined

interface UseAddLiquidityNativeConfigProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  amountADesired?: bigint
  amountAMin?: bigint
  amountBDesired?: bigint
  amountBMin?: bigint
  enabled?: boolean
  tokenA?: `0x${string}`
  tokenB?: `0x${string}`
}

const useAddLiquidityNativeConfig = ({
  amountADesired,
  amountAMin,
  amountBDesired,
  amountBMin,
  chainId,
  enabled,
  tokenA,
  tokenB
}: UseAddLiquidityNativeConfigProps) => {
  const { address: account } = useAccount()
  const getTransactionDeadline = useGetTransactionDeadline()

  const addLiquidityNativeArgs = useMemo((): AddLiquidityNativeArgs => {
    const deadline = getTransactionDeadline()
    if (
      !account ||
      !deadline ||
      !amountADesired ||
      !amountBDesired ||
      !amountAMin ||
      !amountBMin
    ) {
      return undefined
    }
    if (!tokenA && tokenB) {
      return [tokenB, amountBDesired, amountBMin, amountAMin, account, deadline]
    }
    if (!tokenB && tokenA) {
      return [tokenA, amountADesired, amountAMin, amountBMin, account, deadline]
    }
    return undefined
  }, [
    account,
    getTransactionDeadline,
    tokenA,
    tokenB,
    amountADesired,
    amountBDesired,
    amountAMin,
    amountBMin
  ])

  return useSimulateContract({
    abi: RouterABI,
    address: getAddress(ROUTER_ADDRESS[chainId]),
    args: addLiquidityNativeArgs,
    functionName: 'addLiquidityAVAX',
    query: { enabled: enabled && !!addLiquidityNativeArgs, gcTime: 0 },
    value:
      !tokenA && amountADesired
        ? amountADesired
        : !tokenB && amountBDesired
          ? amountBDesired
          : BigInt(0)
  })
}

export default useAddLiquidityNativeConfig
