import { HStack, Tag } from '@chakra-ui/react'
import { ChainId, CNATIVE } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { Route } from 'types/router'
import { traverseRoute } from 'utils/router'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

interface RouteDetailsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  route: Route
}

const RouteDetails = ({ chainId, route }: RouteDetailsProps) => {
  const nativeCurrency = CNATIVE.onChain(chainId)

  const path = traverseRoute(route.tokenIn)
  const nbPools = path.reduce((total, swaps) => total + swaps.length, 0)
  const nbRoutes = route.tokenIn.swaps?.length || 0

  const tokens: { address: string | undefined; symbol: string | undefined }[] =
    [
      {
        address: isWrappedNativeCurrency(route.tokenIn.address, chainId)
          ? undefined
          : route.tokenIn.address,
        symbol: isWrappedNativeCurrency(route.tokenIn.address, chainId)
          ? nativeCurrency.symbol
          : route.tokenIn.symbol
      }
    ]

  path.forEach((swaps) => {
    swaps.forEach((swap) => {
      // Add tokenOut from each swap if not already in tokens array
      const tokenOut = {
        address: isWrappedNativeCurrency(swap.tokenOut.address, chainId)
          ? undefined
          : swap.tokenOut.address,
        symbol: isWrappedNativeCurrency(swap.tokenOut.address, chainId)
          ? nativeCurrency.symbol
          : swap.tokenOut.symbol
      }

      if (!tokens.find((t) => t.address === tokenOut.address)) {
        tokens.push(tokenOut)
      }
    })
  })

  return (
    <HStack>
      <HStack spacing={0}>
        {tokens.map((token, index) => (
          <CurrencyLogo
            key={index}
            address={token.address}
            symbol={token.symbol}
            boxSize={5}
            ml={index > 0 ? -2 : 0}
          />
        ))}
      </HStack>

      <Tag size="sm" rounded="full" fontWeight="semibold" colorScheme="accent">
        {nbRoutes} route{nbRoutes > 1 ? 's' : ''} + {nbPools} pair
        {nbPools > 1 ? 's' : ''}
      </Tag>
    </HStack>
  )
}

export default RouteDetails
