import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import ChainTabs from 'components/ChainTabs'
import ActivityList from 'components/WalletMenu/ActivityScreen/ActivityList'
import ConnnectedAccount from 'components/WalletMenu/ConnectedScreen/ConnectedAccount'
import WalletBalances from 'components/WalletMenu/ConnectedScreen/WalletBalances'
import SettingsScreen from 'components/WalletMenu/SettingScreen'
import React, { useEffect, useState } from 'react'
import { WalletIcon } from 'theme/icons'

interface MobileAccountMenuProps {
  walletAddress: string
}

const MobileAccountMenu = ({ walletAddress }: MobileAccountMenuProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [walletMenuState, setWalletMenuState] = useState<
    'default' | 'settings'
  >('default')

  const [chainId, setChainId] = useState<Exclude<ChainId, ChainId.MANTLE>>(
    ChainId.AVALANCHE
  )

  const renderWalletMenu = () => {
    switch (walletMenuState) {
      case 'default':
        return (
          <>
            <ConnnectedAccount
              chainId={chainId}
              walletAddress={walletAddress}
              stackProps={{ py: 4 }}
              onSettingsClick={() => setWalletMenuState('settings')}
            />

            <Box>
              <ChainTabs selectedChainId={chainId} setChainId={setChainId} />
            </Box>

            <Divider my={6} />
            <Tabs variant="soft-rounded" size="lg" isFitted>
              <TabList>
                <Tab w="full">
                  <Trans>Wallet</Trans>
                </Tab>
                <Tab>
                  <Trans>Activity</Trans>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel px={0}>
                  <WalletBalances
                    isVisible={isOpen}
                    onTokenSelected={onClose}
                    chainId={chainId}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <ActivityList
                    walletAddress={walletAddress}
                    chainId={chainId}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )
      case 'settings':
        return (
          <SettingsScreen
            headerStackProps={{ pb: 2 }}
            onBackClick={() => setWalletMenuState('default')}
            boxProps={{ p: 0 }}
          />
        )
    }
  }

  useEffect(() => {
    if (isOpen) {
      setWalletMenuState('default')
    }
  }, [isOpen])

  return (
    <>
      <Button w="full" bg="transparent" onClick={onOpen}>
        <Flex alignItems="center" pos="relative">
          <WalletIcon fill="textPrimary" w="21px" h="18px" />
          <Box
            top={-1}
            boxSize={2}
            right={-3}
            position="absolute"
            borderRadius="full"
            bgColor="green.300"
          />
        </Flex>
      </Button>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          pb={4}
          borderTopLeftRadius={24}
          borderTopRightRadius={24}
          bg="bgPrimary"
        >
          <DrawerCloseButton />
          <DrawerBody>{renderWalletMenu()}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileAccountMenu
