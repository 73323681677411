import { ChainId } from '@traderjoe-xyz/sdk-core'
import { erc20Abi } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

interface UseBalancesProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  erc20Tokens: `0x${string}`[]
  enabled?: boolean
}

const useBalances = ({
  chainId,
  enabled = true,
  erc20Tokens
}: UseBalancesProps) => {
  const { address: account } = useAccount()

  const calls = erc20Tokens.map((token) => {
    return {
      abi: erc20Abi,
      address: token,
      args: account ? [account] : undefined,
      chainId,
      functionName: 'balanceOf'
    } as const
  })

  return useReadContracts({
    allowFailure: true,
    contracts: calls,
    query: {
      enabled: !!account && enabled,
      select: (data) => {
        return data
          ?.filter((read) => read.status === 'success')
          .map((read) => read?.result as bigint | null)
      }
    }
  })
}

export default useBalances
