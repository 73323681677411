import { useQueries } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { erc404Abi } from 'constants/abi/erc404'
import {
  erc721Abi,
  parseEventLogs,
  TransactionReceipt,
  zeroAddress
} from 'viem'
import { useReadContracts } from 'wagmi'

const useErc404TokenImageUrls = ({
  chainId,
  receipt
}: {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  receipt?: TransactionReceipt
}) => {
  const transferEvents = parseEventLogs({
    abi: erc404Abi,
    eventName: ['Transfer'],
    logs: receipt?.logs || []
  }).filter((event) => event.args.to !== zeroAddress)

  const contractAddress =
    transferEvents.length > 0 ? transferEvents[0].address : undefined

  const { data: erc404Infos } = useReadContracts({
    contracts: [
      ...transferEvents.map((event) => ({
        abi: erc721Abi,
        address: event.address,
        args: [event.args.id],
        chainId,
        functionName: 'tokenURI'
      })),
      {
        abi: erc721Abi,
        address: contractAddress,
        chainId,
        functionName: 'symbol'
      }
    ],
    query: {
      select: (results) => {
        const uris = results
          .slice(0, -1)
          .map((result) => result.result as string | undefined)
          .filter(Boolean) as string[]

        const symbol = results[results.length - 1].result as string | undefined

        return { symbol, tokenURIs: uris.map(convertIpfsUrl) }
      }
    }
  })

  const tokenImageUrlsResponse = useQueries({
    queries:
      erc404Infos?.tokenURIs?.map((tokenURI) => ({
        queryFn: async () => {
          const response = await fetch(tokenURI)
          const metadata = await response.json()

          if (metadata && metadata.image) {
            return convertIpfsUrl(metadata.image)
          } else {
            return undefined
          }
        },
        queryKey: ['FetchErc404Metadata', tokenURI]
      })) || []
  })

  return {
    contractAddress,
    imageUrls: tokenImageUrlsResponse.map((response) => response.data),
    symbol: erc404Infos?.symbol
  }
}

export default useErc404TokenImageUrls

function convertIpfsUrl(ipfsUrl: string): string {
  // Split the IPFS URL to extract the hash and the file identifier
  const parts = ipfsUrl.split('/')
  const hash = parts[2] // Extract the hash part
  const fileIdentifier = parts[3] // Extract the file identifier

  // Construct the new URL
  const baseUrl = 'https://ipfs.io/ipfs'
  const newUrl = fileIdentifier
    ? `${baseUrl}/${hash}/${fileIdentifier}`
    : `${baseUrl}/${hash}`

  return newUrl
}
