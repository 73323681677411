/* eslint-disable @typescript-eslint/naming-convention */
import { isMobile } from 'react-device-detect'
import ReactGA from 'react-ga4'
import { Aggregator } from 'types/router'

export const initializeAnalytics = () => {
  const GOOGLE_ANALYTICS_ID: string | undefined =
    process.env.REACT_APP_GA_MEASUREMENT_ID
  if (typeof GOOGLE_ANALYTICS_ID === 'string' && GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID)
    ReactGA.set({
      customBrowserType: !isMobile
        ? 'desktop'
        : 'web3' in window || 'ethereum' in window
          ? 'mobileWeb3'
          : 'mobileRegular'
    })
    ReactGA.send('pageview')
  } else {
    ReactGA.initialize('test', { testMode: true })
  }
}

export const walletConnect = (walletName?: string): void => {
  ReactGA.event('connectWallet', {
    category: 'wallet',
    label: walletName
  })
}

/**
 * Pool Events Tracking
 *
 */
export const poolAddLiquidity = (poolName: string): void => {
  ReactGA.event('addLiquidity', {
    category: 'pool',
    label: poolName
  })
}

export const poolRemoveLiquidity = (poolName: string): void => {
  ReactGA.event('addLiquidity', {
    category: 'pool',
    label: poolName
  })
}

export const poolSelectDistributionShape = (distributionName: string): void => {
  ReactGA.event('selectDistributionShape', {
    category: 'pool',
    label: distributionName
  })
}

export const poolClaimFees = (poolName: string): void => {
  ReactGA.event('claim', {
    category: 'pool',
    label: poolName
  })
}

export const poolClaimRewards = (poolName: string): void => {
  ReactGA.event('claimRewards', {
    category: 'pool',
    label: poolName
  })
}

/**
 * Trade Events Tracking
 */

export const trackSwap = (
  symbolX?: string,
  symbolY?: string,
  aggregator?: Aggregator,
  amountUsd?: number
): void => {
  ReactGA.event('swapAOA', {
    aggregator: aggregator,
    amount_usd: amountUsd,
    category: 'trade',
    label: `${symbolX}/${symbolY}`
  })
}

export const tradeExpandSummary = (
  symbolX?: string,
  symbolY?: string
): void => {
  ReactGA.event('expand', {
    category: 'trade',
    label: `${symbolX}/${symbolY}`
  })
}

export const tradeAddToWallet = (symbol?: string): void => {
  ReactGA.event('addCurrencyToWallet', {
    category: 'trade',
    label: symbol
  })
}

/**
 * Home Events Tracking
 */
export const homeClickTile = (tileName: string): void => {
  ReactGA.event('clickTile', {
    category: 'home',
    label: tileName
  })
}

export const homeClickToken = (symbol: string): void => {
  ReactGA.event('clickToken', {
    category: 'home',
    label: symbol
  })
}

/**
 * Search Events Tracking
 */

export const searchEnterSearch = (): void => {
  ReactGA.event('enterSEarch', {
    category: 'search'
  })
}

export const searchClickMarket = (pairName: string): void => {
  ReactGA.event('clickMarket', {
    category: 'search',
    label: pairName
  })
}

/**
 * Stake Events Tracking
 */

export const stakeSJoe = (): void => {
  ReactGA.event('deposit', { category: 'stake', label: 'sJOE' })
}

export const unstakeSJoe = (): void => {
  ReactGA.event('withdraw', { category: 'stake', label: 'sJOE' })
}

export const claimSJoe = (): void => {
  ReactGA.event('claim', { category: 'stake', label: 'sJOE' })
}
