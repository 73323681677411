import { ChainId } from '@traderjoe-xyz/sdk-core'
import {
  LIMIT_ORDER_MANAGER_ADDRESS,
  LimitOrderManagerABI
} from '@traderjoe-xyz/sdk-v2'
import { useMemo } from 'react'
import { useSlippageSettings } from 'state/settings/hooks'
import {
  LimitOrder as DexbarnLimitOrder,
  OrderType as DexbarnOrderType
} from 'types/dexbarn'
import { OrderType } from 'types/limitOrder'
import { getAddress } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

interface UseLimitOrdersCurrentAmountsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  limitOrders?: DexbarnLimitOrder[]
}

const useLimitOrdersMinAmounts = ({
  chainId,
  limitOrders
}: UseLimitOrdersCurrentAmountsProps) => {
  const { address: account } = useAccount()

  const { slippageSettings } = useSlippageSettings()
  const amountSlippage = slippageSettings.liquidityAmountV2

  const contract = {
    abi: LimitOrderManagerABI,
    address: getAddress(LIMIT_ORDER_MANAGER_ADDRESS[chainId])
  } as const

  const { data: reads } = useReadContracts({
    contracts:
      account &&
      limitOrders?.map(
        ({
          binId,
          orderType,
          pairLbBinStep: binStep,
          tokenOut: { tokenX, tokenY }
        }) => ({
          ...contract,
          args: [
            getAddress(tokenX.tokenId),
            getAddress(tokenY.tokenId),
            binStep,
            orderType === DexbarnOrderType.BID ? OrderType.BID : OrderType.ASK,
            binId,
            account
          ],
          chainId,
          functionName: 'getCurrentAmounts'
        })
      ),
    query: { enabled: !!limitOrders && !!account }
  })

  const minAmounts = useMemo(() => {
    return reads?.map((data) => {
      const amountX = (data.result as any)?.[0] as bigint | undefined
      const amountY = (data.result as any)?.[1] as bigint | undefined
      const minAmountX = amountX
        ? (amountX * BigInt(10000 - amountSlippage * 100)) / BigInt(10000)
        : BigInt(0)
      const minAmountY = amountY
        ? (amountY * BigInt(10000 - amountSlippage * 100)) / BigInt(10000)
        : BigInt(0)
      return [minAmountX, minAmountY] as const
    })
  }, [reads, amountSlippage])

  return minAmounts
}

export default useLimitOrdersMinAmounts
