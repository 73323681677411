import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Flex,
  Heading,
  HStack,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { format, formatDistance } from 'date-fns'
import React from 'react'
import { SwapEvent } from 'types/dexbarn'
import { getChain } from 'utils/chains'
import { formattedNum } from 'utils/format'

interface PoolSwapsTableProps {
  currency0: Currency
  currency1: Currency
  swaps: SwapEvent[]
}

const PoolSwapsTable = ({
  currency0,
  currency1,
  swaps
}: PoolSwapsTableProps) => {
  const chain = getChain(currency0.chainId)
  const now = new Date()

  return (
    <Flex flexDir="column" gap={8}>
      <Heading size="md">
        <Trans>Recent Swaps</Trans>
      </Heading>
      <TableContainer>
        <Table maxWidth="100%" size="sm">
          <Thead>
            <Tr borderBottom="1px" borderBottomColor="border">
              <Th>
                <Trans>From</Trans>
              </Th>
              <Th>
                <Trans>To</Trans>
              </Th>
              <Th>
                <Trans>Price</Trans>
              </Th>
              <Th>
                <Trans>Bin ID</Trans>
              </Th>
              <Th textAlign="right">
                <Trans>Timestamp</Trans>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {swaps &&
              swaps.map((swap, key) =>
                SwapRow({
                  blockExplorerUrl: chain?.blockExplorers?.default.url,
                  currency0,
                  currency1,
                  key,
                  now,
                  swap
                })
              )}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}

interface SwapRowInterface {
  currency0: Currency
  currency1: Currency
  key: number
  now: Date
  swap: SwapEvent
  blockExplorerUrl?: string
}

const SwapRow = ({
  blockExplorerUrl,
  currency0,
  currency1,
  key,
  now,
  swap
}: SwapRowInterface) => {
  const from = `${formattedNum(swap.amountIn, { places: 4 })} ${
    swap.swapForY ? currency0.symbol : currency1.symbol
  }`
  const to = `${formattedNum(swap.amountOut, { places: 4 })} ${
    swap.swapForY ? currency1.symbol : currency0.symbol
  }`
  const price = formattedNum(swap.swapForY ? swap.priceXY : swap.priceYX, {
    places: 10
  })
  const timestamp = new Date(swap.timestamp)
  return (
    <Tr key={key} borderBottom="1px" borderBottomColor="border">
      <Td>{from}</Td>
      <Td>{to}</Td>
      <Td>{price}</Td>
      <Td>{swap.binId}</Td>
      <Td textAlign="right">
        <Flex flexDir="column" align="flex-end">
          <Link
            aria-label="Link to swap transaction on explorer"
            isExternal
            href={`${blockExplorerUrl}/tx/${swap.transactionHash}`}
          >
            <HStack spacing={1}>
              <Text>{format(timestamp, 'yyyy-MM-dd HH:mm:ss')}</Text>
              <ExternalLinkIcon />
            </HStack>
          </Link>
          <Text color="textSecondary" fontSize="xs" fontWeight="semibold">
            {formatDistance(timestamp, now, {
              addSuffix: true,
              includeSeconds: true
            })}
          </Text>
        </Flex>
      </Td>
    </Tr>
  )
}

export default PoolSwapsTable
