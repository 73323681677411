import { ChainId, WNATIVE } from '@traderjoe-xyz/sdk-core'
import useBalances from 'hooks/useBalances'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import { useMemo } from 'react'
import { useSavedTokens } from 'state/tokens/hooks'
import { TokenInfo, TokenInfoTag } from 'types/tokensList'
import { formatUnits, getAddress } from 'viem'

import useFetchTokensList from './useFetchTokensList'
import useNativeTokenInfo from './useNativeTokenInfo'
import useTokenSearch from './useTokenSearch'

interface UseTokensListProps {
  activeTag: TokenInfoTag
  chainId: Exclude<ChainId, ChainId.MANTLE>
  enabled?: boolean
  query?: string
}

const useTokensList = ({
  activeTag,
  chainId,
  enabled = true,
  query
}: UseTokensListProps) => {
  const { data, isLoading: isLoadingTokensList } = useFetchTokensList({
    enabled
  })
  const savedTokens = useSavedTokens()
  const { data: tokenFoundByAddress } = useTokenSearch({ chainId, query })
  const tokens = useMemo(() => {
    const remoteTokens =
      data?.tokens.filter((token) => token.chainId === chainId) ?? []
    const localTokens: TokenInfo[] = savedTokens
      .filter((token) => token.chainId === chainId)
      .map((token) => ({
        ...token,
        address: token.address ? getAddress(token.address) : undefined,
        symbol: token.symbol ?? '',
        tags: [TokenInfoTag.MY_TOKENS]
      }))
    return [...remoteTokens, ...localTokens]
  }, [data, chainId, savedTokens])

  const { data: nativeTokenInfo, isFetching: isLoadingNativeTokenInfo } =
    useNativeTokenInfo({ chainId, enabled })

  const { data: balances, isFetching: isLoadingBalances } = useBalances({
    chainId,
    enabled: !isLoadingTokensList && enabled,
    erc20Tokens: tokens
      .map((token) => token.address)
      .filter(Boolean) as `0x${string}`[]
  })

  const tokensWithBalance = useMemo((): TokenInfo[] => {
    const results: TokenInfo[] = []

    if (nativeTokenInfo) {
      results.push(nativeTokenInfo)
    }

    results.push(
      ...tokens.map((token, i) => {
        const balance = balances?.[i]
        return {
          ...token,
          balance: balance ? formatUnits(balance, token.decimals) : undefined
        }
      })
    )

    return results
  }, [balances, tokens, nativeTokenInfo])

  const tokenOwnedAddresses = useMemo(() => {
    const addresses = tokensWithBalance
      .filter((token) => token.balance !== undefined)
      .map((token) => token.address)
      .filter((address) => !!address) as `0x${string}`[]

    const wrappedNativeAddress = getAddress(WNATIVE[chainId].address)
    if (!addresses.includes(wrappedNativeAddress)) {
      addresses.push(wrappedNativeAddress)
    }

    return addresses
  }, [tokensWithBalance, chainId])

  const { data: tokenPricesUsd, isFetching: isLoadingPricesUsd } =
    useTokenPriceUSD({
      chainId,
      enabled:
        tokenOwnedAddresses.length > 0 &&
        !isLoadingTokensList &&
        !isLoadingBalances &&
        enabled,
      tokens: tokenOwnedAddresses
    })

  const tokenPricesUsdByAddress: { [address: string]: number } | undefined =
    useMemo(
      () =>
        tokenPricesUsd?.reduce(
          (prev, price, i) => ({
            ...prev,
            [tokenOwnedAddresses[i]]: price
          }),
          {}
        ),
      [tokenOwnedAddresses, tokenPricesUsd]
    )

  const filteredTokens = useMemo(() => {
    const lowercasedQuery = query?.toLowerCase() ?? ''
    const results = tokensWithBalance
      .map((token) => {
        const isNative = token.symbol === nativeTokenInfo?.symbol
        const tokenAddress = isNative
          ? WNATIVE[token.chainId as ChainId].address
          : token.address
        const tokenPriceUsd = tokenAddress
          ? tokenPricesUsdByAddress?.[tokenAddress]
          : undefined
        const totalValueUsd =
          tokenPriceUsd && token.balance
            ? tokenPriceUsd * Number(token.balance)
            : undefined
        return {
          ...token,
          tokenPriceUsd,
          totalValueUsd
        }
      })
      .filter((token) =>
        query && query.length > 0
          ? token.symbol.toLowerCase().includes(lowercasedQuery) ||
            token.name.toLowerCase().includes(lowercasedQuery)
          : token.tags.includes(activeTag) || activeTag === TokenInfoTag.ALL
      )
      .sort((a, b) => {
        if (a.totalValueUsd !== undefined && b.totalValueUsd !== undefined) {
          return b.totalValueUsd - a.totalValueUsd
        }
        if (a.totalValueUsd !== undefined) {
          return -1
        }
        if (b.totalValueUsd !== undefined) {
          return 1
        }
        return a.name.localeCompare(b.name)
      })
    return results
  }, [
    tokensWithBalance,
    activeTag,
    query,
    tokenPricesUsdByAddress,
    nativeTokenInfo?.symbol
  ])

  return {
    isLoading: isLoadingTokensList || isLoadingNativeTokenInfo,
    isLoadingBalances: isLoadingBalances || isLoadingPricesUsd,
    tokenFoundByAddress,
    tokens: filteredTokens
  }
}

export default useTokensList
