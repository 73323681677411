import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { WNativeABI } from '@traderjoe-xyz/sdk'
import { ChainId, Currency, WNATIVE } from '@traderjoe-xyz/sdk-core'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import {
  getIsNativeInAndWrappedNativeOut,
  getIsWrappedNativeInAndNativeOut
} from 'utils/wrappedCurrency'
import { getAddress, parseEther } from 'viem'
import { useSimulateContract, useWriteContract } from 'wagmi'

interface UseWrapUnwrapNativeCurrencyProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  amount?: string
  currency0?: Currency
  currency1?: Currency
  onSuccess?: () => void
}

const useWrapUnwrapNativeCurrency = ({
  amount,
  chainId,
  currency0,
  currency1,
  onSuccess
}: UseWrapUnwrapNativeCurrencyProps) => {
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const isNativeInAndWrappedNativeOut = getIsNativeInAndWrappedNativeOut(
    chainId,
    currency0,
    currency1
  )

  const isWrappedNativeInAndNativeOut = getIsWrappedNativeInAndNativeOut(
    chainId,
    currency0,
    currency1
  )

  const parsedAmount = amount ? parseEther(amount as `${number}`) : undefined

  const isDeposit = (isNativeInAndWrappedNativeOut && !!parsedAmount) || false
  const isWithdraw = (isWrappedNativeInAndNativeOut && !!parsedAmount) || false

  const { data: depositConfig } = useSimulateContract({
    abi: WNativeABI,
    address: getAddress(WNATIVE[chainId].address),
    functionName: 'deposit',
    query: {
      enabled: isDeposit,
      gcTime: 0
    },
    value: parsedAmount ?? BigInt(0)
  })

  const { data: withdrawConfig } = useSimulateContract({
    abi: WNativeABI,
    address: getAddress(WNATIVE[chainId].address),
    args: parsedAmount ? [parsedAmount] : undefined,
    functionName: 'withdraw',
    query: {
      enabled: isWithdraw,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = isNativeInAndWrappedNativeOut
          ? t`Deposit ${currency0?.symbol}`
          : t`Withdraw ${currency0?.symbol}`
        addRecentTransaction({ description, hash })
        addTransactionToast({ description, hash })
      }
    }
  })

  const write =
    isNativeInAndWrappedNativeOut && depositConfig?.request
      ? () => writeContract(depositConfig?.request)
      : withdrawConfig?.request
        ? () => writeContract(withdrawConfig?.request)
        : undefined

  const { isLoading: isWaitingForTransaction } = useWaitForTransactionReceipt({
    chainId,
    hash: data,
    onTransactionSuccess: onSuccess
  })

  return {
    isLoading: isWaitingForTransaction || isPending,
    state:
      parsedAmount === undefined
        ? undefined
        : isNativeInAndWrappedNativeOut
          ? 'Wrap'
          : isWrappedNativeInAndNativeOut
            ? 'Unwrap'
            : undefined,
    write
  }
}

export default useWrapUnwrapNativeCurrency
