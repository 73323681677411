import { ChainId, Currency, TokenAmount } from '@traderjoe-xyz/sdk-core'
import useDebounce from 'hooks/useDebounce'
import {
  getIsNativeInAndWrappedNativeOut,
  getIsWrappedNativeInAndNativeOut
} from 'utils/wrappedCurrency'

import useGetRoutesFromBarn from './useGetRoutesFromBarn'

interface UseGetTradeRoutesProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  inputCurrency?: Currency
  outputCurrency?: Currency
  refetchInterval?: number
  typedTokenAmount?: TokenAmount
}

const useGetTradeRoutes = ({
  chainId,
  inputCurrency,
  outputCurrency,
  refetchInterval,
  typedTokenAmount
}: UseGetTradeRoutesProps) => {
  const debouncedAmount = useDebounce(typedTokenAmount, 500)
  const debouncedInputCurrency = useDebounce(inputCurrency, 250)
  const debouncedOutputCurrency = useDebounce(outputCurrency, 250)

  const isNativeInAndWrappedNativeOut = getIsNativeInAndWrappedNativeOut(
    chainId,
    debouncedInputCurrency,
    debouncedOutputCurrency
  )

  const isWrappedNativeInAndNativeOut = getIsWrappedNativeInAndNativeOut(
    chainId,
    debouncedInputCurrency,
    debouncedOutputCurrency
  )

  return useGetRoutesFromBarn({
    amount: debouncedAmount?.raw?.toString(),
    chainId,
    currencyIn: debouncedInputCurrency,
    currencyOut: debouncedOutputCurrency,
    enabled: !isNativeInAndWrappedNativeOut && !isWrappedNativeInAndNativeOut,
    refetchInterval
  })
}

export default useGetTradeRoutes
