import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import {
  LIMIT_ORDER_MANAGER_ADDRESS,
  LimitOrderManagerABI
} from '@traderjoe-xyz/sdk-v2'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useMemo } from 'react'
import {
  LimitOrder as DexbarnLimitOrder,
  OrderType as DexbarnOrderType
} from 'types/dexbarn'
import { OrderType } from 'types/limitOrder'
import { BaseError, getAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseClaimOrderProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  limitOrder: DexbarnLimitOrder | undefined
  onTransactionSuccess: () => void
  enabled?: boolean
}

const useClaimOrder = ({
  chainId,
  enabled = true,
  limitOrder,
  onTransactionSuccess
}: UseClaimOrderProps) => {
  const walletChainId = useAccount().chain?.id

  const {
    binId,
    orderType,
    pairLbBinStep: lbBinStep,
    tokenOut
  } = limitOrder || {}
  const { tokenX, tokenY } = tokenOut || {}
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // prepare contract write
  const args =
    tokenX?.tokenId && tokenY?.tokenId && lbBinStep && binId && orderType
      ? ([
          getAddress(tokenX.tokenId),
          getAddress(tokenY.tokenId),
          lbBinStep,
          orderType === DexbarnOrderType.BID ? OrderType.BID : OrderType.ASK,
          binId
        ] as const)
      : undefined

  const { data: config, error: simulateContractError } = useSimulateContract({
    abi: LimitOrderManagerABI,
    address: getAddress(LIMIT_ORDER_MANAGER_ADDRESS[chainId]),
    args,
    functionName: 'claimOrder',
    query: {
      enabled: enabled && !!args && chainId === walletChainId,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Claim limit order`
        addRecentTransaction({ description, hash })
        addTransactionToast({ description, hash })
      }
    }
  })

  const claimOrder = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { data: receipt } = useWaitForTransactionReceipt({
    chainId,
    hash: data,
    onTransactionSuccess
  })

  const error = useMemo(() => {
    if (!simulateContractError) return undefined

    const isSlippageTooLow = simulateContractError.message.includes(
      'LimitOrderManager__InsufficientWithdrawalAmounts'
    )

    return {
      message: simulateContractError.message,
      summary: isSlippageTooLow
        ? t`Unable to claim: the slippage is too low. Please increase it from the settings.`
        : (simulateContractError as BaseError).shortMessage
    }
  }, [simulateContractError])

  return {
    claimOrder,
    error,
    isLoading: isPending,
    isSuccess: receipt?.status === 'success'
  }
}

export default useClaimOrder
