import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { ChainId, Token, TokenAmount } from '@traderjoe-xyz/sdk-core'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useCallback, useMemo } from 'react'
import { useState } from 'react'
import { BinAnalyticsData, PoolQueryParam } from 'types/dexbarn'
import { BinVolumeData, LBPairDistribution } from 'types/poolV2'
import { getDexbarnChainParam } from 'utils/chains'

interface UseGetBinsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  radius: number
  timeFilter: PoolQueryParam.FilterBy
  activeBinId?: number
  enabled?: boolean
  lbPairAddress?: string
}

export const useGetBins = ({
  activeBinId,
  chainId,
  enabled = true,
  lbPairAddress,
  radius,
  timeFilter
}: UseGetBinsProps) => {
  const chain = getDexbarnChainParam(chainId)
  const fetchBins = useDexbarnGet<BinAnalyticsData[]>(
    `/v1/bin/${chain}/${lbPairAddress}/${activeBinId}?filterBy=${timeFilter}&radius=${radius}`,
    chainId
  )

  return useQuery<BinAnalyticsData[]>({
    enabled: !!lbPairAddress && !!activeBinId && radius > 0 && enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchBins(),
    queryKey: [
      'BinAnalyticsData',
      lbPairAddress,
      activeBinId,
      chainId,
      radius,
      timeFilter
    ],
    retry: 0
  })
}

interface UseBinAnalyticsProps {
  binTradedRadius: number
  chainId: Exclude<ChainId, ChainId.MANTLE>
  distributionRadius: number
  activeBinId?: number
  enabled?: boolean
  lbPairAddress?: string
  tokenA?: Token
  tokenB?: Token
}

const useBinAnalytics = ({
  activeBinId,
  binTradedRadius,
  chainId,
  distributionRadius,
  enabled = true,
  lbPairAddress,
  tokenA,
  tokenB
}: UseBinAnalyticsProps) => {
  const [timeFilter, setTimeFilter] = useState<PoolQueryParam.FilterBy>('1d')
  const setBinTradedFilter = useCallback(
    (filter: PoolQueryParam.FilterBy) => {
      setTimeFilter(filter)
    },
    [setTimeFilter]
  )

  const {
    data: binsData,
    isFetching,
    isLoading
  } = useGetBins({
    activeBinId,
    chainId,
    enabled,
    lbPairAddress,
    radius: Math.max(binTradedRadius, distributionRadius),
    timeFilter
  })

  const binsTraded: BinVolumeData[] = useMemo(() => {
    if (!binsData || !activeBinId) {
      return []
    }
    return binsData
      .filter(
        (bin) =>
          bin.volumeUsd > 0 &&
          Math.abs(activeBinId - bin.binId) <= binTradedRadius
      )
      .map((bin) => ({
        binId: bin.binId,
        isActiveBin: bin.binId === activeBinId,
        priceXY: bin.priceXY,
        priceYX: bin.priceYX,
        volumeUsd: bin.volumeUsd,
        volumeX: bin.volumeX,
        volumeY: bin.volumeY
      }))
  }, [binsData, activeBinId, binTradedRadius])

  const poolDistributionData: LBPairDistribution[] = useMemo(() => {
    if (!binsData || !tokenA || !tokenB || !activeBinId) {
      return []
    }
    return binsData
      .filter((bin) => Math.abs(activeBinId - bin.binId) <= distributionRadius)
      .map((bin) => {
        const liquidity = bin.reserveY + bin.reserveX / bin.priceYX
        const reserveXRaw = !bin.reserveXRaw.includes('-')
          ? bin.reserveXRaw
          : '0'
        const reserveYRaw = !bin.reserveYRaw.includes('-')
          ? bin.reserveYRaw
          : '0'
        const reserveYRatio =
          bin.reserveY > 0 || bin.reserveX > 0
            ? bin.reserveY / (bin.reserveX * bin.priceXY + bin.reserveY)
            : 0
        return {
          amountX: new TokenAmount(tokenA, reserveXRaw),
          amountY: new TokenAmount(tokenB, reserveYRaw),
          amountYPct: (reserveYRatio * 100).toFixed(2),
          binId: bin.binId,
          isActiveBin: bin.binId === activeBinId,
          liquidity,
          price: bin.priceXY.toFixed(tokenB.decimals)
        }
      })
  }, [binsData, tokenA, tokenB, activeBinId, distributionRadius])

  return {
    binsTraded,
    isFetching,
    isLoading,
    poolDistributionData,
    setBinTradedFilter
  }
}

export default useBinAnalytics
