import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import {
  LIMIT_ORDER_MANAGER_ADDRESS,
  LimitOrderManagerABI
} from '@traderjoe-xyz/sdk-v2'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useMemo } from 'react'
import {
  LimitOrder as DexbarnLimitOrder,
  OrderType as DexbarnOrderType
} from 'types/dexbarn'
import { OrderType } from 'types/limitOrder'
import { getAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

import useLimitOrdersMinAmounts from './useLimitOrdersMinAmounts'

interface UseBatchCancelMakeOrdersProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  limitOrders: DexbarnLimitOrder[] | undefined
  enabled?: boolean
  onSuccess?: () => void
}

const useBatchCancelMakeOrders = ({
  chainId,
  enabled = true,
  limitOrders,
  onSuccess
}: UseBatchCancelMakeOrdersProps) => {
  const walletChainId = useAccount().chain?.id

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // get min amounts for each order
  const minAmounts = useLimitOrdersMinAmounts({
    chainId,
    limitOrders
  })

  // prepare contract write
  const args = useMemo(
    () =>
      limitOrders && limitOrders.length > 0 && minAmounts
        ? ([
            limitOrders.map(
              (
                {
                  binId,
                  orderType,
                  pairLbBinStep: binStep,
                  tokenOut: { tokenX, tokenY }
                },
                i
              ) => {
                return {
                  binId: binId,
                  binStep: binStep,
                  minAmountX: minAmounts[i][0],
                  minAmountY: minAmounts[i][1],
                  orderType:
                    orderType === DexbarnOrderType.BID
                      ? OrderType.BID
                      : OrderType.ASK,
                  tokenX: getAddress(tokenX.tokenId),
                  tokenY: getAddress(tokenY.tokenId)
                }
              }
            )
          ] as const)
        : undefined,
    [limitOrders, minAmounts]
  )

  const { data: config } = useSimulateContract({
    abi: LimitOrderManagerABI,
    address: getAddress(LIMIT_ORDER_MANAGER_ADDRESS[chainId]),
    args,
    functionName: 'batchCancelOrders',
    query: {
      enabled: enabled && !!args && chainId === walletChainId,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Cancel limit orders`
        addRecentTransaction({ description, hash })
        addTransactionToast({ description, hash })
      }
    }
  })

  const batchCancelOrders = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction } = useWaitForTransactionReceipt({
    chainId,
    hash: data,
    onTransactionSuccess: onSuccess
  })

  return { batchCancelOrders, isLoading: isPending || isWaitingForTransaction }
}

export default useBatchCancelMakeOrders
