import { apiGatewayClient, apiGatewayTestnetClient } from 'constants/joebarn'
import { useEffect } from 'react'
import { useOriginCountry } from 'state/settings/hooks'

import useChainFromUrlParam from './useChainFromUrlParam'

const updateToastManagerTopMargin = (countryCode: string) => {
  const top = countryCode === 'GB' ? '152px' : '76px'
  document.documentElement.style.setProperty('--toast-top-offset', top)
}

const useLoadOriginCountry = () => {
  const chain = useChainFromUrlParam()
  const client = chain?.testnet ? apiGatewayTestnetClient : apiGatewayClient

  const { updateOriginCountryCode } = useOriginCountry()

  useEffect(() => {
    const fetch = async () => {
      const { headers } = await client.get('')
      const countryCode = headers['x-tj-country'] as string
      return countryCode
    }

    fetch().then((countryCode) => {
      updateOriginCountryCode(countryCode)
      updateToastManagerTopMargin(countryCode)
    })
  }, [client, updateOriginCountryCode])
}

export default useLoadOriginCountry
