import {
  HStack,
  Image,
  Tab,
  TabList,
  TabProps,
  Tabs,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { supportedChains } from 'constants/chains'
import React from 'react'
import { useShowTestnets } from 'state/settings/hooks'

interface ChainTabsProps {
  selectedChainId: number
  setChainId: (chainId: number) => void
  showName?: boolean
  tabProps?: TabProps
}

const ChainTabs = ({
  selectedChainId,
  setChainId,
  showName,
  tabProps
}: ChainTabsProps) => {
  const { showTestnets } = useShowTestnets()
  const chains = showTestnets
    ? supportedChains.filter((chain) => !chain.isDeprecated)
    : supportedChains.filter((chain) => !chain.testnet && !chain.isDeprecated)

  const chainIndex = chains.findIndex((chain) => chain.id === selectedChainId)

  const onChange = (index: number) => {
    setChainId(chains[index].id)
  }

  return (
    <Tabs variant="solid" index={chainIndex} onChange={onChange}>
      <TabList>
        {chains.map((chain, i) => (
          <Tooltip label={showName ? '' : chain.name} key={i}>
            <Tab
              key={i}
              data-cy={`chain-tab-${chain.slug}`}
              borderRadius={showName ? 'full' : 'lg'}
              px={4}
              {...tabProps}
            >
              <HStack>
                <Image alt="chain-icon" src={chain.iconUrl} boxSize={5} />
                {showName && <Text>{chain.name}</Text>}
              </HStack>
            </Tab>
          </Tooltip>
        ))}
      </TabList>
    </Tabs>
  )
}

export default ChainTabs
