import {
  Box,
  Button,
  Divider,
  Flex,
  Hide,
  HStack,
  IconButton,
  Text
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ConnectButton as RainbowKitConnectButton } from '@rainbow-me/rainbowkit'
import MobileAccountMenu from 'components/MobileAccountMenu'
import WalletMenu from 'components/WalletMenu'
import PopoverSettingsMenu from 'components/WalletMenu/SettingScreen/PopoverSettingsScreen'
import useDisableConnectOptionsIfTermsNotAccepted from 'hooks/useDisableConnectOptionsIfTermsNotAccepted'
import React from 'react'
import { MySettingsIcon, WalletIcon } from 'theme/icons'
import { e2eTestsConfig, isRunningE2ETests } from 'utils/e2e'
import { useConnect } from 'wagmi'

const ConnectButton = () => {
  const { connect, connectors } = useConnect()

  useDisableConnectOptionsIfTermsNotAccepted()

  return (
    <RainbowKitConnectButton.Custom>
      {({ account, chain, mounted, openConnectModal }) => {
        const ready = mounted
        const connected = ready && !!account && !!chain

        const onConnectWalletClick = isRunningE2ETests
          ? () =>
              connect({
                chainId: e2eTestsConfig.chain.id,
                connector: connectors[0]
              })
          : openConnectModal

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none'
              }
            })}
          >
            {(() => {
              return (
                <Flex alignItems="center" justifyContent="center" gap={4}>
                  {!connected ? (
                    <>
                      <Hide below="lg">
                        <HStack spacing={0} flexShrink={0}>
                          <Button
                            data-cy="connect-wallet-button"
                            variant="outline"
                            h="48px"
                            leftIcon={<WalletIcon w="20px" />}
                            onClick={onConnectWalletClick}
                            borderRight={0}
                            borderRadius={0}
                            borderLeftRadius="full"
                          >
                            <Text>
                              <Trans>Connect Wallet</Trans>
                            </Text>
                          </Button>
                          <Divider orientation="vertical" h="24px" />
                          <PopoverSettingsMenu
                            popoverTrigger={
                              <IconButton
                                aria-label="settings menu"
                                icon={<MySettingsIcon />}
                                variant="outline"
                                boxSize={12}
                                borderLeft={0}
                                borderRadius={0}
                                borderRightRadius="full"
                              />
                            }
                          />
                        </HStack>
                      </Hide>
                      <Hide above="lg">
                        <IconButton
                          bg="transparent"
                          aria-label="connect-wallet"
                          w="full"
                          onClick={onConnectWalletClick}
                        >
                          <Flex alignItems="center" pos="relative">
                            <WalletIcon w="21px" h="18px" />
                            <Box
                              pos="absolute"
                              top={-1}
                              boxSize={2}
                              right={-3}
                              borderRadius="full"
                              bgColor="avaxRed"
                            />
                          </Flex>
                        </IconButton>
                      </Hide>
                    </>
                  ) : (
                    <>
                      <Hide below="lg">
                        <WalletMenu
                          accountDisplayName={account.displayName}
                          walletAddress={account.address}
                        />
                      </Hide>
                      <Hide above="lg">
                        <MobileAccountMenu walletAddress={account.address} />
                      </Hide>
                    </>
                  )}
                </Flex>
              )
            })()}
          </div>
        )
      }}
    </RainbowKitConnectButton.Custom>
  )
}

export default ConnectButton
