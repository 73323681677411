import { useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { useDexbarnGet } from 'hooks/useDexbarn'
import useEpochRewards from 'hooks/useEpochRewards'
import { Pool as DexbarnPool } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnPoolToPool } from 'utils/poolV2'

interface UsePoolV2Props {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  address?: string
}

const usePoolV2 = ({ address, chainId }: UsePoolV2Props) => {
  const chain = getDexbarnChainParam(chainId)

  const fetchPool = useDexbarnGet<DexbarnPool>(
    `/v1/pools/${chain}/${address?.toLowerCase()}`,
    chainId
  )
  const result = useQuery<DexbarnPool>({
    enabled: !!address,
    queryFn: () => fetchPool(),
    queryKey: ['PoolV2Query', chainId, address],
    staleTime: 10 * (60 * 1000) // 10 mins
  })

  const { epochRewards } = useEpochRewards({ chainId })

  return {
    ...result,
    data: result.data
      ? convertDexbarnPoolToPool(result.data, epochRewards || [])
      : undefined
  }
}

export default usePoolV2
