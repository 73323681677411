import { ChainId } from '@traderjoe-xyz/sdk-core'
import {
  LB_FACTORY_V22_ADDRESS,
  LB_HOOKS_LENS_ADDRESS,
  LBFactoryV21ABI,
  LBHooksLensABI
} from '@traderjoe-xyz/sdk-v2'
import { getUserClaimableRewards } from 'utils/lbRewards'
import { Hex, zeroAddress } from 'viem'
import { useAccount, useReadContract, useReadContracts } from 'wagmi'

import useUserLBPositions from './useUserLBPositions'

interface UseGetUserLbPendingRewardsForAllPairsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
}

const useGetUserLbPendingRewardsForAllPairs = ({
  chainId
}: UseGetUserLbPendingRewardsForAllPairsProps) => {
  const account = useAccount()

  const { data: lbPairsLength } = useReadContract({
    abi: LBFactoryV21ABI,
    address: LB_FACTORY_V22_ADDRESS[chainId],
    chainId,
    functionName: 'getNumberOfLBPairs'
  })

  const lbPairCalls = []
  if (lbPairsLength) {
    for (let i = 0; i < lbPairsLength; i++) {
      lbPairCalls.push({
        abi: LBFactoryV21ABI,
        address: LB_FACTORY_V22_ADDRESS[chainId],
        args: [BigInt(i)],
        chainId,
        functionName: 'getLBPairAtIndex'
      } as const)
    }
  }

  const { data: lbPairAddresses = [] } = useReadContracts({
    contracts: lbPairCalls,
    query: {
      enabled: lbPairsLength ? lbPairsLength > 0 : false,
      select: (data) => {
        return data.map((d) => d.result).filter(Boolean) as Hex[]
      }
    }
  })

  const { data: userLbPositions } = useUserLBPositions({
    chainId,
    enabled: !!lbPairAddresses
  })

  return useReadContract({
    abi: LBHooksLensABI,
    address: LB_HOOKS_LENS_ADDRESS[chainId],
    args: [
      lbPairAddresses,
      account.address || zeroAddress,
      lbPairAddresses.map(
        (lbPairAddress) =>
          userLbPositions
            .find(
              (position) =>
                position.poolAddress.toLowerCase() ===
                lbPairAddress.toLowerCase()
            )
            ?.binIds.map((id) => BigInt(id)) || []
      )
    ],
    chainId,
    functionName: 'getBatchHooksData',
    query: {
      enabled: !!lbPairAddresses && !!account,
      select: (results) => {
        const rewarderDatas = results[0]
        const extraRewarderDatas = results[1]

        const data = rewarderDatas.map((rewarderData, index) => {
          const extraRewarderData = extraRewarderDatas[index]
          const pairAddress = lbPairAddresses[index]

          const userClaimableRewards = getUserClaimableRewards({
            chainId,
            extraRewarderData,
            rewarderData
          })

          return {
            extraRewarderData,
            pairAddress,
            rewarderData,
            userClaimableRewards
          }
        })

        return data.filter((d) => d.userClaimableRewards.length > 0)
      }
    }
  })
}

export default useGetUserLbPendingRewardsForAllPairs
