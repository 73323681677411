import { ChainId } from '@traderjoe-xyz/sdk-core'
import { LBPairABI, LBPairV21ABI } from '@traderjoe-xyz/sdk-v2'
import useEpochRewards from 'hooks/useEpochRewards'
import { UserPositionRowProps } from 'pages/Pool/UserPositions/UserPositionRow'
import { LBPoolVersion } from 'types/pool'
import { UserPoolPosition } from 'types/poolV1'
import { UserLBPosition } from 'types/poolV2'
import { getPoolPoints } from 'utils/points'
import { convertRewardsInfoToLBPoolReward } from 'utils/poolV2'
import { getAddress } from 'viem'
import { useReadContracts } from 'wagmi'

import useGetBatchLbRewarderData from './v2/useGetBatchLbRewarderData'

interface UseUserPositionsRowsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  lbPositions: UserLBPosition[]
  positions: UserPoolPosition[]
}

const useUserPositionsRows = ({
  chainId,
  lbPositions,
  positions
}: UseUserPositionsRowsProps) => {
  // fetch epoch rewards
  const { epochRewards } = useEpochRewards({ chainId })

  // fetch active bin for v2 pools
  const { data: activeBinResults } = useReadContracts({
    contracts: lbPositions.map((position) => ({
      abi: position.version === 'v2.0' ? LBPairABI : LBPairV21ABI,
      address: getAddress(position.poolAddress),
      chainId,
      functionName:
        position.version === 'v2.0' ? 'getReservesAndId' : 'getActiveId'
    }))
  })

  // active bin per pool address
  const activeBinPerPool = activeBinResults?.reduce(
    (acc, result, i) => {
      if (result && result.status === 'success') {
        let activeId: number
        switch (lbPositions[i].version) {
          case 'v2.0':
            activeId = Number((result.result as [bigint, bigint, bigint])[2])
            break
          case 'v2.1':
          case 'v2.2':
            activeId = result.result as number
            break
        }
        acc[lbPositions[i].poolAddress] = activeId
      }
      return acc
    },
    {} as { [poolAddress: string]: number }
  )

  // get lb rewards
  const { data: rewarderDataPerPairAddress } = useGetBatchLbRewarderData({
    chainId,
    pairs: lbPositions
      .filter((lbPosition) => lbPosition.version === 'v2.2')
      .map((lbPosition) => ({
        binStep: lbPosition.lbBinStep,
        currency0Decimals: lbPosition.tokenX.decimals,
        currency1Decimals: lbPosition.tokenY.decimals,
        ids: lbPosition.binIds.map((binId) => binId.toString()),
        pairAddress: lbPosition.poolAddress
      }))
  })

  // convert v1 positions
  const v1Rows: UserPositionRowProps[] = positions.map((position) => {
    const totalAmountUsd =
      Number(position.pooledToken0.formatted) * position.token0.priceUsd +
      Number(position.pooledToken1.formatted) * position.token1.priceUsd

    return {
      chainId,
      feePct: 0.3,
      isPoolMigrated: false,
      isPoolRewarded: false,
      points: getPoolPoints(position.id),
      poolAddress: position.id,
      tokenX: {
        ...position.token0,
        address: position.token0.address,
        amount: Number(position.pooledToken0.formatted),
        decimals: position.token0.decimals
      },
      tokenY: {
        ...position.token1,
        address: position.token1.address,
        amount: Number(position.pooledToken1.formatted),
        decimals: position.token1.decimals
      },
      totalAmountUsd
    }
  })

  // convert v2 positions
  const v2Rows: UserPositionRowProps[] = lbPositions.map((position) => {
    const lbRewards =
      rewarderDataPerPairAddress?.[position.poolAddress.toLowerCase()]
        ?.rewards || []

    const poolRewards =
      epochRewards
        ?.map((rewards) =>
          rewards.filter(
            (reward) =>
              reward.poolId.toLowerCase() === position.poolAddress.toLowerCase()
          )
        )
        .filter((rewards) => rewards.length > 0) || []

    const activeBinId = activeBinPerPool?.[position.poolAddress]

    const processedPoolRewards =
      poolRewards.length > 0
        ? poolRewards.map(convertRewardsInfoToLBPoolReward)
        : undefined

    const isPoolRewarded =
      processedPoolRewards?.some(
        (reward) => reward.epoch.status === 'ongoing'
      ) || false

    let lbPoolVersion: LBPoolVersion
    switch (position.version) {
      case 'v2.0':
        lbPoolVersion = 'v2'
        break
      case 'v2.1':
        lbPoolVersion = 'v21'
        break
      case 'v2.2':
        lbPoolVersion = 'v22'
        break
    }

    return {
      chainId,
      feePct: position.lbBaseFeePct,
      isOutOfPosition: activeBinId
        ? !position.binIds.includes(activeBinId)
        : false,
      isPoolMigrated: position.status === 'old',
      isPoolRewarded,
      lbBinStep: position.lbBinStep,
      lbPoolVersion,
      lbRewards,
      points: getPoolPoints(position.poolAddress),
      poolAddress: position.poolAddress,
      rewards: processedPoolRewards,
      tokenX: {
        ...position.tokenX,
        amount: Number(position.totalAmountX.formatted)
      },
      tokenY: {
        ...position.tokenY,
        amount: Number(position.totalAmountY.formatted)
      },
      totalAmountUsd:
        Number(position.totalAmountX.formatted) * position.tokenX.priceUsd +
        Number(position.totalAmountY.formatted) * position.tokenY.priceUsd
    }
  })

  const allPositions = v1Rows.concat(v2Rows)

  // sort by total amount in usd
  return allPositions.sort(
    (a, b) => (b.totalAmountUsd ?? 0) - (a.totalAmountUsd ?? 0)
  )
}

export default useUserPositionsRows
