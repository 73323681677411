import { ChainId } from '@traderjoe-xyz/sdk-core'
import { Hex, zeroAddress } from 'viem'

export const LB_SWAP_ROUTER: {
  [key in Exclude<ChainId, ChainId.MANTLE>]: Hex
} = {
  [ChainId.FUJI]: '0x45A62B090DF48243F12A21897e7ed91863E2c86b',
  [ChainId.AVALANCHE]: '0x45A62B090DF48243F12A21897e7ed91863E2c86b',
  [ChainId.ARBITRUM_ONE]: '0x45A62B090DF48243F12A21897e7ed91863E2c86b',
  [ChainId.ARB_GOERLI]: zeroAddress,
  [ChainId.BNB_CHAIN]: zeroAddress,
  [ChainId.BNB_TESTNET]: zeroAddress,
  [ChainId.ETHEREUM]: zeroAddress
}

export const LB_SWAP_ROUTER_LOGIC: {
  [key in Exclude<ChainId, ChainId.MANTLE>]: Hex
} = {
  [ChainId.FUJI]: '0xB35033d71cF5E13cAB5eB8618260F94363Dff9Cf',
  [ChainId.AVALANCHE]: '0xB35033d71cF5E13cAB5eB8618260F94363Dff9Cf',
  [ChainId.ARBITRUM_ONE]: '0xB35033d71cF5E13cAB5eB8618260F94363Dff9Cf',
  [ChainId.ARB_GOERLI]: zeroAddress,
  [ChainId.BNB_CHAIN]: zeroAddress,
  [ChainId.BNB_TESTNET]: zeroAddress,
  [ChainId.ETHEREUM]: zeroAddress
}

export const OKX_TOKEN_SPENDER: {
  [key in Exclude<ChainId, ChainId.MANTLE>]: Hex
} = {
  [ChainId.FUJI]: zeroAddress,
  [ChainId.AVALANCHE]: '0x40aA958dd87FC8305b97f2BA922CDdCa374bcD7f',
  [ChainId.ARBITRUM_ONE]: '0x70cBb871E8f30Fc8Ce23609E9E0Ea87B6b222F58',
  [ChainId.ARB_GOERLI]: zeroAddress,
  [ChainId.BNB_CHAIN]: zeroAddress,
  [ChainId.BNB_TESTNET]: zeroAddress,
  [ChainId.ETHEREUM]: zeroAddress
}

export const ONE_INCH_SPENDER: {
  [key in Exclude<ChainId, ChainId.MANTLE>]: Hex
} = {
  [ChainId.FUJI]: zeroAddress,
  [ChainId.AVALANCHE]: '0x111111125421ca6dc452d289314280a0f8842a65',
  [ChainId.ARBITRUM_ONE]: '0x111111125421ca6dc452d289314280a0f8842a65',
  [ChainId.ARB_GOERLI]: zeroAddress,
  [ChainId.BNB_CHAIN]: zeroAddress,
  [ChainId.BNB_TESTNET]: zeroAddress,
  [ChainId.ETHEREUM]: zeroAddress
}

export const ODOS_SPENDER: {
  [key in Exclude<ChainId, ChainId.MANTLE>]: Hex
} = {
  [ChainId.FUJI]: zeroAddress,
  [ChainId.AVALANCHE]: '0x88de50B233052e4Fb783d4F6db78Cc34fEa3e9FC',
  [ChainId.ARBITRUM_ONE]: '0xa669e7A0d4b3e4Fa48af2dE86BD4CD7126Be4e13',
  [ChainId.ARB_GOERLI]: zeroAddress,
  [ChainId.BNB_CHAIN]: zeroAddress,
  [ChainId.BNB_TESTNET]: zeroAddress,
  [ChainId.ETHEREUM]: zeroAddress
}
