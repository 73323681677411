import { Center, Flex, Spinner, Text } from '@chakra-ui/react'
import { ChainId, Currency } from '@traderjoe-xyz/sdk-core'
import Web3Button from 'components/Web3Button'
import useBatchCancelMakeOrders from 'hooks/limitOrder/useBatchCancelMakeOrders'
import { RemoveLiquidityV2Option } from 'hooks/pool/v2/useRemoveLiquidityV2'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import React, { useEffect, useMemo, useState } from 'react'
import { LimitOrder } from 'types/dexbarn'
import { wrappedCurrency } from 'utils/wrappedCurrency'

import RemoveLiquidityOptions from '../RemoveLiquidityOptions'

interface CancelOrdersPanelProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  isLoadingOrders: boolean
  isPriceRatioInversed: boolean
  onCancelOrdersSuccess: () => void
  placedOrders: LimitOrder[]
  activeBinId?: number
  binStep?: string
  currency0?: Currency
  currency1?: Currency
}

const CancelOrdersPanel = ({
  activeBinId,
  binStep,
  chainId,
  currency0,
  currency1,
  isLoadingOrders,
  isPriceRatioInversed,
  onCancelOrdersSuccess,
  placedOrders
}: CancelOrdersPanelProps) => {
  const wrappedCurrency0 = wrappedCurrency(currency0)
  const wrappedCurrency1 = wrappedCurrency(currency1)

  // get all binIds
  const binIds = useMemo(() => {
    return placedOrders.map((order) => order.binId).sort()
  }, [placedOrders])

  const [range, setRange] = useState<number[]>([])
  const [removeLiquidityOption, setRemoveLiquidityOption] =
    useState<RemoveLiquidityV2Option>(RemoveLiquidityV2Option.BOTH_TOKENS)

  // get orders to cancel
  const selectedOrders = useMemo(() => {
    if (range.length === 0 || !activeBinId) return []
    switch (removeLiquidityOption) {
      case RemoveLiquidityV2Option.BOTH_TOKENS:
        return placedOrders.filter(
          (order) => order.binId >= range[0] && order.binId <= range[1]
        )
      case RemoveLiquidityV2Option.TOKEN_A:
        return placedOrders.filter((order) => order.binId > activeBinId)
      case RemoveLiquidityV2Option.TOKEN_B:
        return placedOrders.filter((order) => order.binId < activeBinId)
    }
  }, [range, placedOrders, removeLiquidityOption, activeBinId])

  const { batchCancelOrders, isLoading: isCancelingOrders } =
    useBatchCancelMakeOrders({
      chainId,
      enabled: selectedOrders.length > 0,
      limitOrders: selectedOrders,
      onSuccess: onCancelOrdersSuccess
    })

  const { data: usdPrices } = useTokenPriceUSD({
    chainId,
    tokens:
      wrappedCurrency0 && wrappedCurrency1
        ? [wrappedCurrency0.address, wrappedCurrency1.address]
        : []
  })

  // update range when orders change
  useEffect(() => {
    setRange(binIds.length >= 1 ? [binIds[0], binIds[binIds.length - 1]] : [])
  }, [binIds])

  if (isLoadingOrders || !currency0 || !currency1) {
    return (
      <Center h="120px">
        <Spinner />
      </Center>
    )
  }

  if (placedOrders.length === 0 || range.length < 2) {
    return (
      <Center h="160px">
        <Text color="textSecondary" fontSize="sm">
          {`You don't have any active orders in this pool`}
        </Text>
      </Center>
    )
  }

  return (
    <Flex flexDir="column" gap={4}>
      <RemoveLiquidityOptions
        currencyPrice0={usdPrices?.[0]}
        currencyPrice1={usdPrices?.[1]}
        chainId={chainId}
        currency0={currency0}
        currency1={currency1}
        binStep={Number(binStep)}
        userPositions={binIds}
        range={range}
        onRangeChange={setRange}
        onRemoveLiquidityOptionChange={setRemoveLiquidityOption}
        inversePriceRatios={isPriceRatioInversed}
      />
      <Web3Button
        variant="primary"
        colorScheme="accent"
        size="lg"
        isLoading={isCancelingOrders}
        loadingText="Canceling orders"
        isDisabled={!batchCancelOrders}
        onClick={batchCancelOrders}
      >
        Cancel orders
      </Web3Button>
    </Flex>
  )
}

export default CancelOrdersPanel
