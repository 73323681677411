import { ChainId } from '@traderjoe-xyz/sdk-core'
import { getDexbarnChainParam } from 'utils/chains'
import { useAccount } from 'wagmi'

import useVaults from './useVaults'

interface UseUserVaultsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
}

const useUserVaults = ({ chainId }: UseUserVaultsProps) => {
  const { address: account } = useAccount()
  const result = useVaults({
    chain: getDexbarnChainParam(chainId),
    chainId,
    enabled: !!account,
    userAddress: account
  })
  return {
    ...result,
    isLoading: result.isLoading
  }
}

export default useUserVaults
