import { useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Token as DexbarnToken } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

import { useDexbarnGet } from './useDexbarn'

dayjs.extend(utc)

export interface Token {
  address: string
  price: number
  symbol: string
  volume: number
  pctChange?: number
}

export type TokensSortType = 'volume' | 'pct_change' | 'price'

interface UseTopTradedTokensProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  sortType: TokensSortType
}

const useTopTradedTokens = ({ chainId, sortType }: UseTopTradedTokensProps) => {
  const chainParam = getDexbarnChainParam(chainId)

  const fetchTopTradedTokensV2 = useDexbarnGet<DexbarnToken[]>(
    `/v1/tokens/${chainParam}?orderBy=${sortType}&filterBy=1d&pageSize=15`,
    chainId
  )
  return useQuery({
    queryFn: () => fetchTopTradedTokensV2(),
    queryKey: ['TokensAnalyticsQuery', sortType, chainId],
    select: (data) =>
      data.map(
        (token) =>
          ({
            address: token.tokenAddress,
            pctChange: token.pctChange,
            price: token.priceUsd,
            symbol: token.symbol,
            volume: token.volume
          }) satisfies Token
      )
  })
}

export default useTopTradedTokens
