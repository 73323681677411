import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { CNATIVE } from '@traderjoe-xyz/sdk-core'
import { LBSwapRouterAbi } from 'constants/abi/lbSwapRouter'
import { LB_SWAP_ROUTER } from 'constants/addresses'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useEffect, useMemo } from 'react'
import { formattedNum } from 'utils/format'
import { BaseError, encodeFunctionData, getAddress } from 'viem'
import {
  useAccount,
  useEstimateGas,
  useSimulateContract,
  useWalletClient,
  useWriteContract
} from 'wagmi'

import { UseSwapProps, UseSwapResult } from './useSwap'
import useSwapCallArguments from './useSwapCallArguments'

const useSwapWithJoeAggregator = ({
  chainId,
  currencyIn,
  currencyOut,
  enabled,
  isExactIn,
  onSwapSuccess,
  route,
  slippageBps
}: UseSwapProps): UseSwapResult => {
  const walletClient = useWalletClient({ chainId })
  const { chain: walletChain } = useAccount()
  const nativeCurrency = CNATIVE.onChain(chainId)
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()
  const { defaultParameters, feeOnTransferParameters } = useSwapCallArguments({
    chainId,
    currencyIn,
    currencyOut,
    isExactIn,
    route,
    slippageBps
  })

  const {
    data: defaultConfig,
    error: defaultSimulationError,
    isError: isDefaultSimulationError,
    isLoading: isSimulatingDefault
  } = useSimulateContract({
    abi: LBSwapRouterAbi,
    address: getAddress(LB_SWAP_ROUTER[chainId]),
    args: defaultParameters?.args,
    chainId,
    functionName: isExactIn ? 'swapExactIn' : 'swapExactOut',
    query: {
      enabled: !!defaultParameters && enabled && walletChain?.id === chainId,
      gcTime: 0
    },
    value: defaultParameters ? BigInt(defaultParameters.value) : BigInt(0)
  })

  const {
    data: feeOnTransferConfig,
    error: feeOnTransferSimulationError,
    isError: isFeeOnTransferSimulationError,
    isLoading: isSimulatingFeeOnTransfer
  } = useSimulateContract({
    abi: LBSwapRouterAbi,
    address: getAddress(LB_SWAP_ROUTER[chainId]),
    args: feeOnTransferParameters?.args,
    chainId,
    functionName: isExactIn ? 'swapExactIn' : 'swapExactOut',
    query: {
      enabled:
        !!feeOnTransferParameters && enabled && walletChain?.id === chainId,
      gcTime: 0
    },
    value: feeOnTransferParameters
      ? BigInt(feeOnTransferParameters.value)
      : BigInt(0)
  })

  const _config = defaultConfig || feeOnTransferConfig
  const _params =
    isDefaultSimulationError && !isFeeOnTransferSimulationError
      ? feeOnTransferParameters
      : defaultParameters

  const { data: estimatedGas, isLoading: isEstimatingGas } = useEstimateGas({
    account: walletClient.data?.account,
    chainId,
    data: _params
      ? encodeFunctionData({
          abi: LBSwapRouterAbi,
          args: _params.args,
          functionName: isExactIn ? 'swapExactIn' : 'swapExactOut'
        })
      : undefined,
    to: getAddress(LB_SWAP_ROUTER[chainId]),
    value: defaultParameters ? BigInt(defaultParameters.value) : BigInt(0)
  })

  const transactionSummary = useMemo(() => {
    if (!route) return ''
    const inputSymbol = currencyIn?.isNative
      ? nativeCurrency?.symbol
      : currencyIn?.symbol
    const outputSymbol = currencyOut?.isNative
      ? nativeCurrency?.symbol
      : currencyOut?.symbol
    const inputAmount = route.amountIn.formatted
    const outputAmount = route.amountOut.formatted
    return t`Swap ${formattedNum(
      inputAmount
    )} ${inputSymbol} for ${formattedNum(outputAmount)} ${outputSymbol}`
  }, [route, nativeCurrency, currencyIn, currencyOut])

  const config = _config
    ? {
        ..._config,
        request: {
          ..._config.request,
          gas: estimatedGas
            ? (estimatedGas * BigInt(110)) / BigInt(100)
            : undefined
        }
      }
    : undefined

  const {
    data: hash,
    isPending,
    reset,
    writeContractAsync
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        addRecentTransaction({ description: transactionSummary, hash })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  //  force send the transaction when the simulation fails still works
  // https://github.com/wevm/wagmi/discussions/2661
  const writeArgsFallback = defaultParameters
    ? {
        abi: LBSwapRouterAbi,
        address: getAddress(LB_SWAP_ROUTER[chainId]),
        args: defaultParameters.args,
        functionName: defaultParameters.methodName,
        value: defaultParameters.value
      }
    : undefined

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransactionReceipt({
      chainId,
      hash,
      onTransactionSuccess: onSwapSuccess
    })

  useEffect(() => {
    reset()
  }, [chainId, reset])

  const error = useMemo(() => {
    if (!defaultSimulationError || !feeOnTransferSimulationError) {
      return undefined
    }

    const simulationError =
      defaultSimulationError || feeOnTransferSimulationError

    const defaultErrorSummary = simulationError
      ? (simulationError as BaseError).shortMessage
      : undefined

    const isErrorDueToSlippageTooLow =
      simulationError?.message?.includes('LBRouter__InsufficientAmountOut') ||
      simulationError?.message?.includes(
        'RouterLogic__InsufficientAmountOut'
      ) ||
      simulationError.message.includes('0xc18a3e58') ||
      simulationError.message.includes('Router__InsufficientAmountReceived')

    const message = simulationError?.message
    const summary = defaultErrorSummary

    if (!message) {
      return undefined
    }

    return {
      message,
      summary: isErrorDueToSlippageTooLow
        ? t`Error: the slippage is too low for this trade.`
        : summary
    }
  }, [defaultSimulationError, feeOnTransferSimulationError])

  return {
    error,
    forceSwapAsync:
      !config && writeArgsFallback
        ? () => walletClient?.data?.writeContract(writeArgsFallback)
        : undefined,
    isSimulating:
      isEstimatingGas || isSimulatingDefault || isSimulatingFeeOnTransfer,
    isSwapping: isPending || isWaitingForTransaction,
    receipt,
    resetSwap: reset,
    swapAsync: config ? () => writeContractAsync(config.request) : undefined
  }
}

export default useSwapWithJoeAggregator
