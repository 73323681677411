import { ChainId } from '@traderjoe-xyz/sdk-core'
import { APTFarmABI } from '@traderjoe-xyz/sdk-v2'
import { MEME_FARMS } from 'pages/MemeFarms/constants'
import { useMemo } from 'react'
import { MemeFarm } from 'types/dexbarn'
import { getFarmAndPoolId } from 'utils/farm'
import { erc20Abi, formatUnits, getAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

interface UseGetMemeFarmByTokenAddressProps {
  tokenAddress?: string
}

const useGetMemeFarmByTokenAddress = ({
  tokenAddress
}: UseGetMemeFarmByTokenAddressProps) => {
  const chainId = ChainId.AVALANCHE
  const account = useAccount()

  const memeFarm = useMemo(() => {
    return MEME_FARMS.find(
      (farm) => farm.memeToken.tokenAddress === tokenAddress
    )
  }, [tokenAddress])

  const farmInfo = memeFarm ? getFarmAndPoolId(memeFarm.memeFarmId) : undefined

  const {
    data: userStakedAmount,
    isLoading: isLoadingUserStakedAmount,
    refetch: refetchUserStakedAmount
  } = useReadContract({
    abi: APTFarmABI,
    address: farmInfo ? getAddress(farmInfo.farmAddress) : undefined,
    args:
      farmInfo && account.address
        ? [BigInt(farmInfo.poolId), account.address]
        : undefined,
    chainId,
    functionName: 'userInfo',
    query: {
      enabled: !!account && !!memeFarm,
      select: (result) => {
        return result?.amount
      }
    }
  })

  const { data: stakedAmount, isLoading: isLoadingStakedAmount } =
    useReadContract({
      abi: erc20Abi,
      address: memeFarm
        ? getAddress(memeFarm.memeToken.tokenAddress)
        : undefined,
      args: farmInfo ? [getAddress(farmInfo.farmAddress)] : undefined,
      chainId,
      functionName: 'balanceOf',
      query: {
        enabled: !!account && !!memeFarm
      }
    })

  const data = useMemo(() => {
    if (!memeFarm) return undefined

    const stakedRaw = stakedAmount
    const userStakedRaw = userStakedAmount

    return {
      ...memeFarm,
      staked:
        stakedRaw !== undefined
          ? formatUnits(stakedRaw, memeFarm.memeToken.decimals)
          : undefined,
      stakedRaw,
      userStaked:
        userStakedRaw !== undefined
          ? formatUnits(userStakedRaw, memeFarm.memeToken.decimals)
          : undefined,
      userStakedRaw
    } satisfies MemeFarm
  }, [memeFarm, userStakedAmount, stakedAmount])

  return {
    data,
    isLoadingStakedAmount,
    isLoadingUserStakedAmount,
    refetchUserStakedAmount
  }
}

export default useGetMemeFarmByTokenAddress
