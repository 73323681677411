import { Swap, Token } from 'types/router'

export const traverseRoute = (token: Token): Swap[][] => {
  if (!token.swaps || token.swaps.length === 0) {
    return [[]]
  }

  const paths: Swap[][] = []

  for (const swap of token.swaps) {
    const subPaths = traverseRoute(swap.tokenOut)
    if (subPaths.length === 0) {
      paths.push([swap])
    } else {
      for (const subPath of subPaths) {
        paths.push([swap, ...subPath])
      }
    }
  }

  return paths
}
