import { useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { useJoebarnGet } from 'hooks/useJoebarn'
import { CollectionItem } from 'types/joebarn'

export const useGetPopularItems = () => {
  const fetchPopularItems = useJoebarnGet(
    `/v3/collections/trending`,
    ChainId.AVALANCHE
  )

  return useQuery<CollectionItem[]>({
    queryFn: () => fetchPopularItems(),
    queryKey: ['TrendingCollections'],
    select: (data: CollectionItem[]) =>
      data.map((collection) => {
        const newCollection = { ...collection }
        newCollection.floorPrice = BigInt(collection.floorPrice ?? '0')
        newCollection.volume7d = BigInt(collection.volume7d ?? '0')
        return newCollection
      })
  })
}
