import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { LBRewarderABI } from '@traderjoe-xyz/sdk-v2'
import { useDexbarnPost } from 'hooks/useDexbarn'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { GetProofParams } from 'types/rewards'
import { getDexbarnChainParam } from 'utils/chains'
import { getAddress, isAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

export type UseLBPairClaimRewardsArg = {
  amount: string
  epoch: number
  market: string
  tokenAddress: string
}

interface UseLBPairClaimRewardsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  rewarderAddress: string
  claimableRewards?: {
    amount: string
    epoch: number
    market: string
    tokenAddress: string
  }[]
}

const useLBPairClaimRewards = ({
  chainId,
  claimableRewards,
  rewarderAddress
}: UseLBPairClaimRewardsProps) => {
  const { address: account } = useAccount()

  const chain = getDexbarnChainParam(chainId)

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // params for Rewarder API
  const proofsParams: GetProofParams[] | undefined = useMemo(() => {
    if (!claimableRewards || !account || claimableRewards.length === 0) {
      return undefined
    }

    const proofsParams: GetProofParams[] = []

    claimableRewards.forEach((reward) => {
      proofsParams.push({
        epoch: reward.epoch,
        market: reward.market,
        token: reward.tokenAddress
      })
    })

    return proofsParams
  }, [claimableRewards, account])

  const fetchMerkleProofs = useDexbarnPost(
    `/v1/rewards/batch-proof/${chain}/${account}`,
    chainId,
    {
      batch: proofsParams
    }
  )

  // fetch merkleProof using Rewarder API
  const { data: merkleProofs } = useQuery<`0x${string}`[][]>({
    enabled: !!claimableRewards && !!account && !!proofsParams,
    queryFn: () => fetchMerkleProofs(),
    queryKey: ['MerkleProofs', account, proofsParams],
    retry: 0
  })

  const isEnabled = !!account && !!merkleProofs && !!proofsParams

  const { data: config } = useSimulateContract({
    abi: LBRewarderABI,
    address: getAddress(rewarderAddress),
    args: isEnabled
      ? [
          proofsParams.flatMap((param, index) => {
            const { epoch, market, token } = param
            const reward = claimableRewards?.find(
              (r) =>
                r.market === market &&
                r.tokenAddress === token &&
                r.epoch === epoch
            )

            if (
              reward &&
              merkleProofs.length > index &&
              isAddress(market) &&
              isAddress(token)
            ) {
              return [
                {
                  amount: BigInt(reward.amount),
                  epoch: BigInt(epoch),
                  market,
                  merkleProof: merkleProofs[index],
                  token,
                  user: account
                }
              ]
            }
            return []
          })
        ]
      : undefined,
    chainId,
    functionName: 'batchClaim',
    query: {
      enabled: isEnabled,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const { isPending, writeContractAsync } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Claim rewards`
        addRecentTransaction({ description, hash })
        addTransactionToast({ description, hash })
      }
    }
  })

  const batchClaimRewards = config?.request
    ? () => writeContractAsync(config.request)
    : undefined

  return { batchClaimRewards, isLoading: isPending }
}

export default useLBPairClaimRewards
