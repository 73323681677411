import ArbitrumIcon from 'assets/arbitrum.svg'
import AvalancheIcon from 'assets/avalanche.svg'
import BNBChainIcon from 'assets/bnb-chain.svg'
import EthereumIcon from 'assets/ethereum.svg'
import {
  arbitrum as wagmiArbitrum,
  arbitrumGoerli as wagmiArbitrumGoerli,
  avalanche as wagmiAvalanche,
  avalancheFuji as wagmiAvalancheFuji,
  bsc as wagmiBsc,
  bscTestnet as wagmiBscTestnet,
  Chain,
  mainnet as wagmiMainnet
} from 'wagmi/chains'

interface JoeChain {
  iconUrl: string
  slug: string
  isDeprecated?: boolean
}

const avalanche: Chain & JoeChain = {
  ...wagmiAvalanche,
  blockExplorers: {
    default: {
      apiUrl: 'https://api.snowscan.xyz/api',
      name: 'SnowScan',
      url: 'https://snowscan.xyz'
    }
  },
  iconUrl: AvalancheIcon,
  slug: 'avalanche'
}

const fuji: Chain & JoeChain = {
  ...wagmiAvalancheFuji,
  blockExplorers: {
    default: {
      apiUrl: 'https://api-testnet.snowscan.xyz/api',
      name: 'SnowScan',
      url: 'https://testnet.snowscan.xyz'
    }
  },
  iconUrl: AvalancheIcon,
  slug: 'fuji'
}

const arbitrum: Chain & JoeChain = {
  ...wagmiArbitrum,
  iconUrl: ArbitrumIcon,
  name: 'Arbitrum',
  slug: 'arbitrum'
}

const arbitrumGoerli: Chain & JoeChain = {
  ...wagmiArbitrumGoerli,
  iconUrl: ArbitrumIcon,
  slug: 'arbitrum-goerli'
}

const bsc: Chain & JoeChain = {
  ...wagmiBsc,
  iconUrl: BNBChainIcon,
  isDeprecated: true,
  name: 'BNB Chain',
  slug: 'bnb-chain'
}

const bscTestnet: Chain & JoeChain = {
  ...wagmiBscTestnet,
  iconUrl: BNBChainIcon,
  isDeprecated: true,
  name: 'BNB Testnet',
  slug: 'bnb-testnet'
}

const mainnet: Chain & JoeChain = {
  ...wagmiMainnet,
  iconUrl: EthereumIcon,
  isDeprecated: true,
  slug: 'ethereum'
}

export const supportedChains = [
  avalanche,
  fuji,
  arbitrum,
  arbitrumGoerli,
  bsc,
  bscTestnet,
  mainnet
] as const
