import { Flex, SimpleGrid, Stat, StatLabel, StatNumber } from '@chakra-ui/react'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import React from 'react'
import { LBPoolRewards } from 'types/pool'
import { formattedNum } from 'utils/format'
import { wrappedCurrency } from 'utils/wrappedCurrency'

interface RewardingDetailsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  rewards: LBPoolRewards
}

const RewardingDetails = ({
  chainId,
  rewards: { apr, rewards }
}: RewardingDetailsProps) => {
  const rewardsTokenAddresses = rewards.map(
    (reward) => reward.rewardToken?.address
  )
  const { tokens: sdkCurrencies } = useSdkCurrencies({
    addresses: rewardsTokenAddresses,
    chainId
  })
  const sdkTokens = sdkCurrencies.map((sdkCurrency) =>
    wrappedCurrency(sdkCurrency)
  )

  return (
    <SimpleGrid columns={{ base: 2, lg: 2, md: 3, sm: 3, xl: 3 }} gap={2}>
      <Stat>
        <StatLabel>{`Rewarded Token${
          rewards.length > 1 ? 's' : ''
        }`}</StatLabel>
        <Flex mt={1} flexWrap="wrap">
          {sdkTokens.map((sdkToken, i) => (
            <Flex flexDir="row" gap={1} mr={4} key={i} alignItems="center">
              <CurrencyLogo
                address={sdkToken?.address}
                symbol={sdkToken?.symbol}
                boxSize={5}
              />
              <StatNumber fontSize={20}>{sdkToken?.symbol}</StatNumber>
            </Flex>
          ))}
        </Flex>
      </Stat>

      <Stat>
        <StatLabel>Rewards per day</StatLabel>
        <Flex mt={1} flexWrap="wrap">
          {rewards?.map((reward, index) => (
            <StatNumber key={index} mr={4} fontSize={20}>
              {`${formattedNum(Number(reward.rewardPerDay))} ${sdkTokens[index]
                ?.symbol}`}
            </StatNumber>
          ))}
        </Flex>
      </Stat>

      <Stat sx={{ display: 'flex' }}>
        <StatLabel>Rewards APR (24H)</StatLabel>
        <StatNumber fontSize={20}>
          {`${formattedNum(apr * 100, { places: 2 })}%`}
        </StatNumber>
      </Stat>
    </SimpleGrid>
  )
}

export default RewardingDetails
