import PoolDetailHeader from 'pages/PoolDetail/PoolDetailHeader'
import React, { useMemo } from 'react'
import { Point } from 'types/pool'
import { computeAndParsePriceFromBin } from 'utils/prices'
import { wrappedCurrency } from 'utils/wrappedCurrency'

import { useLBPairDataContext, usePoolDetailV2Context } from './state'

interface PoolDetailV2HeaderProps {
  isRewarded: boolean
  points: Point[]
}

const PoolDetailV2Header = ({
  isRewarded,
  points
}: PoolDetailV2HeaderProps) => {
  const {
    chainId,
    currency0,
    currency1,
    inversePriceRatios,
    pool,
    togglePriceRatios
  } = usePoolDetailV2Context()
  const { activeBinId, baseFee, binStep, lbPairAddress } =
    useLBPairDataContext()

  // calculate active bin price
  const activeBinPrice = useMemo((): string | undefined => {
    if (!activeBinId) return undefined
    const wrappedCurrency0 = wrappedCurrency(currency0)
    const wrappedCurrency1 = wrappedCurrency(currency1)
    if (!wrappedCurrency0 || !wrappedCurrency1 || !binStep) {
      return undefined
    }
    const price = computeAndParsePriceFromBin(
      activeBinId,
      binStep,
      wrappedCurrency0,
      wrappedCurrency1,
      wrappedCurrency1?.decimals
    )
    return inversePriceRatios ? (1 / Number(price)).toFixed(18) : price
  }, [activeBinId, binStep, currency0, currency1, inversePriceRatios])

  return (
    <PoolDetailHeader
      points={points}
      chainId={chainId}
      address={lbPairAddress}
      token0={{
        address: currency0?.isToken ? currency0.address : undefined,
        symbol: currency0?.isNative
          ? currency0?.symbol
          : pool?.tokenX.symbol ?? currency0?.symbol
      }}
      token1={{
        address: currency1?.isToken ? currency1.address : undefined,
        symbol: currency1?.isNative
          ? currency1?.symbol
          : pool?.tokenY.symbol ?? currency0?.symbol
      }}
      tag={`${baseFee} bps`}
      isRewarded={isRewarded}
      slippageSettingsPickerType="poolV2"
      px={4}
      inversePriceRatios={inversePriceRatios}
      onInversePriceRatiosClick={togglePriceRatios}
      activeBinPrice={activeBinPrice}
      mb={{ base: 4, md: 0 }}
      showHotkeyTooltip
    />
  )
}

export default PoolDetailV2Header
