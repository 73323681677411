import { ChainId } from '@traderjoe-xyz/sdk-core'
import { APTFarmABI } from '@traderjoe-xyz/sdk-v2'
import { MEME_FARMS } from 'pages/MemeFarms/constants'
import { useMemo } from 'react'
import { MemeFarm } from 'types/dexbarn'
import { getFarmAndPoolId } from 'utils/farm'
import { erc20Abi, formatUnits, getAddress } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

const useGetMemeFarms = () => {
  const chainId = ChainId.AVALANCHE
  const account = useAccount()

  const { data: userStakedAmounts, isLoading: isLoadingUserStakedAmounts } =
    useReadContracts({
      contracts: MEME_FARMS.map((farm) => {
        const farmInfo = getFarmAndPoolId(farm.memeFarmId)
        return {
          abi: APTFarmABI,
          address: getAddress(farmInfo.farmAddress),
          args: [farmInfo.poolId, account.address],
          chainId,
          functionName: 'userInfo'
        } as const
      }),
      query: {
        enabled: !!account,
        select: (results) => {
          return results.map((result) => result.result?.amount)
        }
      }
    })

  const { data: stakedAmounts, isLoading: isLoadingStakedAmounts } =
    useReadContracts({
      contracts: MEME_FARMS.map((farm) => {
        const farmInfo = getFarmAndPoolId(farm.memeFarmId)
        return {
          abi: erc20Abi,
          address: getAddress(farm.memeToken.tokenAddress),
          args: [getAddress(farmInfo.farmAddress)],
          chainId,
          functionName: 'balanceOf'
        } as const
      }),
      query: {
        select: (results) => {
          return results.map((result) => result.result)
        }
      }
    })

  const data = useMemo(() => {
    return MEME_FARMS.map((farm, i) => {
      const stakedRaw = stakedAmounts?.[i]
      const userStakedRaw = userStakedAmounts?.[i]
      return {
        ...farm,
        staked:
          stakedRaw !== undefined
            ? formatUnits(stakedRaw, farm.memeToken.decimals)
            : undefined,
        stakedRaw,
        userStaked:
          userStakedRaw !== undefined
            ? formatUnits(userStakedRaw, farm.memeToken.decimals)
            : undefined,
        userStakedRaw
      } satisfies MemeFarm
    }).sort((a, b) => {
      const aValue = a.userStaked ? parseFloat(a.userStaked) : 0
      const bValue = b.userStaked ? parseFloat(b.userStaked) : 0
      return bValue - aValue
    })
  }, [userStakedAmounts, stakedAmounts])

  return {
    data,
    isLoadingStakedAmounts,
    isLoadingUserStakedAmounts
  }
}

export default useGetMemeFarms
