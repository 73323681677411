import { ChainId } from '@traderjoe-xyz/sdk-core'
import { LBPairABI, LBPairV21ABI } from '@traderjoe-xyz/sdk-v2'
import { ALL_MARKETS_BY_CHAIN } from 'constants/makerRewards'
import { UserRewardRowProps } from 'pages/Pool/UserPositions/UserRewardRow'
import { useMemo } from 'react'
import { LBPoolVersion } from 'types/pool'
import { erc20Abi, formatUnits, getAddress, Hex } from 'viem'
import { useReadContracts } from 'wagmi'

import useAllMarketsClaimable from './v2/useAllMarketsClaimable'

interface UseUserMakerRewardRowsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  enabled: boolean
}

const useUserMakerRewardRows = ({
  chainId,
  enabled
}: UseUserMakerRewardRowsProps) => {
  // fetch claimable maker rewards for all markets
  const {
    data: allClaimableMakerRewards = [],
    isLoading: isLoadingAllMarkets
  } = useAllMarketsClaimable({
    chainId,
    enabled,
    markets: ALL_MARKETS_BY_CHAIN[chainId]
  })

  // group rewards
  const claimableMakerRewards = useMemo(() => {
    const results: {
      claimableRewards: {
        amount: bigint
        tokenAddress: string
      }[]
      market: string
    }[] = []
    allClaimableMakerRewards.forEach((rewardInfo) => {
      const poolAddress = rewardInfo.market

      const index = results.findIndex((result) => result.market === poolAddress)
      if (index >= 0) {
        rewardInfo.claimableRewards.forEach((claimableReward) => {
          const rewardIndex = results[index].claimableRewards.findIndex(
            (reward) => claimableReward.tokenAddress === reward.tokenAddress
          )

          if (rewardIndex >= 0) {
            results[index].claimableRewards[rewardIndex].amount += BigInt(
              claimableReward.amount
            )
          } else {
            results[index].claimableRewards.push({
              amount: BigInt(claimableReward.amount),
              tokenAddress: claimableReward.tokenAddress
            })
          }
        })
      } else {
        results.push({
          claimableRewards: rewardInfo.claimableRewards.map(
            (claimableReward) => {
              return {
                amount: BigInt(claimableReward.amount),
                tokenAddress: claimableReward.tokenAddress
              }
            }
          ),
          market: poolAddress
        })
      }
    })

    return results
  }, [allClaimableMakerRewards])

  // get all reward token addresses
  const rewardTokenAddresses = useMemo(
    () =>
      claimableMakerRewards
        .map((rewardInfo) =>
          rewardInfo.claimableRewards.map((claimableReward) =>
            getAddress(claimableReward.tokenAddress)
          )
        )
        .flat(),
    [claimableMakerRewards]
  )

  // fetch LB pair info for each claimable reward
  // we assume the pools can be v2 or v21
  const { data, isLoading: isLoadingLbPairInfo } = useReadContracts({
    contracts: [
      ...claimableMakerRewards.map((rewardInfo) => ({
        abi: LBPairV21ABI,
        address: getAddress(rewardInfo.market),
        chainId,
        functionName: 'getTokenX'
      })),
      ...claimableMakerRewards.map((rewardInfo) => ({
        abi: LBPairABI,
        address: getAddress(rewardInfo.market),
        chainId,
        functionName: 'tokenX'
      })),
      ...claimableMakerRewards.map((rewardInfo) => ({
        abi: LBPairV21ABI,
        address: getAddress(rewardInfo.market),
        chainId,
        functionName: 'getTokenY'
      })),
      ...claimableMakerRewards.map((rewardInfo) => ({
        abi: LBPairABI,
        address: getAddress(rewardInfo.market),
        chainId,
        functionName: 'tokenY'
      })),
      ...claimableMakerRewards.map((rewardInfo) => ({
        abi: LBPairV21ABI,
        address: getAddress(rewardInfo.market),
        chainId,
        functionName: 'getBinStep'
      })),
      ...claimableMakerRewards.map((rewardInfo) => ({
        abi: LBPairABI,
        address: getAddress(rewardInfo.market),
        chainId,
        functionName: 'feeParameters'
      }))
    ],
    query: {
      enabled: enabled && claimableMakerRewards.length > 0,
      select: (data) => {
        const tokenXAddresses: (Hex | undefined)[] = []
        const tokenYAddresses: (Hex | undefined)[] = []
        const binSteps: (number | undefined)[] = []
        const versions: LBPoolVersion[] = []

        const length = claimableMakerRewards.length

        for (let i = 0; i < length; i++) {
          // TokenX
          if (data[i].status === 'success') {
            tokenXAddresses.push(data[i].result as Hex | undefined)
          } else {
            tokenXAddresses.push(data[i + length].result as Hex | undefined)
          }

          // TokenY
          if (data[i + length * 2].status === 'success') {
            tokenYAddresses.push(data[i + length * 2].result as Hex | undefined)
          } else {
            tokenYAddresses.push(data[i + length * 3].result as Hex | undefined)
          }

          // BinStep
          if (data[i + length * 4].status === 'success') {
            binSteps.push(data[i + length * 4].result as number | undefined)
            versions[i] = 'v21'
          } else {
            const feeParams = data[i + length * 5].result as { binStep: number }
            binSteps.push(feeParams?.binStep)
            versions[i] = 'v2'
          }
        }

        return {
          binSteps,
          tokenXAddresses,
          tokenYAddresses,
          versions
        }
      }
    }
  })
  const { binSteps, tokenXAddresses, tokenYAddresses, versions } = data || {}

  // fetch symbols and decimals
  const { data: tokenInfo, isLoading: isLoadingTokenInfo } = useReadContracts({
    contracts: [
      ...(tokenXAddresses?.map((address) => ({
        abi: erc20Abi,
        address,
        chainId,
        functionName: 'symbol'
      })) || []),
      ...(tokenYAddresses?.map((address) => ({
        abi: erc20Abi,
        address,
        chainId,
        functionName: 'symbol'
      })) || []),
      ...(rewardTokenAddresses?.map((address) => ({
        abi: erc20Abi,
        address,
        chainId,
        functionName: 'symbol'
      })) || []),
      ...(rewardTokenAddresses?.map((address) => ({
        abi: erc20Abi,
        address,
        chainId,
        functionName: 'decimals'
      })) || [])
    ],
    query: {
      enabled:
        enabled &&
        !!tokenXAddresses &&
        tokenXAddresses.length > 0 &&
        !!tokenYAddresses &&
        tokenYAddresses.length > 0 &&
        !!rewardTokenAddresses &&
        rewardTokenAddresses.length > 0,
      select: (data) => {
        const tokenXLength = tokenXAddresses?.length || 0
        const tokenYLength = tokenYAddresses?.length || 0

        const tokenXSymbols = data
          .slice(0, tokenXLength)
          .map(({ result }) => result as string | undefined)

        const tokenYSymbols = data
          .slice(tokenXLength, tokenXLength + tokenYLength)
          .map(({ result }) => result as string | undefined)

        const rewardTokenSymbols = data
          .slice(
            tokenXLength + tokenYLength,
            tokenXLength + tokenYLength + rewardTokenAddresses.length
          )
          .map(({ result }) => result as string | undefined)

        const rewardTokenDecimals = data
          .slice(tokenXLength + tokenYLength + rewardTokenAddresses.length)
          .map(({ result }) => result as number | undefined)

        return {
          rewardTokenDecimals,
          rewardTokenSymbols,
          tokenXSymbols,
          tokenYSymbols
        }
      }
    }
  })
  const {
    rewardTokenDecimals,
    rewardTokenSymbols,
    tokenXSymbols,
    tokenYSymbols
  } = tokenInfo || {}

  // convert to rows
  const rows: UserRewardRowProps[] = useMemo(() => {
    if (!claimableMakerRewards) {
      return []
    }

    return claimableMakerRewards.map((rewardInfo, i) => {
      return {
        chainId,
        lbPair: {
          address: rewardInfo.market,
          binStep: binSteps?.[i] || 0,
          version: versions?.[i] || 'v21'
        },
        rewards: rewardInfo.claimableRewards.map((claimableReward, i) => {
          const decimals = rewardTokenDecimals?.[i] || 0
          return {
            amount: Number(formatUnits(claimableReward.amount, decimals)),
            tokenAddress: claimableReward.tokenAddress,
            tokenDecimals: decimals,
            tokenSymbol: rewardTokenSymbols?.[i] || ''
          }
        }),
        tokenX: {
          address: tokenXAddresses?.[i] || '',
          symbol: tokenXSymbols?.[i] || ''
        },
        tokenY: {
          address: tokenYAddresses?.[i] || '',
          symbol: tokenYSymbols?.[i] || ''
        }
      }
    })
  }, [
    claimableMakerRewards,
    tokenXAddresses,
    tokenYAddresses,
    tokenXSymbols,
    tokenYSymbols,
    rewardTokenSymbols,
    rewardTokenDecimals,
    binSteps,
    versions,
    chainId
  ])

  return {
    isLoading: isLoadingAllMarkets || isLoadingLbPairInfo || isLoadingTokenInfo,
    rows
  }
}

export default useUserMakerRewardRows
