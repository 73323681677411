import { ChainId, Currency } from '@traderjoe-xyz/sdk-core'
import useLBPairData from 'hooks/pool/v2/useLBPairData'
import usePoolDetailV2Params from 'hooks/pool/v2/usePoolDetailV2Params'
import usePoolV2 from 'hooks/pool/v2/usePoolV2'
import useChainIdFromUrlParam from 'hooks/useChainIdFromUrlParam'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useTokenBalance } from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState
} from 'react'
import { LBPool } from 'types/pool'
import { getCurrencyAddress } from 'utils/wrappedCurrency'
import { GetBalanceData } from 'wagmi/query'

import { AddLiquidityStateContextProvider } from './AddLiquidityStateContextProvider'

type PoolDetailV2ContextType = {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  inversePriceRatios: boolean
  isLoadingPoolV2: boolean
  refetchBalance0: () => void
  refetchBalance1: () => void
  togglePriceRatios: () => void
  balance0?: GetBalanceData
  balance1?: GetBalanceData
  currency0?: Currency
  currency1?: Currency
  pool?: LBPool
}

const PoolDetailV2Context = createContext<PoolDetailV2ContextType>(
  {} as PoolDetailV2ContextType
)

type LBPairDataContextType = {
  refetchReservesAndActiveBinId: () => void
  activeBinId?: number
  baseFee?: number
  binStep?: number
  isLoading?: boolean
  lbPairAddress?: string
  reserveX?: bigint
  reserveY?: bigint
}

const LBPairDataContext = createContext<LBPairDataContextType>(
  {} as LBPairDataContextType
)

export const PoolDetailV2ContextProvider = ({
  children
}: PropsWithChildren) => {
  const chainId = useChainIdFromUrlParam()!

  // get url params
  const {
    binStep,
    currencyId0,
    currencyId1,
    lbPoolVersion,
    token0Address,
    token1Address
  } = usePoolDetailV2Params()

  // fetch on-chain data
  const {
    activeBinId,
    feeParameters: { baseFee },
    isLoading,
    lbPairInfo,
    refetchReservesAndActiveBinId,
    reserveX,
    reserveY
  } = useLBPairData({
    binStep,
    chainId,
    token0Address,
    token1Address,
    version: lbPoolVersion
  })
  const lbPairAddress = lbPairInfo?.LBPair

  // fetch pool from dexbarn
  const { data: pool, isLoading: isLoadingPoolV2 } = usePoolV2({
    address: lbPairAddress,
    chainId
  })

  // fetch currencies
  const {
    tokens: [currency0, currency1]
  } = useSdkCurrencies({
    addresses: [currencyId0, currencyId1],
    chainId,
    // NOTE: override the symbol for the bridged USDC pools
    symbolByAddressOverrides:
      pool && pool.tokenX.address && pool.tokenY.address
        ? {
            [pool.tokenX.address]: pool.tokenX.symbol,
            [pool.tokenY.address]: pool.tokenY.symbol
          }
        : undefined
  })

  // balances
  const { data: balance0, refetch: refetchBalance0 } = useTokenBalance({
    chainId,
    token: getCurrencyAddress(currency0)
  })
  const { data: balance1, refetch: refetchBalance1 } = useTokenBalance({
    chainId,
    token: getCurrencyAddress(currency1)
  })
  const debouncedRefetchBalance0 = debounce(() => refetchBalance0(), 4000)
  const debouncedRefetchBalance1 = debounce(() => refetchBalance1(), 4000)

  // price ratio
  const [inversePriceRatios, setPriceRatiosInversed] = useState(false)
  const togglePriceRatios = () => setPriceRatiosInversed((prev) => !prev)

  return (
    <PoolDetailV2Context.Provider
      value={{
        balance0,
        balance1,
        chainId,
        currency0,
        currency1,
        inversePriceRatios,
        isLoadingPoolV2,
        pool,
        refetchBalance0: debouncedRefetchBalance0,
        refetchBalance1: debouncedRefetchBalance1,
        togglePriceRatios
      }}
    >
      <LBPairDataContext.Provider
        value={{
          activeBinId,
          baseFee: baseFee ? Math.round(baseFee * 200) / 200 : undefined, // round to nearest 0.005%
          binStep: lbPairInfo?.binStep,
          isLoading,
          lbPairAddress,
          refetchReservesAndActiveBinId,
          reserveX,
          reserveY
        }}
      >
        <AddLiquidityStateContextProvider activeBinId={activeBinId}>
          {children}
        </AddLiquidityStateContextProvider>
      </LBPairDataContext.Provider>
    </PoolDetailV2Context.Provider>
  )
}

export const usePoolDetailV2Context = () => useContext(PoolDetailV2Context)
export const useLBPairDataContext = () => useContext(LBPairDataContext)
