import { useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Chain, Vault as DexbarnVault } from 'types/dexbarn'
import { convertDexbarnVaultIntoVault } from 'utils/vault'

interface FetchVaultsParameters {
  userAddress?: string
}

interface UseVaultsProps {
  chain: Chain | 'all'
  chainId: Exclude<ChainId, ChainId.MANTLE>
  enabled?: boolean
  userAddress?: string
}

const useVaults = ({
  chain,
  chainId,
  enabled = true,
  userAddress
}: UseVaultsProps) => {
  const fetchVaults = useDexbarnGet<DexbarnVault[], FetchVaultsParameters>(
    chain === 'all' ? 'v1/vaults' : `v1/vaults/${chain}`,
    chainId
  )
  return useQuery({
    enabled,
    queryFn: () => fetchVaults({ params: { userAddress } }),
    queryKey: ['VaultsQuery', chainId, userAddress],
    select: (vaults) =>
      vaults.map((vault) => convertDexbarnVaultIntoVault(vault))
  })
}

export default useVaults
