import {
  Box,
  Button,
  Center,
  Heading,
  Hide,
  Show,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useLbPoolsWithRewards from 'hooks/pool/v2/useLbPoolsWithRewards'
import usePoolsV2 from 'hooks/pool/v2/usePoolsV2'
import React, { useMemo, useState } from 'react'
import { LBPool } from 'types/pool'

import BoostPoolMobileRow from './BoostPoolMobileRow'
import BoostPoolRow from './BoostPoolRow'

const BoostPoolsTable = () => {
  const chainId = ChainId.AVALANCHE
  const [showAllMobile, setShowAllMobile] = useState(false)

  const { data: lbPools, isLoading: isLoadingPools } = usePoolsV2({
    chainId,
    excludeLowVolumePools: false,
    orderBy: 'liquidity',
    pageSize: 100,
    status: 'main',
    version: 'v2.2'
  })

  const { isLoading: isLoadingRewards, lbPoolsWithRewards } =
    useLbPoolsWithRewards({
      chainId,
      pools: lbPools
    })

  const boostedPools = useMemo(() => {
    return lbPoolsWithRewards.filter(
      (pool) => pool.lbRewards.length > 0 && pool.points.includes('boost')
    ) as LBPool[]
  }, [lbPoolsWithRewards])

  const isLoading = isLoadingPools || isLoadingRewards

  const displayedPools = showAllMobile ? boostedPools : boostedPools.slice(0, 5)

  if (!isLoading && boostedPools.length === 0) {
    return null
  }

  return (
    <Box w="full">
      <VStack
        align="flex-start"
        spacing={{ base: 0, md: 2 }}
        px={{ base: 4, md: 0 }}
      >
        <Heading size="lg">
          <Trans>Boost Pools</Trans>
        </Heading>
        <Text textColor="textSecondary" fontSize="sm">
          <Trans>Provide liquidity and earn AVAX incentives</Trans>
        </Text>
      </VStack>

      <Box pt={{ base: 4, md: 6 }}>
        {isLoading ? (
          <Center h="120px">
            <Spinner />
          </Center>
        ) : boostedPools && boostedPools.length > 0 ? (
          <>
            <Hide below="md">
              <TableContainer
                bg="bgCard"
                borderRadius="2xl"
                border="1px solid"
                borderColor="border"
                p={4}
              >
                <Table variant="card">
                  <Thead>
                    <Tr>
                      <Th>
                        <Trans>Pool</Trans>
                      </Th>
                      <Th isNumeric>
                        <Trans>TVL</Trans>
                      </Th>
                      <Th isNumeric textAlign="right">
                        <Trans>Rewards / day</Trans>
                      </Th>
                      <Th
                        isNumeric
                        textDecor="dotted underline"
                        textAlign="right"
                      >
                        <TouchFriendlyTooltip label="The displayed APR shows the potential yield you could earn, depending on how well you manage your position. This reward APR is calculated from the reward dollar value relative to the liquidity within a +/-2% range projected over a year.">
                          <Trans>Rewards APR</Trans>
                        </TouchFriendlyTooltip>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {boostedPools.map((lbPool) => (
                      <BoostPoolRow
                        key={lbPool.pairAddress}
                        lbPool={lbPool}
                        chainId={chainId}
                      />
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Hide>

            <Show below="md">
              <TableContainer>
                <Table width="100%">
                  <Thead bg="bgSecondary">
                    <Tr>
                      <Th pr={4} py={2}>
                        Pool
                      </Th>
                      <Th px={0} py={2}>
                        Volume
                      </Th>
                      <Th px={0} pr={4} py={2} textAlign="right">
                        Rewards APR
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {displayedPools.map((lbPool, i) => {
                      return (
                        <BoostPoolMobileRow
                          key={i}
                          chainId={chainId}
                          lbPool={lbPool}
                        />
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
              {!showAllMobile && boostedPools.length > 5 && (
                <Button
                  onClick={() => setShowAllMobile(true)}
                  w="full"
                  variant="ghost"
                >
                  View All Pools
                </Button>
              )}
            </Show>
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default BoostPoolsTable
