import { keepPreviousData } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { LB_HOOKS_LENS_ADDRESS, LBHooksLensABI } from '@traderjoe-xyz/sdk-v2'
import { getLbPoolRewards, getUserClaimableRewards } from 'utils/lbRewards'
import { getAddress, zeroAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

interface UseGetBatchLbRewarderDataProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  pairs: {
    binStep: number
    currency0Decimals: number
    currency1Decimals: number
    ids: string[]
    pairAddress: string
  }[]
}

const useGetBatchLbRewarderData = ({
  chainId,
  pairs
}: UseGetBatchLbRewarderDataProps) => {
  const { address: account } = useAccount()

  return useReadContract({
    abi: LBHooksLensABI,
    address: LB_HOOKS_LENS_ADDRESS[chainId],
    args: [
      pairs.map(({ pairAddress }) => getAddress(pairAddress)),
      account || zeroAddress,
      pairs.map(({ ids }) => ids.map((id) => BigInt(id)))
    ],
    chainId,
    functionName: 'getBatchHooksData',
    query: {
      enabled: pairs.length > 0,
      placeholderData: keepPreviousData,
      select: (results) => {
        const rewarderDatas = results[0]
        const extraRewarderDatas = results[1]

        const data = rewarderDatas.map((rewarderData, index) => {
          const extraRewarderData = extraRewarderDatas[index]
          const pair = pairs[index]

          const rewards = getLbPoolRewards({
            chainId,
            currency0Decimals: pair.currency0Decimals,
            currency1Decimals: pair.currency1Decimals,
            extraRewarderData,
            lbBinStep: pair.binStep,
            rewarderData
          })

          const userClaimableRewards = getUserClaimableRewards({
            chainId,
            extraRewarderData,
            rewarderData
          })

          return {
            extraRewarderData,
            rewarderData,
            rewards,
            userClaimableRewards
          }
        })

        const rewarderDataPerPairAddress: Record<string, (typeof data)[0]> = {}
        pairs.forEach(({ pairAddress }, index) => {
          rewarderDataPerPairAddress[pairAddress] = data[index]
        })
        return rewarderDataPerPairAddress
      }
    }
  })
}

export default useGetBatchLbRewarderData
