import { Flex, Heading } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import PageHelmet from 'components/PageHelmet'
import { POOL_HELMET_DESCRIPTION, POOL_HELMET_TITLE } from 'constants/pool'
import usePoolV1LiquidityPanel from 'hooks/pool/v1/usePoolV1LiquidityPanel'
import useChainIdFromUrlParam from 'hooks/useChainIdFromUrlParam'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import AddLiquidityPanel from 'pages/PoolDetail/AddLiquidityPanel'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import CreatePoolPriceDetail from './CreatePoolPriceDetail'

interface CreatePoolProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
}

const CreatePool = ({ chainId }: CreatePoolProps) => {
  const { token0Address, token1Address } = useParams()
  const location = useLocation()

  const { isLoading, onToken0Select, onToken1Select, pair, token0, token1 } =
    usePoolV1LiquidityPanel({
      chainId,
      token0Address,
      token1Address
    })

  const amountInput0 = useCurrencyInputAmount({ currency: token0 })
  const amountInput1 = useCurrencyInputAmount({ currency: token1 })

  return (
    <>
      <PageHelmet
        title={POOL_HELMET_TITLE}
        description={POOL_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <Flex
        justify="center"
        pt={{ base: 6, md: 12 }}
        maxW="1200px"
        margin="0 auto"
        minH="calc(100vh - 80px)"
        px={4}
      >
        <Flex
          flexDir="column"
          w={{ base: 'full', md: '400px' }}
          h="fit-content"
          gap={4}
          p={4}
          borderRadius="xl"
          border="1px"
          borderColor="border"
          bg="bgCard"
        >
          <Heading size="md">
            <Trans>Add Liquidity</Trans>
          </Heading>
          <AddLiquidityPanel
            isPoolLowLiquidity={false}
            chainId={chainId}
            pair={pair}
            token0={token0}
            token1={token1}
            amountInput0={amountInput0}
            amountInput1={amountInput1}
            onToken0Select={onToken0Select}
            onToken1Select={onToken1Select}
            loadingText={isLoading ? t`Checking Pair` : undefined}
          />
          {token0 && token1 ? (
            <CreatePoolPriceDetail
              chainId={chainId}
              currency0={token0}
              currency1={token1}
              currency0Amount={amountInput0.amountBN}
              currency1Amount={amountInput1.amountBN}
            />
          ) : null}
        </Flex>
      </Flex>
    </>
  )
}

const CreatePoolWrapper = () => {
  const chainId = useChainIdFromUrlParam()

  if (!chainId) {
    return null
  }

  return <CreatePool chainId={chainId} />
}

export default CreatePoolWrapper
