import { Flex, FlexProps, Grid, HStack, Text } from '@chakra-ui/react'
import { ChainId, CNATIVE } from '@traderjoe-xyz/sdk-core'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Token } from 'types/pool'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

interface PoolCardProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  columns: {
    title: string
    value: string
    valueTextColor?: string
  }[]
  href: string
  token0: Token
  token1: Token
  bottomContent?: JSX.Element
  isLabelsColumn?: boolean
  tags?: (JSX.Element | null)[]
  topContent?: JSX.Element
}

const PoolCard = ({
  bottomContent,
  chainId,
  columns,
  href,
  isLabelsColumn = false,
  tags,
  token0,
  token1,
  topContent,
  ...props
}: PoolCardProps & FlexProps): JSX.Element => {
  const nativeCurrency = CNATIVE.onChain(chainId)

  const isToken0WrappedNative = isWrappedNativeCurrency(token0.address, chainId)
  const isToken1WrappedNative = isWrappedNativeCurrency(token1.address, chainId)

  const address0 = isToken0WrappedNative ? undefined : token0.address
  const address1 = isToken1WrappedNative ? undefined : token1.address

  const symbol0 = isToken0WrappedNative ? nativeCurrency.symbol : token0.symbol
  const symbol1 = isToken1WrappedNative ? nativeCurrency.symbol : token1.symbol

  const navigate = useNavigate()

  return (
    <Flex
      aria-label={`${token0.symbol}-${token1.symbol} liquidity pool`}
      flexDir="column"
      bg="bgCard"
      borderColor="border"
      borderWidth={1}
      borderRadius="xl"
      p={4}
      cursor="pointer"
      onClick={() => navigate(href)}
      {...props}
    >
      {topContent ? topContent : null}
      <HStack spacing={2}>
        <DoubleCurrencyLogo
          address0={address0}
          address1={address1}
          symbol0={symbol0}
          symbol1={symbol1}
          boxSize={7}
          ml="-2"
        />
        <Text fontWeight="semibold" fontSize="md">
          {symbol0}-{symbol1}
        </Text>
        {tags ? tags : null}
      </HStack>
      {isLabelsColumn ? (
        <Grid
          templateColumns="minmax(0, 1fr) minmax(0, 1.5fr)"
          rowGap={1}
          mt={4}
        >
          {columns.map((column, i) => (
            <React.Fragment key={i}>
              <Text color="textSecondary" fontSize="sm" mb={1}>
                {column.title}
              </Text>
              <Text
                color={column.valueTextColor || 'textPrimary'}
                fontSize="sm"
                fontWeight="semibold"
              >
                {column.value}
              </Text>
            </React.Fragment>
          ))}
        </Grid>
      ) : (
        <HStack justify="space-between" mt={4}>
          {columns.map((column, i) => (
            <Flex flexDir="column" align="left" key={i}>
              <Text color="textSecondary" fontSize="sm" mb={1}>
                {column.title}
              </Text>
              <Text color="textPrimary" fontSize="sm" fontWeight="semibold">
                {column.value}
              </Text>
            </Flex>
          ))}
        </HStack>
      )}
      {bottomContent && bottomContent}
    </Flex>
  )
}

export default PoolCard
