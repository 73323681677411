import {
  Button,
  Center,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import useUserLbRewardRows from 'hooks/pool/useUserLbRewardRows'
import useUserMakerRewardRows from 'hooks/pool/useUserMakerRewardRows'
import React from 'react'
import { useAccount } from 'wagmi'

import UserRewardRow from './UserRewardRow'

interface UserRewardsTableProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  isTabSelected: boolean
}

const UserRewardsTable = ({
  chainId,
  isTabSelected
}: UserRewardsTableProps) => {
  const { isConnected } = useAccount()
  const { openConnectModal } = useConnectModal()

  const makerRewards = useUserMakerRewardRows({
    chainId,
    enabled: isTabSelected
  })

  const lbRewards = useUserLbRewardRows({
    chainId,
    enabled: isTabSelected
  })

  if (!isConnected) {
    return (
      <Center flexDir="column" gap={4} h="200px">
        <Text>
          <Trans>Connect your wallet to see your rewards.</Trans>
        </Text>
        <Button
          variant="primary"
          colorScheme="accent"
          onClick={openConnectModal}
        >
          <Trans>Connect Wallet</Trans>
        </Button>
      </Center>
    )
  }

  if (makerRewards.isLoading || lbRewards.isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  const rows = [...lbRewards.rows, ...makerRewards.rows]

  return (
    <Flex flexDir="column" gap={{ base: 8, md: 8 }}>
      {rows.length > 0 ? (
        <TableContainer
          bg="bgCard"
          borderRadius="2xl"
          border="1px solid"
          borderColor="border"
          p={4}
        >
          <Table variant="card">
            <Thead>
              <Tr>
                <Th>
                  <Trans>Pool name</Trans>
                </Th>
                <Th textAlign="right">
                  <Trans>Rewards</Trans>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {rows.map((row, i) => (
                <UserRewardRow key={`${row.lbPair.address}-${i}`} {...row} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : null}
      {rows.length === 0 ? (
        <Center py={8}>
          <Text size="sm" color="textSecondary">
            <Trans>You don&#39;t have any rewards.</Trans>
          </Text>
        </Center>
      ) : null}
    </Flex>
  )
}

export default UserRewardsTable
