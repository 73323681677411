import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  Heading,
  Hide,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Spacer,
  Spinner,
  Table,
  TableContainer,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { ChainId, Currency } from '@traderjoe-xyz/sdk-core'
import SortableTableHeader from 'components/SortableTableHeader'
import { format } from 'date-fns'
import usePoolLeaderboard, {
  PoolLeaderboardSortMethod
} from 'hooks/pool/usePoolLeaderboard'
import usePoolDetailV2Params from 'hooks/pool/v2/usePoolDetailV2Params'
import { Epoch, useFetchEpochs } from 'hooks/useEpochRewards'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { LeaderboardData } from 'types/leaderboard'
import { LBPairUserBalances } from 'types/poolV2'
import { convertPositionToAddLiquidityParams } from 'utils/convertPositionToAddLiquidityParams'
import { useAccount } from 'wagmi'

import {
  useAddLiquidityDispatchContext,
  usePoolDetailV2Context
} from '../state'
import LeaderboardRow from './LeaderboardRow'
import LeaderboardRowMobile from './LeaderboardRowMobile'

interface LeaderboardProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  isTabSelected: boolean
  onDisplayAddLiquidityPanel: () => void
  activeBinId?: number
  currency0?: Currency
  currency1?: Currency
  lbPairAddress?: string
}

const Leaderboard = ({
  activeBinId,
  chainId,
  currency0,
  currency1,
  isTabSelected,
  lbPairAddress,
  onDisplayAddLiquidityPanel
}: LeaderboardProps) => {
  const { isConnected } = useAccount()
  const { openConnectModal } = useConnectModal()

  const { binStep, lbPoolVersion } = usePoolDetailV2Params()
  const dispatchAddLiquidityAction = useAddLiquidityDispatchContext()
  const { balance0, balance1 } = usePoolDetailV2Context()

  const { data: _epochs, isLoading: isLoadingEpochs } = useFetchEpochs()
  const epochs = useMemo(() => {
    if (!_epochs) {
      return undefined
    }
    return [..._epochs]
      .reverse()
      .filter((epoch) => new Date(epoch.start) < new Date())
      .slice(0, 5)
  }, [_epochs])
  const [selectedEpoch, setSelectedEpoch] = useState<Epoch | undefined>()
  useEffect(() => {
    if (epochs && epochs.length > 0) {
      setSelectedEpoch(epochs[0])
    }
  }, [epochs])

  const [sortMethod, setSortMethod] =
    useState<PoolLeaderboardSortMethod>('volume')
  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)

  const [expandedRows, setExpandedRows] = useState<number[]>([])
  const toggleExpandedRow = useCallback(
    (rowId: number) => {
      if (expandedRows.includes(rowId)) {
        setExpandedRows((previous) => previous.filter((id) => id !== rowId))
      } else {
        setExpandedRows((previous) => [...previous, rowId])
      }
    },
    [expandedRows]
  )

  const { data: leaderboardData = [], isLoading: isLeaderboardLoading } =
    usePoolLeaderboard({
      chainId,
      enabled: isTabSelected,
      endTime: selectedEpoch
        ? new Date(selectedEpoch.end).getTime() / 1000
        : undefined,
      lbPairAddress,
      orderBy: sortMethod,
      startTime: selectedEpoch
        ? new Date(selectedEpoch.start).getTime() / 1000
        : undefined
    })

  const hasEstimatedRewards = useMemo(() => {
    return leaderboardData.some(
      (data) => data.estimatedRewardsUsd !== undefined
    )
  }, [leaderboardData])

  const sortedData = useMemo(() => {
    let sorter
    switch (sortMethod) {
      case 'volume':
        sorter = (a: LeaderboardData, b: LeaderboardData) =>
          parseFloat(a.shareVolumeTradedUsd) -
          parseFloat(b.shareVolumeTradedUsd)
        break
      case 'fees':
        sorter = (a: LeaderboardData, b: LeaderboardData) =>
          parseFloat(a.accruedFeesUsd) - parseFloat(b.accruedFeesUsd)
        break
    }

    let sorted = [...leaderboardData].sort(sorter)

    if (isSortDescending) {
      sorted = sorted.reverse()
    }

    return sorted
  }, [sortMethod, isSortDescending, leaderboardData])

  const onHeaderClick = useCallback(
    (method: PoolLeaderboardSortMethod) => {
      sortMethod !== method
        ? setSortMethod(method)
        : setIsSortDescending((previous) => !previous)
    },
    [sortMethod]
  )

  const onCopyDistributionClick = (
    userAddress: string,
    userBalances?: LBPairUserBalances
  ) => {
    if (!isConnected) {
      openConnectModal?.()
      return
    }

    if (!activeBinId || !userBalances || !currency0 || !currency1) {
      return
    }

    const { amountXPerYRatio, distributionParams } =
      convertPositionToAddLiquidityParams({
        activeBinId,
        amountsX: userBalances.amounts.map(({ amountX }) => amountX),
        amountsY: userBalances.amounts.map(({ amountY }) => amountY),
        decimalsX: currency0.decimals,
        decimalsY: currency1.decimals,
        positions: userBalances.positions
      })
    dispatchAddLiquidityAction({
      payload: {
        balance0: balance0,
        balance1: balance1,
        distribution: {
          account: userAddress,
          amountXPerYRatio,
          distributionParams,
          percentOfFundsToUse: 0.9
        }
      },
      type: 'set_imported_distribution'
    })

    onDisplayAddLiquidityPanel()
  }

  if (!lbPairAddress || !lbPoolVersion || !binStep) {
    return null
  }

  return (
    <Flex flexDir="column">
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ base: 'flex-start', md: 'center' }}
        gap={4}
      >
        <Heading size="lg">
          <Trans>Leaderboard</Trans>
        </Heading>
        {selectedEpoch && !isLoadingEpochs ? (
          <Menu>
            <MenuButton
              h="48px"
              as={Button}
              variant="outline"
              rightIcon={<ChevronDownIcon />}
              textAlign="left"
              w={{ base: 'full', md: 'auto' }}
            >
              <FormattedEpoch epoch={selectedEpoch} />
            </MenuButton>
            <MenuList maxH="300px" overflowY="scroll">
              {epochs?.map((epoch) => (
                <MenuItem
                  key={epoch.epoch}
                  h="48px"
                  onClick={() => setSelectedEpoch(epoch)}
                  fontWeight="bold"
                >
                  <FormattedEpoch epoch={epoch} />
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ) : (
          <Skeleton h="48px" w="200px" borderRadius="2xl" />
        )}
      </Flex>
      <Spacer mt="1rem" />
      {isLeaderboardLoading ? (
        <Center w="full" p={8}>
          <Spinner />
        </Center>
      ) : (
        <>
          <Hide below="md">
            <TableContainer
              bg="bgCard"
              borderRadius="2xl"
              border="1px solid"
              borderColor="border"
              p={4}
            >
              <Table variant="card">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>
                      <Trans>Address</Trans>
                    </Th>
                    <Th></Th>
                    <SortableTableHeader
                      isNumeric
                      name={t`Volume`}
                      isSortActive={sortMethod === 'volume'}
                      isSortDescending={isSortDescending}
                      onClick={() => onHeaderClick('volume')}
                    />
                    <SortableTableHeader
                      isNumeric
                      name={t`Fees Generated`}
                      isSortActive={sortMethod === 'fees'}
                      isSortDescending={isSortDescending}
                      onClick={() => onHeaderClick('fees')}
                    />
                    {hasEstimatedRewards ? (
                      <Th isNumeric textAlign="right">
                        <Trans>Est. Rewards</Trans>
                      </Th>
                    ) : null}
                    <Th />
                  </Tr>
                </Thead>
                {sortedData.map((row, index) => (
                  <LeaderboardRow
                    key={index}
                    {...row}
                    position={index + 1}
                    currency0={currency0}
                    currency1={currency1}
                    isExpanded={expandedRows.includes(index)}
                    onExpandCollapseClick={() => toggleExpandedRow(index)}
                    lbPairAddress={lbPairAddress}
                    lbPairVersion={lbPoolVersion}
                    binStep={binStep}
                    activeBinId={activeBinId}
                    onCopyDistributionClick={onCopyDistributionClick}
                    chainId={chainId}
                  />
                ))}
              </Table>
            </TableContainer>
          </Hide>
          <Hide above="md">
            <VStack gap={4}>
              {sortedData.map((row, index) => {
                return (
                  <LeaderboardRowMobile
                    {...row}
                    key={index}
                    position={index + 1}
                    currency0={currency0}
                    currency1={currency1}
                    isExpanded={expandedRows.includes(index)}
                    onExpandCollapseClick={() => toggleExpandedRow(index)}
                    lbPairAddress={lbPairAddress}
                    lbPairVersion={lbPoolVersion}
                    binStep={binStep}
                    activeBinId={activeBinId}
                    onCopyDistributionClick={onCopyDistributionClick}
                    chainId={chainId}
                  />
                )
              })}
            </VStack>
          </Hide>
        </>
      )}
    </Flex>
  )
}

export default Leaderboard

const FormattedEpoch = ({ epoch }: { epoch: Epoch }) => {
  return (
    <HStack justify="space-between" spacing={3}>
      <Text>
        <Trans>Epoch {epoch.epoch}</Trans>
      </Text>
      <Text
        fontSize="sm"
        textColor="textSecondary"
        fontWeight="normal"
        textAlign="right"
      >
        {format(new Date(epoch.start), 'MMM dd, yyyy')} -{' '}
        {format(new Date(epoch.end), 'MMM dd, yyyy')}
      </Text>
    </HStack>
  )
}
