import {
  Box,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { ChainId, Token } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import Web3Button from 'components/Web3Button'
import { useSJoeClaimRewards } from 'hooks/stake/useSJoeStaking'
import React from 'react'
import { formattedNum } from 'utils/format'
import { claimSJoe } from 'utils/measure'
import { formatEther, formatUnits } from 'viem'

interface SJoeClaimPanelProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  isLoading?: boolean
  joePriceUSD?: number
  joeToken?: Token
  pendingRewards?: bigint
  rewardToken?: Token
  rewardTokenPriceUSD?: number
  sJoeBalance?: bigint
}

const SJoeClaimPanel = ({
  chainId,
  isLoading,
  joePriceUSD,
  joeToken,
  pendingRewards,
  rewardToken,
  rewardTokenPriceUSD,
  sJoeBalance
}: SJoeClaimPanelProps) => {
  const { claim, isClaiming } = useSJoeClaimRewards({ chainId })

  const fmtStakedBalance = sJoeBalance ? formatEther(sJoeBalance) : ''
  const fmtRewardsBalance =
    pendingRewards && rewardToken
      ? formatUnits(pendingRewards, rewardToken.decimals)
      : ''

  const stakedAmountUSD = joePriceUSD
    ? Number(fmtStakedBalance) * joePriceUSD
    : undefined
  const rewardAmountUSD = rewardTokenPriceUSD
    ? Number(fmtRewardsBalance) * rewardTokenPriceUSD
    : undefined

  return (
    <Box
      w="full"
      p={{ base: 4, md: 6 }}
      bg="bgCard"
      borderRadius="2xl"
      border="1px"
      borderColor="border"
    >
      <SimpleGrid gap={2} columns={2}>
        <Heading size="sm">
          <Trans>Staked Balance</Trans>
        </Heading>
        <Heading size="sm">
          <Trans>Pending Rewards</Trans>
        </Heading>
        <HStack align="flex-start">
          <CurrencyLogo
            mt="2px"
            address={joeToken?.address}
            symbol={joeToken?.symbol}
            boxSize={6}
          />
          <VStack spacing={0} align="flex-start">
            <Skeleton isLoaded={!isLoading}>
              <Flex align="center" gap={2}>
                <Text fontSize="lg" fontWeight="semibold">
                  {`${formattedNum(fmtStakedBalance)} ${joeToken?.symbol}`}
                </Text>
              </Flex>
            </Skeleton>
            {stakedAmountUSD ? (
              <Text fontSize="sm" color="textSecondary">
                {formattedNum(stakedAmountUSD, { usd: true })}
              </Text>
            ) : null}
          </VStack>
        </HStack>
        <HStack align="flex-start">
          <CurrencyLogo
            mt="2px"
            address={rewardToken?.address}
            symbol={rewardToken?.symbol}
            boxSize={6}
          />
          <VStack spacing={0} align="flex-start">
            <Skeleton isLoaded={!isLoading}>
              <Text fontSize="lg" fontWeight="semibold">
                {`${formattedNum(fmtRewardsBalance)} ${rewardToken?.symbol}`}
              </Text>
            </Skeleton>
            {rewardAmountUSD ? (
              <Text fontSize="sm" color="textSecondary">
                {formattedNum(rewardAmountUSD, { usd: true })}
              </Text>
            ) : null}
          </VStack>
        </HStack>
      </SimpleGrid>

      <Web3Button
        colorScheme="accent"
        variant="primary"
        size="xl"
        w="full"
        marginTop={6}
        isDisabled={!Number(fmtRewardsBalance) || !claim}
        isLoading={isClaiming}
        loadingText={t`Claiming Rewards`}
        onClick={() => {
          claim?.()
          claimSJoe()
        }}
      >
        <Trans>Claim Rewards</Trans>
      </Web3Button>
    </Box>
  )
}

export default SJoeClaimPanel
