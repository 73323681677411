import { ChainId, CNATIVE, Token } from '@traderjoe-xyz/sdk-core'
import { useMemo } from 'react'
import { erc20Abi, getAddress, isAddress } from 'viem'
import { useReadContracts } from 'wagmi'

import useFetchTokensList from './tokensList/useFetchTokensList'

interface UseSdkCurrenciesProps {
  addresses: (string | null | undefined)[]
  chainId: Exclude<ChainId, ChainId.MANTLE> | undefined
  symbolByAddressOverrides?: Record<string, string> // allow to override the symbol of a token
}

const useSdkCurrencies = ({
  addresses,
  chainId,
  symbolByAddressOverrides
}: UseSdkCurrenciesProps) => {
  const { data: tokensList } = useFetchTokensList({
    enabled: addresses.length > 0
  })

  const nativeCurrency = chainId ? CNATIVE.onChain(chainId) : undefined

  // Filter addresses that need on-chain lookup (not in tokensList)
  const addressesNeedingLookup = useMemo(() => {
    if (!chainId || !tokensList) return []
    return addresses.filter((address) => {
      if (!address || !isAddress(address)) return false
      const normalizedAddress = getAddress(address)
      return !tokensList.tokens.some(
        (t) => t.chainId === chainId && t.address === normalizedAddress
      )
    }) as string[]
  }, [addresses, chainId, tokensList])

  const contracts = addressesNeedingLookup.map((address) => ({
    abi: erc20Abi,
    address: getAddress(address),
    chainId
  }))

  const { data: onChainData, isLoading } = useReadContracts({
    contracts: [
      ...contracts.map((contract) => ({
        ...contract,
        functionName: 'decimals'
      })),
      ...contracts.map((contract) => ({
        ...contract,
        functionName: 'symbol'
      })),
      ...contracts.map((contract) => ({
        ...contract,
        functionName: 'name'
      }))
    ]
  })

  const tokens = useMemo(() => {
    if (!chainId) {
      return []
    }

    return addresses.map((address) => {
      // Handle native currency
      if (address === nativeCurrency?.symbol) {
        return nativeCurrency
      }

      // Handle invalid addresses
      if (!address || !isAddress(address)) {
        return undefined
      }

      const normalizedAddress = getAddress(address)

      // Check tokensList first
      if (tokensList) {
        const listToken = tokensList.tokens.find(
          (t) => t.chainId === chainId && t.address === normalizedAddress
        )
        if (listToken) {
          const symbol = symbolByAddressOverrides?.[address] ?? listToken.symbol
          return new Token(
            chainId,
            normalizedAddress,
            listToken.decimals,
            symbol,
            listToken.name
          )
        }
      }

      // If not in list and we have on-chain data, use that
      if (onChainData) {
        const idx = addressesNeedingLookup.findIndex(
          (a) => getAddress(a) === normalizedAddress
        )
        if (idx === -1) return undefined

        const decimals = onChainData[idx].result as number | undefined
        const symbol = onChainData[idx + addressesNeedingLookup.length]
          .result as string | undefined
        const name = onChainData[idx + addressesNeedingLookup.length * 2]
          .result as string | undefined

        if (!decimals || !symbol || !name) return undefined

        const _symbol = symbolByAddressOverrides?.[address] ?? symbol
        return new Token(chainId, normalizedAddress, decimals, _symbol, name)
      }

      return undefined
    })
  }, [
    chainId,
    addresses,
    nativeCurrency,
    tokensList,
    onChainData,
    addressesNeedingLookup,
    symbolByAddressOverrides
  ])

  return { isLoading, tokens }
}

export default useSdkCurrencies
