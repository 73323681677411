import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExternalLinkIcon
} from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Link,
  Tag,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import LBPairDistributionSection from 'components/LBPairDistributionSection'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import { STRATEGIES_INFO } from 'constants/vaults'
import useLBPairUserDistribution from 'hooks/pool/v2/useLBPairUserDistribution'
import React from 'react'
import { AchievementIcon, CopyIcon } from 'theme/icons'
import { LeaderboardRowProps } from 'types/leaderboard'
import { shortenAddress } from 'utils/addresses'
import { getChain } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { useAccount } from 'wagmi'

const LeaderboardRowMobile = ({
  accruedFeesUsd,
  activeBinId,
  binStep,
  chainId,
  currency0,
  currency1,
  estimatedRewardsUsd,
  isExpanded,
  lbPairAddress,
  lbPairVersion,
  onCopyDistributionClick,
  onExpandCollapseClick,
  position,
  shareVolumeTradedUsd,
  userAddress
}: LeaderboardRowProps) => {
  const { address: account } = useAccount()
  const chain = getChain(chainId)

  const autoPoolName = STRATEGIES_INFO[chainId][userAddress]?.name
  const userName = autoPoolName ?? shortenAddress(userAddress, 6)

  const renderTrophy = (position: number | undefined) => {
    switch (position) {
      case 1:
        return <AchievementIcon fill="yellowBar" />
      case 2:
        return <AchievementIcon fill="gray.400" />
      case 3:
        return <AchievementIcon fill="yellow.600" />
      default:
        return null
    }
  }

  const isCurrentUser = account?.toLowerCase() === userAddress.toLowerCase()
  const borderColor = isCurrentUser ? 'yellowBar' : 'border'

  const {
    chartData,
    isLoading: isLoadingUserDistribution,
    numBins,
    totalTokensX,
    totalTokensY,
    userBalances
  } = useLBPairUserDistribution({
    activeBinId,
    binStep,
    chainId,
    currency0,
    currency1,
    enabled: isExpanded,
    lbPairAddress,
    lbPairVersion,
    owner: userAddress
  })

  return (
    <Flex
      flexDir="column"
      border="1px"
      borderColor={borderColor}
      borderRadius="lg"
      bg="bgCard"
      w="full"
    >
      <Grid p={4} templateColumns="minmax(0, 1fr) minmax(0, 1.5fr)">
        <VStack w="full" alignItems="flex-start">
          <Text fontSize="sm" textColor="textSecondaryLight">
            <Trans>User Address:</Trans>
          </Text>
          <Flex align="flex-start">
            <Text fontSize="sm" textColor="textSecondaryLight">
              <Trans>Volume traded:</Trans>
            </Text>
          </Flex>
          <Flex align="flex-start">
            <Text fontSize="sm" textColor="textSecondaryLight">
              <Trans>Fees generated:</Trans>
            </Text>
          </Flex>
          {estimatedRewardsUsd ? (
            <Flex align="flex-start">
              <TouchFriendlyTooltip
                label={t`Estimated Rewards based on fees earned may differ from final reward calculations`}
              >
                <Text
                  fontSize="sm"
                  textColor="textSecondaryLight"
                  textDecor="dotted underline"
                >
                  <Trans>Est. Rewards:</Trans>
                </Text>
              </TouchFriendlyTooltip>
            </Flex>
          ) : null}
          {numBins !== undefined ? (
            <Flex align="flex-start">
              <Text fontSize="sm" textColor="textSecondaryLight">
                <Trans>Bins:</Trans>
              </Text>
            </Flex>
          ) : null}
          {totalTokensX !== undefined ? (
            <Flex align="flex-start">
              <Text fontSize="sm" textColor="textSecondaryLight">
                <Trans>Token X:</Trans>
              </Text>
            </Flex>
          ) : null}
          {totalTokensY !== undefined ? (
            <Flex align="flex-start">
              <Text fontSize="sm" textColor="textSecondaryLight">
                <Trans>Token Y:</Trans>
              </Text>
            </Flex>
          ) : null}
        </VStack>
        <VStack w="full" alignItems="flex-start">
          <Link
            aria-label="Link to user address on explorer"
            isExternal
            href={`${chain?.blockExplorers?.default.url}/address/${userAddress}`}
          >
            <HStack fontSize="sm" textColor="textPrimary" fontWeight="semibold">
              {renderTrophy(position)}
              <Box noOfLines={1}>{userName}</Box>
              {isCurrentUser ? (
                <Tag
                  size="sm"
                  bg="yellowBar"
                  fontWeight="semibold"
                  borderRadius="full"
                >
                  <Trans>YOU</Trans>
                </Tag>
              ) : null}
              <ExternalLinkIcon />
            </HStack>
          </Link>
          <Flex alignItems="flex-start">
            <Text fontSize="sm" fontWeight="semibold">
              {formattedNum(shareVolumeTradedUsd, { usd: true })}
            </Text>
          </Flex>
          <Flex>
            <Text fontSize="sm" fontWeight="semibold">
              {formattedNum(accruedFeesUsd, { usd: true })}
            </Text>
          </Flex>
          {estimatedRewardsUsd ? (
            <Flex>
              <Text fontSize="sm" fontWeight="semibold">
                {formattedNum(estimatedRewardsUsd, { usd: true })}
              </Text>
            </Flex>
          ) : null}
          {numBins !== undefined ? (
            <Flex>
              <Text fontSize="sm" fontWeight="semibold">
                {formattedNum(numBins, { usd: false })}
              </Text>
            </Flex>
          ) : null}
          {totalTokensX !== undefined ? (
            <Flex>
              <Text fontSize="sm" fontWeight="semibold">
                {`${formattedNum(totalTokensX, {
                  allowDecimalsOver1000: true,
                  allowSmallDecimals: true,
                  places: 2
                })} ${currency0?.symbol}`}
              </Text>
            </Flex>
          ) : null}
          {totalTokensY !== undefined ? (
            <Flex>
              <Text fontSize="sm" fontWeight="semibold">
                {`${formattedNum(totalTokensY, {
                  allowDecimalsOver1000: true,
                  allowSmallDecimals: true,
                  places: 2
                })} ${currency1?.symbol}`}
              </Text>
            </Flex>
          ) : null}
        </VStack>
      </Grid>
      {isExpanded ? (
        <Box px={4}>
          <LBPairDistributionSection
            chainId={chainId}
            title="User Distribution"
            currency0={currency0}
            currency1={currency1}
            activeBinId={activeBinId}
            data={chartData}
            isLoading={isLoadingUserDistribution}
            headingActionButtonProps={{
              color: 'textSecondary',
              leftIcon: <CopyIcon fill="textSecondary" />,
              size: 'xs',
              variant: 'ghost'
            }}
            onHeadingActionButtonClick={() =>
              onCopyDistributionClick(userAddress, userBalances)
            }
          />
        </Box>
      ) : null}
      <Button
        variant="ghost"
        h={12}
        borderTopRadius={0}
        borderTop="1px solid"
        borderColor="border"
        onClick={onExpandCollapseClick}
        color="textSecondary"
        rightIcon={
          isExpanded ? (
            <ChevronUpIcon boxSize={5} color="textSecondary" />
          ) : (
            <ChevronDownIcon boxSize={5} color="textSecondary" />
          )
        }
      >
        {isExpanded ? (
          <Trans>Hide Distribution</Trans>
        ) : (
          <Trans>Show Distribution</Trans>
        )}
      </Button>
    </Flex>
  )
}

export default LeaderboardRowMobile
