import { ChainId, Token } from '@traderjoe-xyz/sdk-core'
import useGetLbPoolWithHighestLiquidity from 'hooks/pool/v2/useGetLbPoolWithHighestLiquidity'
import { useMemo, useState } from 'react'
import { getBinIdFromPrice, getPriceFromBinId } from 'utils/bin'
import { getAddress } from 'viem'

interface UsePrepareLimitOrderProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  tokenIn?: Token
  tokenOut?: Token
}

const usePrepareLimitOrder = ({
  chainId,
  tokenIn,
  tokenOut
}: UsePrepareLimitOrderProps) => {
  const [typedPrice, setTypedPrice] = useState<string>('')
  const [inputBinId, setInputBinId] = useState<number | undefined>()
  const [isPriceRatioInversed, setIsPriceRatioInversed] =
    useState<boolean>(false)

  const tokenInAddr = tokenIn?.address ? getAddress(tokenIn.address) : undefined

  // get best pair to place orders
  const { highestLiquidityPair, isLoading: isLoadingAllLBPairs } =
    useGetLbPoolWithHighestLiquidity({
      chainId,
      tokenX: tokenIn,
      tokenY: tokenOut,
      version: 'v21'
    })

  const binStep = highestLiquidityPair?.binStep
  const lbPairAddr = highestLiquidityPair?.LBPair
  const activeId = highestLiquidityPair?.activeId
  const tokenXAddr = highestLiquidityPair?.tokenXAddr
  const tokenYAddr = highestLiquidityPair?.tokenYAddr

  const arePairTokensInversed = tokenXAddr === tokenOut?.address

  const tokenX = !lbPairAddr
    ? tokenIn
    : tokenXAddr === tokenInAddr
      ? tokenIn
      : tokenOut

  const tokenY = !lbPairAddr
    ? tokenOut
    : tokenYAddr === tokenInAddr
      ? tokenIn
      : tokenOut

  // function to calculate price from bin id
  const calculatePriceFromBinId = (binId?: number) => {
    if (!binId || !binStep || !tokenX || !tokenY) {
      return { priceXY: 0, priceYX: 0 }
    }

    const priceXY = Number(
      getPriceFromBinId(binId, binStep, tokenX.decimals, tokenY.decimals, 18) ??
        0
    )

    return {
      priceXY,
      priceYX: 1 / priceXY
    }
  }

  // price of bin
  const { priceXY, priceYX } = calculatePriceFromBinId(inputBinId)

  // price of active bin
  const activePrice = useMemo(() => {
    if (!activeId || !binStep || !tokenX || !tokenY) {
      return 0
    }

    const activePriceXY = Number(
      getPriceFromBinId(
        activeId,
        binStep,
        tokenX.decimals,
        tokenY.decimals,
        18
      ) ?? 0
    )

    // set default price
    const binId = arePairTokensInversed ? activeId - 1 : activeId + 1
    setInputBinId(binId)
    const defaultPriceXY = Number(
      getPriceFromBinId(binId, binStep, tokenX.decimals, tokenY.decimals, 18)
    )

    const newTypedPrice =
      (arePairTokensInversed && !isPriceRatioInversed) ||
      (!arePairTokensInversed && isPriceRatioInversed)
        ? (1 / defaultPriceXY).toFixed(20)
        : defaultPriceXY.toFixed(20)
    setTypedPrice(newTypedPrice)

    return activePriceXY
  }, [
    activeId,
    binStep,
    tokenX,
    tokenY,
    setTypedPrice,
    arePairTokensInversed,
    isPriceRatioInversed
  ])

  // update input bin id from price and returns the bin id and priceXY
  const updateInputBinIdFromPrice = (price: number) => {
    if (!binStep || !tokenX || !tokenY) {
      return
    }

    const binId = getBinIdFromPrice(
      (arePairTokensInversed ? 1 / price : price).toString(),
      binStep,
      tokenX,
      tokenY
    )
    setInputBinId(binId)

    const { priceXY, priceYX } = calculatePriceFromBinId(binId)

    return { binId, priceXY, priceYX }
  }

  const togglePriceRatio = () => {
    if (!activeId || !inputBinId || !tokenX || !tokenY) return

    const isInversed = !isPriceRatioInversed
    setIsPriceRatioInversed(isInversed)

    const diff = activeId - inputBinId
    const binId = activeId - diff
    setInputBinId(binId)

    const { priceXY, priceYX } = calculatePriceFromBinId(binId)
    setTypedPrice(
      (arePairTokensInversed && !isInversed) ||
        (!arePairTokensInversed && isInversed)
        ? priceYX.toFixed(20)
        : priceXY.toFixed(20)
    )
  }

  const resetToMarketPrice = () => {
    if (!activeId || !binStep || !tokenX || !tokenY) {
      return
    }

    setInputBinId(activeId)

    const defaultPriceXY = Number(
      getPriceFromBinId(activeId, binStep, tokenX.decimals, tokenY.decimals, 18)
    )
    const newTypedPrice =
      (arePairTokensInversed && !isPriceRatioInversed) ||
      (!arePairTokensInversed && isPriceRatioInversed)
        ? (1 / defaultPriceXY).toFixed(20)
        : defaultPriceXY.toFixed(20)
    setTypedPrice(newTypedPrice)

    return newTypedPrice
  }

  return {
    activeId,
    activePrice,
    arePairTokensInversed,
    binStep,
    inputBinId,
    isLoadingAllLBPairs,
    isPriceRatioInversed,
    lbPairAddr,
    priceXY,
    priceYX,
    resetToMarketPrice,
    setTypedPrice,
    togglePriceRatio,
    tokenX,
    tokenY,
    typedPrice,
    updateInputBinIdFromPrice
  }
}

export default usePrepareLimitOrder
