import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Collapse,
  Divider,
  Flex,
  HStack,
  Spacer,
  Tag,
  Text,
  VStack
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { ChainId, CNATIVE } from '@traderjoe-xyz/sdk-core'
import ContractLink from 'components/ContractLink'
import Warning from 'components/Warning'
import useIsTrustedTrade from 'hooks/swap/useIsTrustedTrade'
import React from 'react'
import { useState } from 'react'
import { Route } from 'types/router'
import { isRunningE2ETests } from 'utils/e2e'
import { formattedNum } from 'utils/format'
import { tradeExpandSummary } from 'utils/measure'
import { formatUnits } from 'viem'

import AggregatorTag from './AggregatorTag'
import RouteDetails from './RouteDetails'

interface TradeDetailsRowProps {
  title: string
  value: string
}

const TradeDetailsRow = ({ title, value }: TradeDetailsRowProps) => (
  <HStack w="full" justify="space-between">
    <Text fontSize="sm" color="textSecondary">
      {title}
    </Text>
    <Text fontSize="sm" fontWeight="semibold">
      {value}
    </Text>
  </HStack>
)

const getWarningMessage = (symbol: string): string => {
  return `${symbol} is not on the verified token list`
}

interface TradeDetailsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  onTradeSelected: (trade: Route) => void
  priceImpact: number | undefined
  selectedTrade: Route
  slippageBps: number
  trades: Route[]
  outputCurrencyUsdPrice?: number
}

const TradeDetails = ({
  chainId,
  onTradeSelected,
  outputCurrencyUsdPrice,
  priceImpact = 0,
  selectedTrade,
  slippageBps,
  trades
}: TradeDetailsProps) => {
  const nativeCurrency = CNATIVE.onChain(chainId)
  const [isOpen, setIsOpen] = useState(false)
  const [isInverted, setIsInverted] = useState(false)

  const isNativeIn = selectedTrade.currencyIn.isNative
  const isNativeOut = selectedTrade.currencyOut.isNative

  const inputTokenSymbol = isNativeIn
    ? nativeCurrency?.symbol
    : selectedTrade.currencyIn.symbol
  const outputTokenSymbol = isNativeOut
    ? nativeCurrency?.symbol
    : selectedTrade.currencyOut.symbol

  const symbolA = isInverted ? outputTokenSymbol : inputTokenSymbol
  const symbolB = isInverted ? inputTokenSymbol : outputTokenSymbol

  const formattedPrice = isInverted
    ? formattedNum(
        Number(selectedTrade.amountIn.formatted) /
          Number(selectedTrade.amountOut.formatted),
        { places: 8 }
      )
    : formattedNum(
        Number(selectedTrade.amountOut.formatted) /
          Number(selectedTrade.amountIn.formatted),
        { places: 8 }
      )

  // Trusted
  const { inputTrusted, outputTrusted } = useIsTrustedTrade({
    chainId,
    trade: selectedTrade
  })

  // Expected output
  const formattedExpectedOutput = `${formattedNum(
    selectedTrade.amountOut.formatted,
    { places: 5 }
  )} ${isNativeOut ? nativeCurrency?.symbol : selectedTrade.currencyOut.symbol}`

  // Minimum received
  const slippageAdjustedAmount =
    selectedTrade.amountOut.value -
    (selectedTrade.amountOut.value * BigInt(slippageBps)) / BigInt(10000)
  const fmtSlippageAdjustedAmount = `${formattedNum(
    formatUnits(slippageAdjustedAmount, selectedTrade.currencyOut.decimals),
    { places: 5 }
  )} ${selectedTrade.currencyOut.symbol}`

  // Price impact warning
  const showPriceImpactWarning = priceImpact > 2
  const formattedPriceImpact =
    priceImpact < 0.01
      ? '<0.01%'
      : `${formattedNum(priceImpact, { places: 2 })}%`

  return (
    <Flex
      flexDir="column"
      w="full"
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="2xl"
    >
      <Flex
        data-cy="trade-details-header"
        align="center"
        justify="space-between"
        w="full"
        minH="40px"
        px={4}
        borderRadius="md"
        cursor="pointer"
        onClick={() => {
          setIsOpen((previous) => !previous)
          if (!isOpen) {
            tradeExpandSummary(symbolA, symbolB)
          }
        }}
      >
        <HStack w="full">
          <Text
            fontSize="sm"
            fontWeight="semibold"
            onClick={(e) => {
              if (!isRunningE2ETests) {
                e.stopPropagation()
              }
              setIsInverted((previous) => !previous)
            }}
          >
            {`1 ${symbolA} = ${formattedPrice} ${symbolB}`}
          </Text>

          <Spacer />

          <ChevronDownIcon />
        </HStack>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <VStack pt={2} pb={4} px={4}>
          <VStack spacing={0.5} mb={4} w="full">
            <TradeDetailsRow
              title={t`Expected Output:`}
              value={formattedExpectedOutput}
            />
            <TradeDetailsRow
              title={t`Minimum Received:`}
              value={fmtSlippageAdjustedAmount}
            />
            <TradeDetailsRow
              title={t`Price Impact:`}
              value={formattedPriceImpact}
            />
          </VStack>

          {trades.map((trade, i) => {
            const isSelected = trade === selectedTrade

            // Expected output
            const formattedExpectedOutput = `${formattedNum(
              trade.amountOut.formatted,
              { places: 5 }
            )} ${
              isNativeOut ? nativeCurrency?.symbol : trade.currencyOut.symbol
            }`

            // Expected output in USD
            const expectedOutputUsd = outputCurrencyUsdPrice
              ? formattedNum(
                  Number(trade.amountOut.formatted) * outputCurrencyUsdPrice,
                  { usd: true }
                )
              : undefined

            // Amount diff
            const amountDiffWithBestTrade =
              (1 -
                Number(trade.amountOut.formatted) /
                  Number(trades[0].amountOut.formatted)) *
              100
            const fmtAmountDiffWithBestTrade =
              amountDiffWithBestTrade < 0.001
                ? '-0%'
                : `-${formattedNum(amountDiffWithBestTrade, { places: 3 })}%`

            return (
              <VStack
                data-cy={`aggregator-option-${trade.aggregator}`}
                key={i}
                border="1px solid"
                borderColor={isSelected ? 'accent.500' : 'border'}
                w="full"
                borderRadius="lg"
                bg={isSelected ? 'bgSecondary' : 'bgPrimary'}
                align="flex-start"
                p={4}
                spacing={2}
                cursor="pointer"
                _hover={{
                  bg: 'bgSecondary'
                }}
                onClick={() => onTradeSelected(trade)}
              >
                <AggregatorTag aggregator={trade.aggregator} />

                <HStack w="full">
                  <Text fontSize="lg" fontWeight="semibold">
                    {formattedExpectedOutput}
                  </Text>
                  <Text textColor="textSecondary" fontSize="sm">
                    ~{expectedOutputUsd}
                  </Text>

                  {trade.aggregator !== 'jar' && <Spacer />}

                  {trade.aggregator !== 'jar' ? (
                    i === 0 ? (
                      <Tag
                        rounded="full"
                        colorScheme="green"
                        fontWeight="semibold"
                        size="sm"
                        bg="green.400"
                        textColor="white"
                      >
                        BEST
                      </Tag>
                    ) : (
                      <Tag
                        rounded="full"
                        colorScheme="red"
                        fontWeight="semibold"
                        size="sm"
                      >
                        {fmtAmountDiffWithBestTrade}
                      </Tag>
                    )
                  ) : null}
                </HStack>

                {trade.aggregator === 'jar' && (
                  <Divider
                    mb={2}
                    borderColor="gray.300"
                    _dark={{ borderColor: 'gray.600' }}
                  />
                )}

                {trade.aggregator === 'jar' && (
                  <HStack w="full">
                    <RouteDetails chainId={chainId} route={trade} />
                    <Spacer />
                    {i === 0 ? (
                      <Tag
                        rounded="full"
                        colorScheme="green"
                        fontWeight="semibold"
                        size="sm"
                        bg="green.400"
                        textColor="white"
                      >
                        BEST
                      </Tag>
                    ) : (
                      <Tag
                        rounded="full"
                        colorScheme="red"
                        fontWeight="semibold"
                        size="sm"
                      >
                        {fmtAmountDiffWithBestTrade}
                      </Tag>
                    )}
                  </HStack>
                )}
              </VStack>
            )
          })}
        </VStack>
      </Collapse>
      {!inputTrusted && inputTokenSymbol && selectedTrade.currencyIn.isToken ? (
        <Warning
          message={getWarningMessage(inputTokenSymbol)}
          rightContent={
            <ContractLink
              address={selectedTrade.currencyIn.address}
              showAddress={false}
              color="white"
              chainId={chainId}
            />
          }
          m={2}
          mt={0}
        />
      ) : null}
      {showPriceImpactWarning ||
      (!outputTrusted &&
        outputTokenSymbol &&
        selectedTrade.currencyOut.isToken) ? (
        <VStack m={isOpen ? 4 : 2} mt={0}>
          {!outputTrusted &&
          outputTokenSymbol &&
          selectedTrade.currencyOut.isToken ? (
            <Warning
              message={getWarningMessage(outputTokenSymbol)}
              rightContent={
                <ContractLink
                  address={selectedTrade.currencyOut.address}
                  showAddress={false}
                  color="white"
                  chainId={chainId}
                />
              }
              w="full"
            />
          ) : null}
          {showPriceImpactWarning && (
            <Warning
              data-cy="price-impact-warning"
              message="Price Impact Warning"
              rightContent={
                <Text fontSize="sm" fontWeight="semibold" color="white">
                  {formattedPriceImpact}
                </Text>
              }
              w="full"
            />
          )}
        </VStack>
      ) : null}
    </Flex>
  )
}

export default TradeDetails
