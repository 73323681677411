import { ChainId } from '@traderjoe-xyz/sdk-core'
import { TokenInfo, TokenInfoTag } from 'types/tokensList'
import { getChain } from 'utils/chains'
import { formatUnits } from 'viem'
import { useAccount, useBalance } from 'wagmi'

interface UseNativeTokenInfoProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  enabled: boolean
}

const useNativeTokenInfo = ({
  chainId,
  enabled
}: UseNativeTokenInfoProps): {
  data: TokenInfo | undefined
  isFetching: boolean
} => {
  const { address } = useAccount()
  const { data, isFetching } = useBalance({
    address,
    chainId,
    query: { enabled }
  })

  const chain = getChain(chainId)

  return {
    data: chain
      ? {
          balance: data ? formatUnits(data.value, data.decimals) : undefined,
          chainId,
          decimals: chain.nativeCurrency.decimals,
          name: chain.nativeCurrency.name,
          symbol: chain.nativeCurrency.symbol,
          tags: [TokenInfoTag.TOP]
        }
      : undefined,
    isFetching
  }
}

export default useNativeTokenInfo
