import { useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { useDexbarnGet } from 'hooks/useDexbarn'
import qs from 'qs'
import { ClaimableRewardsEpoch } from 'types/rewards'
import { getDexbarnChainParam } from 'utils/chains'
import { useAccount } from 'wagmi'

interface UseAllMarketsClaimableProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  markets: string[]
  enabled?: boolean
}

const useAllMarketsClaimable = ({
  chainId,
  enabled = true,
  markets
}: UseAllMarketsClaimableProps) => {
  const { address: account } = useAccount()
  const chain = getDexbarnChainParam(chainId)

  const fetchUserClaimableRewards = useDexbarnGet<
    ClaimableRewardsEpoch[],
    { markets: string[] }
  >(`/v1/rewards/all-markets-claimable/${chain}/${account}`, chainId)

  return useQuery<ClaimableRewardsEpoch[]>({
    enabled: enabled && !!account && markets.length > 0,
    queryFn: () =>
      fetchUserClaimableRewards({
        params: { markets },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'repeat' })
      }),
    queryKey: ['ClaimableRewardsEpoch', account, chainId],
    retry: 0,
    select: (data) => {
      return data.filter((d) => d.claimableRewards.length > 0)
    }
  })
}

export default useAllMarketsClaimable
