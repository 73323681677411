import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import {
  LIQUIDITY_AMOUNTS_HELPER_ADDRESS,
  LIQUIDITY_HELPER_V2_ADDRESS,
  LiquidityAmountsHelperABI,
  LiquidityHelperV2ABI
} from '@traderjoe-xyz/sdk-v2'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { LBPoolVersion } from 'types/pool'
import { getDexbarnChainParam } from 'utils/chains'
import { convertLBPositionsToUserLBPositions } from 'utils/poolV2'
import { getAddress, zeroAddress } from 'viem'
import { usePublicClient } from 'wagmi'

interface UseLBPairBalancesProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  lbPoolVersion: LBPoolVersion | undefined
  enabled?: boolean
  lbBinStep?: number
  lbPairAddress?: string
  owner?: string
  token0Decimals?: number
  token1Decimals?: number
}

const useLBPairBalances = ({
  chainId,
  enabled = true,
  lbBinStep,
  lbPairAddress,
  lbPoolVersion,
  owner,
  token0Decimals,
  token1Decimals
}: UseLBPairBalancesProps) => {
  const chain = getDexbarnChainParam(chainId)
  const fetchUserBinIds = useDexbarnGet<number[]>(
    `/v1/user/bin-ids/${owner?.toLowerCase()}/${chain}/${lbPairAddress?.toLowerCase()}`,
    chainId
  )

  const {
    data: bins,
    isFetching: isFetchingBins,
    refetch: refetchBins
  } = useQuery<number[]>({
    enabled: !!lbPairAddress && !!owner && enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchUserBinIds(),
    queryKey: ['UserBinIds', chainId, owner, lbPairAddress]
  })

  const binIds = bins?.map((binId) => BigInt(binId))

  // we get the amounts and liquidities from the chain directly
  const publicClient = usePublicClient({ chainId })
  const {
    data: amountsAndLiquidities,
    isFetching: isFetchingAmountsAndLiquidities,
    refetch: refetchAmountsAndLiquidities
  } = useQuery({
    enabled:
      !!lbPairAddress && !!owner && !!binIds && binIds.length > 0 && enabled,
    queryFn: async () => {
      if (!publicClient || !owner || !binIds || !lbPairAddress) return

      const liquidityHelperV2Address = LIQUIDITY_HELPER_V2_ADDRESS[chainId]

      if (liquidityHelperV2Address !== zeroAddress && lbPoolVersion !== 'v2') {
        const args = [
          getAddress(lbPairAddress),
          getAddress(owner),
          binIds
        ] as const

        const results = await publicClient.multicall({
          contracts: [
            {
              abi: LiquidityHelperV2ABI,
              address: liquidityHelperV2Address,
              args,
              functionName: 'getAmountsOf'
            },
            {
              abi: LiquidityHelperV2ABI,
              address: liquidityHelperV2Address,
              args,
              functionName: 'getSharesOf'
            }
          ]
        })

        const amounts = results?.[0].result as [
          readonly bigint[],
          readonly bigint[]
        ]
        const liquidities = results?.[1].result as readonly bigint[]

        return {
          amounts,
          liquidities
        }
      } else {
        const args = [
          getAddress(owner),
          binIds,
          getAddress(lbPairAddress)
        ] as const

        const results = await publicClient.multicall({
          contracts: [
            {
              abi: LiquidityAmountsHelperABI,
              address: getAddress(LIQUIDITY_AMOUNTS_HELPER_ADDRESS[chainId]),
              args,
              functionName: 'getAmountsOf'
            },
            {
              abi: LiquidityAmountsHelperABI,
              address: getAddress(LIQUIDITY_AMOUNTS_HELPER_ADDRESS[chainId]),
              args,
              functionName: 'getBalanceOf'
            }
          ]
        })

        const amounts = results?.[0].result as [
          readonly bigint[],
          readonly bigint[]
        ]
        const liquidities = results?.[1].result as readonly bigint[]

        return {
          amounts,
          liquidities
        }
      }
    },
    queryKey: ['BinAmountsAndLiquidities', chainId, owner, lbPairAddress, bins]
  })

  const amounts = amountsAndLiquidities?.amounts
  const liquidities = amountsAndLiquidities?.liquidities

  const positions =
    bins &&
    amounts &&
    liquidities &&
    token0Decimals &&
    token1Decimals &&
    lbBinStep
      ? convertLBPositionsToUserLBPositions({
          amounts: { amountsX: amounts[0], amountsY: amounts[1] },
          binIds: bins,
          lbBinStep,
          liquidities,
          token0Decimals,
          token1Decimals
        })
      : undefined

  return {
    data: positions,
    isFetching: isFetchingBins || isFetchingAmountsAndLiquidities,
    refetch: () => {
      refetchBins()
      refetchAmountsAndLiquidities()
    }
  }
}

export default useLBPairBalances
