import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Flex,
  Hide,
  HStack,
  IconButton,
  Image,
  Link,
  Show,
  Spacer,
  StackProps,
  Text
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import Jazzicon from 'assets/jazzicon.svg'
import useGetUserProfile from 'hooks/joebarn/useGetUserProfile'
import React from 'react'
import { DisconnectIcon, SettingsIcon } from 'theme/icons'
import { shortenAddress } from 'utils/addresses'
import { getChain } from 'utils/chains'
import { useDisconnect } from 'wagmi'

interface ConnnectedAccountProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  onSettingsClick?: () => void
  stackProps?: StackProps
  walletAddress?: string
}

const ConnnectedAccount = ({
  chainId,
  onSettingsClick,
  stackProps,
  walletAddress
}: ConnnectedAccountProps) => {
  const { disconnect } = useDisconnect()
  const chain = getChain(chainId)
  const { data: userProfile } = useGetUserProfile(walletAddress)
  const { pfpUrl } = userProfile ?? { pfpUrl: null }

  return (
    <HStack align="center" gap={4} w="full" {...stackProps}>
      <Image
        src={pfpUrl}
        fallbackSrc={Jazzicon}
        borderRadius="50%"
        alt="pfp"
        bgColor="rgb(243, 184, 0)"
        boxSize="40px"
      />
      <Flex flexDir="column" alignItems="flex-start">
        <Text fontSize="xs" textColor="textSecondary">
          <Trans>Connected Account:</Trans>
        </Text>
        <Link
          aria-label="Link to user address on explorer"
          isExternal
          href={`${chain?.blockExplorers?.default.url}/address/${walletAddress}`}
        >
          <HStack>
            <Text fontSize="md" fontWeight="semibold">
              {shortenAddress(walletAddress)}
            </Text>
            <ExternalLinkIcon />
          </HStack>
        </Link>
      </Flex>
      <Spacer />
      <Hide below="lg">
        <IconButton
          aria-label="disconnect wallet"
          onClick={() => disconnect()}
          variant="outline"
          icon={<DisconnectIcon />}
          boxSize={12}
        />
      </Hide>
      <Show below="lg">
        <Flex gap={2}>
          <IconButton
            aria-label="go to settings"
            onClick={onSettingsClick}
            variant="outline"
            icon={<SettingsIcon />}
            boxSize={12}
          />
          <IconButton
            aria-label="disconnect wallet"
            onClick={() => disconnect()}
            variant="outline"
            icon={<DisconnectIcon />}
            boxSize={12}
          />
        </Flex>
      </Show>
    </HStack>
  )
}

export default ConnnectedAccount
