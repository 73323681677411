import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { ChainId, Currency } from '@traderjoe-xyz/sdk-core'
import { APTFarmABI } from '@traderjoe-xyz/sdk-v2'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { formattedNum } from 'utils/format'
import { formatUnits, getAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseFarmWithdrawProps {
  chainId: ChainId
  farmAddress: string
  poolId: string
  currency?: Currency
  enabled?: boolean
  onSuccess?: () => void
  tokenAmount?: bigint
}

const useFarmWithdraw = ({
  chainId,
  currency,
  enabled = true,
  farmAddress,
  onSuccess,
  poolId,
  tokenAmount
}: UseFarmWithdrawProps) => {
  const { isConnected } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const _enabled =
    enabled && !!poolId && !!currency && !!farmAddress && isConnected

  const { data: withdrawConfig, error } = useSimulateContract({
    abi: APTFarmABI,
    address: getAddress(farmAddress),
    args: tokenAmount ? [BigInt(poolId), tokenAmount] : undefined,
    functionName: 'withdraw',
    query: {
      enabled: _enabled,
      gcTime: 0
    },
    value: BigInt(0) as any
  })

  const {
    data: hash,
    isPending: isLoadingWithdraw,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary =
          tokenAmount !== undefined && currency
            ? t`Withdraw ${formattedNum(
                formatUnits(tokenAmount, currency.decimals)
              )} ${currency.symbol}`
            : undefined
        if (!transactionSummary) return
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({
          description: transactionSummary,
          hash
        })
      }
    }
  })

  const withdraw = withdrawConfig?.request
    ? () => writeContract(withdrawConfig.request)
    : undefined

  const { isLoading: isWaitingForTransaction } = useWaitForTransactionReceipt({
    chainId,
    hash,
    onTransactionSuccess: onSuccess
  })

  return {
    error,
    isLoading: isWaitingForTransaction || isLoadingWithdraw,
    withdraw
  }
}

export default useFarmWithdraw
