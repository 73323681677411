import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { ChainId, Currency } from '@traderjoe-xyz/sdk-core'
import {
  LB_ROUTER_ADDRESS,
  LB_ROUTER_V21_ADDRESS,
  LB_ROUTER_V22_ADDRESS,
  LBRouterABI,
  LBRouterV21ABI,
  LBRouterV22ABI
} from '@traderjoe-xyz/sdk-v2'
import useGetTransactionDeadline from 'hooks/useGetTransactionDeadline'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useIsRemoveLiquidityInNativeCurrencyEnabled } from 'state/settings/hooks'
import { LBPoolVersion } from 'types/pool'
import { formattedNum } from 'utils/format'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { formatUnits, getAddress } from 'viem'
import { useAccount, useWriteContract } from 'wagmi'

export enum RemoveLiquidityV2Option {
  BOTH_TOKENS,
  TOKEN_A,
  TOKEN_B
}

interface UseRemoveLiquidityV2Props {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  currency0: Currency
  currency1: Currency
  lbPoolVersion: LBPoolVersion
  onSuccess: () => void
  amount0Min?: string
  amount1Min?: string
  binStep?: string
  enabled?: boolean
  liquidityToRemove?: string[]
  userPositionIds?: number[]
}

const useRemoveLiquidityV2 = ({
  amount0Min,
  amount1Min,
  binStep,
  chainId,
  currency0,
  currency1,
  lbPoolVersion,
  liquidityToRemove,
  onSuccess,
  userPositionIds
}: UseRemoveLiquidityV2Props) => {
  const { address: account } = useAccount()
  const getTransactionDeadline = useGetTransactionDeadline()

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { isRemoveLiquidityInNativeCurrencyEnabled } =
    useIsRemoveLiquidityInNativeCurrencyEnabled()
  const shouldUseRemoveLiquidityNative =
    (currency0.isNative || currency1.isNative) &&
    isRemoveLiquidityInNativeCurrencyEnabled

  const address0 = wrappedCurrency(currency0)?.address
  const address1 = wrappedCurrency(currency1)?.address

  const _userPositionIds = userPositionIds?.map((id) => BigInt(id))
  const _liquidityToRemove = liquidityToRemove?.map((liquidity) =>
    BigInt(liquidity)
  )

  const transactionDeadline = getTransactionDeadline()
  const canBuildArgs =
    address0 &&
    address1 &&
    binStep &&
    amount0Min &&
    amount1Min &&
    _userPositionIds &&
    _liquidityToRemove &&
    account &&
    transactionDeadline &&
    (amount0Min !== '0' || amount1Min !== '0')

  const removeLiquidityNativeArgs = canBuildArgs
    ? ([
        currency1.isNative ? getAddress(address0) : getAddress(address1),
        Number(binStep),
        currency1.isNative ? BigInt(amount0Min) : BigInt(amount1Min),
        currency1.isNative ? BigInt(amount1Min) : BigInt(amount0Min),
        _userPositionIds,
        _liquidityToRemove,
        account,
        transactionDeadline
      ] as const)
    : undefined

  const removeLiquidityArgs = canBuildArgs
    ? ([
        getAddress(address0),
        getAddress(address1),
        Number(binStep),
        BigInt(amount0Min),
        BigInt(amount1Min),
        _userPositionIds,
        _liquidityToRemove,
        account,
        transactionDeadline
      ] as const)
    : undefined

  const {
    data,
    error: writeError,
    isPending,
    reset,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Remove ${formattedNum(
          formatUnits(BigInt(amount0Min ?? '0'), currency0.decimals)
        )} ${currency0.symbol} and ${formattedNum(
          formatUnits(BigInt(amount1Min ?? '0'), currency1.decimals)
        )} ${currency1.symbol} from pool`
        addRecentTransaction({ description, hash })
        addTransactionToast({ description, hash })
      }
    }
  })

  let routerAddress: string
  switch (lbPoolVersion) {
    case 'v2':
      routerAddress = LB_ROUTER_ADDRESS[chainId]
      break
    case 'v21':
      routerAddress = LB_ROUTER_V21_ADDRESS[chainId]
      break
    case 'v22':
      routerAddress = LB_ROUTER_V22_ADDRESS[chainId]
      break
  }

  const routerAbi =
    lbPoolVersion === 'v2'
      ? LBRouterABI
      : lbPoolVersion === 'v21'
        ? LBRouterV21ABI
        : LBRouterV22ABI

  const removeLiquidity =
    shouldUseRemoveLiquidityNative && removeLiquidityNativeArgs
      ? () =>
          writeContract({
            abi: routerAbi,
            address: getAddress(routerAddress),
            args: removeLiquidityNativeArgs,
            functionName:
              lbPoolVersion === 'v2'
                ? 'removeLiquidityAVAX'
                : 'removeLiquidityNATIVE',
            value: BigInt(0) as any // workaround for safe app
          })
      : removeLiquidityArgs
        ? () =>
            writeContract({
              abi: routerAbi,
              address: getAddress(routerAddress),
              args: removeLiquidityArgs,
              functionName: 'removeLiquidity',
              value: BigInt(0) as any // workaround for safe app
            })
        : undefined

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransactionReceipt({
      chainId,
      hash: data,
      onTransactionSuccess: onSuccess
    })

  return {
    isRemovingLiquidity: isPending || isWaitingForTransaction,
    isSuccess: receipt?.status === 'success',
    removeLiquidity,
    removeLiquidityError: writeError?.message.includes('User denied')
      ? undefined
      : writeError,
    resetRemoveLiquidity: reset
  }
}

export default useRemoveLiquidityV2
