import { Flex, Heading, HStack, Tag } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import BackButton from 'components/BackButton'
import ContractLink from 'components/ContractLink'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface MemeFarmDetailHeaderProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  farmAddress: string
  tokenAddress: string
  tokenSymbol: string
}

const MemeFarmDetailHeader = ({
  chainId,
  farmAddress,
  tokenAddress,
  tokenSymbol
}: MemeFarmDetailHeaderProps) => {
  const navigate = useNavigate()

  return (
    <Flex
      maxW={{ '2xl': '1600px', base: '1400px' }}
      margin="0 auto"
      flexDir="column"
      align="flex-start"
      gap={2}
      pos="relative"
      mb={8}
      px={4}
    >
      <BackButton
        title={t`Back to farms list`}
        ml={-3}
        minH="24px"
        onClick={() => navigate('/avalanche/stek')}
      />
      <Flex
        zIndex={1}
        gap={4}
        flexWrap="wrap"
        align={{ base: 'flex-start', md: 'center' }}
      >
        <HStack>
          <CurrencyLogo
            address={tokenAddress}
            symbol={tokenSymbol}
            boxSize={7}
          />
          <Heading size="lg">{tokenSymbol}</Heading>
        </HStack>

        <Tag variant="outline">
          <ContractLink
            color="textPrimary"
            address={farmAddress}
            chainId={chainId}
          />
        </Tag>
      </Flex>
    </Flex>
  )
}

export default MemeFarmDetailHeader
