import { ChainId } from '@traderjoe-xyz/sdk-core'
import { RawAxiosRequestConfig } from 'axios'
import { joebarnClient, joebarnTestnetClient } from 'constants/joebarn'
import { useCallback } from 'react'
import { getChain } from 'utils/chains'

const useJoebarnClient = ({ chainId }: { chainId: ChainId }) => {
  const chain = getChain(chainId)
  return chain?.testnet ? joebarnTestnetClient : joebarnClient
}

export const useJoebarnGet = <T = any, D = any>(
  url: string,
  chainId: ChainId,
  config?: RawAxiosRequestConfig<D>
) => {
  const client = useJoebarnClient({ chainId })
  return useCallback(
    async (overridesConfig?: RawAxiosRequestConfig<D>) => {
      const { data } = await client.get<T>(url, overridesConfig ?? config)
      return data
    },
    [client, url, config]
  )
}
