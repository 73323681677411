import { ChainId, Currency } from '@traderjoe-xyz/sdk-core'
import { useMemo } from 'react'
import { Route } from 'types/router'
import { getSwapCallParameters } from 'utils/swap'
import { useAccount } from 'wagmi'

import useGetTransactionDeadline from '../useGetTransactionDeadline'

interface UseSwapCallArgumentsProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  isExactIn: boolean
  slippageBps: number
  currencyIn?: Currency
  currencyOut?: Currency
  route?: Route
}

const useSwapCallArguments = ({
  chainId,
  currencyIn,
  currencyOut,
  isExactIn,
  route,
  slippageBps
}: UseSwapCallArgumentsProps) => {
  const { address: recipient } = useAccount()
  const getTransactionDeadline = useGetTransactionDeadline()

  return useMemo(() => {
    if (!route || !recipient || !chainId || !currencyIn || !currencyOut) {
      return {
        defaultParameters: undefined,
        feeOnTransferParameters: undefined
      }
    }

    const deadline = getTransactionDeadline()

    return {
      defaultParameters: getSwapCallParameters({
        chainId,
        currencyIn,
        currencyOut,
        deadline: Number(deadline),
        isExactIn,
        isTransferTax: false,
        recipient,
        route,
        slippageBps
      }),
      feeOnTransferParameters: getSwapCallParameters({
        chainId,
        currencyIn,
        currencyOut,
        deadline: Number(deadline),
        isExactIn,
        isTransferTax: true,
        recipient,
        route,
        slippageBps
      })
    }
  }, [
    route,
    slippageBps,
    chainId,
    getTransactionDeadline,
    recipient,
    currencyIn,
    currencyOut,
    isExactIn
  ])
}

export default useSwapCallArguments
