import { useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import dayjs from 'dayjs'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { VaultSharePriceData } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

interface UseVaultSharePriceDataProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  vaultAddress: string
  enabled?: boolean
}

const useVaultSharePriceData = ({
  chainId,
  enabled,
  vaultAddress
}: UseVaultSharePriceDataProps) => {
  const chain = getDexbarnChainParam(chainId)

  const now = dayjs()
  const ninetyDaysAgo = now.subtract(90, 'day')

  const fromTimestamp = ninetyDaysAgo.unix()
  const toTimestamp = now.unix()

  const fetchSharePriceData = useDexbarnGet<VaultSharePriceData[]>(
    `/v1/vaults/${chain}/${vaultAddress.toLowerCase()}/share-price`,
    chainId
  )
  return useQuery({
    enabled,
    queryFn: () =>
      fetchSharePriceData({ params: { fromTimestamp, toTimestamp } }),
    queryKey: ['UseVaultSharePriceData', vaultAddress, chainId]
  })
}

export default useVaultSharePriceData
