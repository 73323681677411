import { useQuery } from '@tanstack/react-query'
import { ChainId, Currency } from '@traderjoe-xyz/sdk-core'
import { AxiosError } from 'axios'
import { routerApiClient, routerTestnetClient } from 'constants/dexbarn'
import { Aggregator, SwapData } from 'types/router'
import { getDexbarnChainParam } from 'utils/chains'
import { useAccount } from 'wagmi'

interface UseGetSwapDataFromBarnProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  slippageBps: number
  aggregator?: Aggregator
  amountIn?: string
  currencyIn?: Currency
  currencyOut?: Currency
  enabled?: boolean
}

const useGetSwapDataFromBarn = ({
  aggregator,
  amountIn,
  chainId,
  currencyIn,
  currencyOut,
  enabled,
  slippageBps
}: UseGetSwapDataFromBarnProps) => {
  const isTestnet =
    chainId === ChainId.FUJI ||
    chainId === ChainId.ARB_GOERLI ||
    chainId === ChainId.BNB_TESTNET
  const client = isTestnet ? routerTestnetClient : routerApiClient

  const { address: userAddress } = useAccount()

  const fromTokenAddress = currencyIn?.isNative
    ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
    : currencyIn?.address

  const toTokenAddress = currencyOut?.isNative
    ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
    : currencyOut?.address

  let slippageParam = slippageBps
  switch (aggregator) {
    case 'okx':
      slippageParam = slippageBps / 10000
      break
    case 'odos':
    case '1inch':
      slippageParam = slippageBps / 100
      break
    case 'jar':
      break
  }

  const params =
    fromTokenAddress && toTokenAddress && amountIn && userAddress
      ? {
          amountIn,
          slippage: slippageParam,
          tokenIn: fromTokenAddress.toLowerCase(),
          tokenOut: toTokenAddress.toLowerCase(),
          userAddress
        }
      : undefined

  return useQuery<SwapData, AxiosError<{ error: string }>>({
    enabled: enabled && !!params && !!aggregator,
    queryFn: async () => {
      const response = await client.get<SwapData>(
        `/v2/aggregator/routes/${getDexbarnChainParam(chainId)}/${aggregator}`,
        { params }
      )
      return response.data
    },
    queryKey: ['GetSwapData', chainId, params, aggregator]
  })
}

export default useGetSwapDataFromBarn
