import { useQuery } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { useDexbarnPost } from 'hooks/useDexbarn'
import { TokenPrice } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

interface UseGetTokensUsdPriceProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  enabled?: boolean
  tokenAddresses?: string[]
}

const useGetTokensUsdPrice = ({
  chainId,
  enabled = true,
  tokenAddresses
}: UseGetTokensUsdPriceProps) => {
  const sortedTokenAddresses = tokenAddresses?.sort()
  const fetchPrices = useDexbarnPost<TokenPrice[]>(
    `/v1/tokens/${getDexbarnChainParam(chainId)}/prices`,
    chainId,
    { tokenAddresses: sortedTokenAddresses }
  )

  return useQuery({
    enabled:
      !!sortedTokenAddresses && sortedTokenAddresses.length > 0 && enabled,
    queryFn: () => fetchPrices(),
    queryKey: ['GetTokensUsdPrice', sortedTokenAddresses],
    select: (data) => {
      return data.reduce(
        (acc, price) => {
          acc[price.tokenAddress] = Number(price.priceUsd)
          return acc
        },
        {} as Record<string, number>
      )
    }
  })
}

export default useGetTokensUsdPrice
