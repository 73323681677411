import { VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { STABLE_JOE_STAKING_ADDRESS } from '@traderjoe-xyz/sdk'
import { ChainId, Token } from '@traderjoe-xyz/sdk-core'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CurrencyInput from 'components/CurrencyInput'
import Web3Button from 'components/Web3Button'
import { useSJoeDeposit } from 'hooks/stake/useSJoeStaking'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import { useTokenBalance } from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React from 'react'
import { stakeSJoe } from 'utils/measure'

interface SJoeStakePanelProps {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  onStakeSuccess: () => void
  joeToken?: Token
}

const SJoeStakePanel = ({
  chainId,
  joeToken,
  onStakeSuccess
}: SJoeStakePanelProps) => {
  // get JOE token balance
  const { data: balance, refetch: refetchJoeBalance } = useTokenBalance({
    chainId,
    token: joeToken?.address
  })
  const debouncedRefetchJoeBalance = debounce(() => refetchJoeBalance(), 2000)

  // get input
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: joeToken
  })
  const isZero = Number(amount) === 0
  const isExceedingBalance =
    joeToken && balance ? Number(balance.formatted) < Number(amount) : false

  // check approval
  const {
    approvalType,
    approve,
    isApproved,
    isApproving,
    reset: resetApproval,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: amountBN,
    chainId,
    spender: STABLE_JOE_STAKING_ADDRESS[chainId],
    token: joeToken?.address,
    tokenSymbol: joeToken?.symbol
  })

  const { deposit, isLoading } = useSJoeDeposit({
    amount: amountBN,
    approved: isApproved,
    chainId,
    onSuccess: () => {
      resetApproval()
      setAmount('')
      debouncedRefetchJoeBalance()
      onStakeSuccess()
    }
  })

  return (
    <VStack align="flex-start" spacing={4}>
      <CurrencyInput
        data-cy="sjoe-stake-input"
        chainId={chainId}
        currency={joeToken}
        currencyAddress={joeToken?.address}
        value={amount}
        onValueChange={(value) => {
          setAmount(value)
        }}
        balance={balance?.formatted}
        error={
          isExceedingBalance ? t`Not enough ${joeToken?.symbol}` : undefined
        }
      />

      {!isApproved && !isExceedingBalance && approve && joeToken ? (
        <ApproveTokenButton
          data-cy="sjoe-stake-approve-button"
          amount={amount}
          approvalType={approvalType}
          currencySymbol={joeToken.symbol}
          onApprovalTypeSelect={setApprovalType}
          isLoading={isApproving}
          loadingText={t`Approving ${joeToken.symbol}`}
          onClick={() => {
            approve()
          }}
        >
          <Trans>Approve {joeToken.symbol}</Trans>
        </ApproveTokenButton>
      ) : null}
      <Web3Button
        data-cy="sjoe-stake-button"
        colorScheme="accent"
        variant="primary"
        size="xl"
        w="full"
        isDisabled={isZero || isExceedingBalance || !deposit}
        isLoading={isLoading}
        loadingText={t`Staking Tokens`}
        onClick={() => {
          deposit?.()
          stakeSJoe()
        }}
      >
        <Trans>Stake Tokens</Trans>
      </Web3Button>
    </VStack>
  )
}

export default SJoeStakePanel
